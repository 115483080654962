import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import moment from 'moment'
// helpers
import dateFormat from '../../helpers/dateFormat'
import translateTransactions from '../../helpers/translateTransactions'

const UserRow = ({
  id,
  rfc,
  name,
  lastName,
  userTransactions,
  userBuys,
  periodMonth
}) => {
  const [sortedTransactions, setSortedTransactions] = useState([])

  useEffect(() => {
    const newUserTransactions = [...userTransactions, ...userBuys]
    const sorted = newUserTransactions.sort((a, b) => a.id - b.id)
    setSortedTransactions(sorted)
  }, [userTransactions, userBuys])

  return (
    <RowStyled>
      <h2 className="user-name">
        {id}.- {name} {lastName} <br /> {rfc}
      </h2>

      {sortedTransactions.length > 0 ? (
        <>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li
                key={transaction.id}
                className={
                  moment(transaction.transaction_date).format('MM') !==
                  periodMonth
                    ? 'nextMonth'
                    : ''
                }
              >
                <span>
                  {moment(transaction.transaction_date).format('DD ')}
                  {dateFormat(transaction.transaction_date, 'month')}
                </span>
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li key={transaction.id}>
                <span>{translateTransactions(transaction.type)}</span>
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li
                key={transaction.id}
                className={
                  transaction.indicator === 'withdrawal' ||
                  transaction.indicator === 'buy'
                    ? 'amount-column withdrawal'
                    : 'amount-column'
                }
              >
                <NumberFormat
                  value={Number(transaction.subtotal).toFixed(2)}
                  displayType="text"
                  thousandSeparator
                  prefix={
                    transaction.indicator === 'withdrawal' ||
                    transaction.indicator === 'buy'
                      ? '-'
                      : ''
                  }
                />
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li
                key={transaction.id}
                className={
                  transaction.indicator === 'withdrawal' ||
                  transaction.indicator === 'buy'
                    ? 'amount-column withdrawal'
                    : 'amount-column'
                }
              >
                <NumberFormat
                  value={Number(transaction.net_amount).toFixed(2)}
                  displayType="text"
                  thousandSeparator
                  prefix={
                    transaction.indicator === 'withdrawal' ||
                    transaction.indicator === 'buy'
                      ? '-'
                      : ''
                  }
                />
              </li>
            ))}
          </ol>
          <ol className="user-transactions">
            {sortedTransactions.map((transaction) => (
              <li key={transaction.id} className="amount-column">
                <NumberFormat
                  value={Number(transaction.new_user_balance).toFixed(2)}
                  displayType="text"
                  thousandSeparator
                />
              </li>
            ))}
          </ol>
        </>
      ) : (
        <span>-</span>
      )}
    </RowStyled>
  )
}

const RowStyled = styled.li`
  align-items: center;
  border-bottom: 2px solid black;
  display: grid;
  grid-template-columns: 300px 100px 280px 160px 160px 160px;
  padding: 10px 0;

  .user-name {
    font-size: 16px;
    font-weight: 500;
  }

  .user-transactions {
    list-style: none;
  }

  .amount-column {
    text-align: right;
    font-family: monospace;
  }

  .withdrawal {
    color: darkred;
  }

  .number-title {
    margin-right: 5px;
    font-weight: 500;
  }

  .nextMonth {
    text-decoration: line-through;
  }
`

export default UserRow
