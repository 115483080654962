import React, { useState } from 'react'
import { BuildingsStyles } from '../styles'

import Wrapper from '../../layout/wrapper'
import SectionTitle from '../../layout/shared/sectionTitle'
import BuildingCard from './buildingCard'
import Modal from '../../layout/shared/modal'
import NewBuilding from '../newBuilding'

const BuildingsView = ({ buildingsList, updateBuildings, userToken }) => {
  const [modal, setModal] = useState(false)

  const toggleModal = (value) => {
    setModal(value)
  }

  return (
    <BuildingsStyles>
      <Wrapper>
        <SectionTitle
          title="Edificios"
          action="Nuevo edificio"
          handleClick={toggleModal}
        />
        <ul className="buildings-list">
          {buildingsList
            ? buildingsList.map((building) => (
                <BuildingCard
                  key={building.phase_id}
                  name={building.name}
                  phase_id={building.phase_id}
                />
              ))
            : null}
        </ul>
      </Wrapper>
      {modal && (
        <Modal toggleModal={toggleModal}>
          <NewBuilding
            userToken={userToken}
            toggleModal={toggleModal}
            updateBuildings={updateBuildings}
          />
        </Modal>
      )}
    </BuildingsStyles>
  )
}

export default BuildingsView
