/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import Swal from 'sweetalert2'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Button from '../layout/shared/button'
import {
  getSubscriptions,
  updateSubscription
} from '../../services/subscriptions'
import { NewDataSubscription, Subscription } from '../../interfaces'

interface props {
  subscription: Subscription
  newData: NewDataSubscription
  setNewData: (value: NewDataSubscription) => void
  setSubscription: (value: Subscription[]) => void
  setEditSubscription: (value: boolean) => void
}

const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  h2 {
    font-size: 2rem;
    /* margin-bottom: 10px; */
  }

  p {
    font-size: 1.2rem;
  }

  span {
    font-weight: bold;
    font-size: 1.4rem;
  }

  form,
  label {
    display: flex;
    flex-direction: column;
    font-size: 1.2rem;
    gap: 0.3rem;
  }

  form {
    gap: 1rem;
  }

  input,
  select {
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    font-size: 17px;
  }
`

export const EditSubscriptionForm = ({
  subscription,
  newData,
  setNewData,
  setSubscription,
  setEditSubscription
}: props): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  const subscriptionsList = async (): Promise<void> => {
    const { data: subscriptionsResult } = await getSubscriptions(userToken)
    setSubscription(subscriptionsResult)
  }

  const onHandleUpdateSubscription = (): void => {
    Swal.fire({
      title: '¿Quieres guardar los cambios?',
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: `Cancelar`
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire('Guardados!', '', 'success')
        const response = await updateSubscription(userToken, subscription?.id, {
          subscription_charge_date: newData.newChargeDate,
          subscription_amount: newData.newAmount,
          subscription_active: newData.newStatus
        })

        if (response) {
          setEditSubscription(false)
          subscriptionsList()
        }
      }
    })
  }

  return (
    <StyledForm>
      <h2>Editar suscripción</h2>
      <div className="container-info">
        <p>
          {subscription?.user.name} {subscription?.user.last_name}
        </p>
        <p>
          Suscripción desde{' '}
          <span>
            {moment(subscription?.subscription_start_date)
              .locale('es-MX')
              .format('DD-MMM-YYYY')}
          </span>
        </p>
      </div>
      <form>
        <label>
          Monto de suscripción:
          <input
            type="number"
            value={newData && newData.newAmount > 0 ? newData.newAmount : ''}
            onChange={(e) => {
              setNewData({
                ...newData,
                newAmount: Number(e.target.value)
              })
            }}
          />
        </label>

        <label>
          Fecha de cobro:
          <input
            type="number"
            value={
              newData && newData.newChargeDate > 0 ? newData.newChargeDate : ''
            }
            onChange={(e) => {
              setNewData({
                ...newData,
                newChargeDate: Number(e.target.value)
              })
            }}
          />
        </label>

        <label>
          Estatus:
          <select
            defaultValue={
              subscription.subscription_active ? 'active' : 'paused'
            }
            onChange={(e) => {
              setNewData({
                ...newData,
                newStatus: e.target.value === 'active'
              })
            }}
          >
            <option value="active">Activada</option>
            <option value="paused">En pausa</option>
          </select>
        </label>
      </form>
      <p>
        Motor de pago:{' '}
        {subscription?.subscription_stripe_subscription_id ? 'Stripe' : 'Zigu'}
      </p>
      <Button
        text="Guardar"
        clickHandler={() => onHandleUpdateSubscription()}
      />
    </StyledForm>
  )
}
