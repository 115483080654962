import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { ResponsiveBar } from '@nivo/bar'
import currency from 'currency.js'
import moment from 'moment'
// layout
import { colors } from '../../layout/constants'

const StyledGraph = styled.section`
  height: 400px;
  h3 {
    text-align: center;
  }

  .tooltip {
    background-color: ${colors.primaryDark};
    color: white;
    padding: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
  }

  header {
    display: flex;
    justify-content: space-between;
  }

  .buttons {
    display: flex;
    gap: 1rem;
  }
`

export const TransactionsGraph = ({ transactions }) => {
  const [year, setYear] = useState(2024)

  const data = useMemo(() => {
    if (transactions.length) {
      const dataset = formatTransactions(transactions, year)
      const filtered = dataset.filter((a) => a.date.includes(year))
      return filtered
    }

    return []
  }, [transactions, year])

  return (
    <StyledGraph>
      <header>
        <h3>Historial de Ventas</h3>
        <div className="buttons">
          <button onClick={() => setYear((prev) => prev - 1)}>◀</button>
          <h3>{year}</h3>
          <button onClick={() => setYear((prev) => prev + 1)}>▶</button>
        </div>
      </header>
      {transactions && (
        <ResponsiveBar
          data={data}
          keys={['value']}
          indexBy="date"
          valueScale={{ type: 'linear' }}
          valueFormat={(value) => currency(value, { precision: 0 }).format()}
          margin={{ top: 32, right: 32, bottom: 80, left: 120 }}
          padding={0.5}
          tooltip={(props) => tooltip(props)}
          axisLeft={{
            format: (value) => currency(value, { precision: 0 }).format(),
            tickSize: 5,
            tickPadding: 10,
            tickRotation: 0,
            legend: 'Valor',
            legendPosition: 'middle',
            legendOffset: -80,
            truncateTickAt: 0
          }}
          axisBottom={{
            format: (value) => {
              return moment(value, 'YYYY-MM-DD').format('MMM-YY')
            },
            tickSize: 5,
            tickPadding: 10,
            tickRotation: 0,
            legend: 'Fecha',
            legendPosition: 'middle',
            legendOffset: 40,
            truncateTickAt: 0
          }}
          // enableGridY={false}
        />
      )}
    </StyledGraph>
  )
}

// helper functions
function formatTransactions(transactions, year) {
  const yearCalendar = generateMonths(year)
  const tokensSold = transactions.filter(
    (transaction) => transaction.seller_rfc === 'URV150302I90'
  )

  const amountByMonth = tokensSold.reduce((data, transaction) => {
    const transactionDate = moment(transaction.transaction_date)
      .startOf('month')
      .format('YYYY-MM-DD')

    if (data.some((t) => t.date === transactionDate)) {
      data.map((t) => {
        if (t.date === transactionDate) {
          t.value += Number(transaction.transaction_amount)
          t.amount++
        }
      })
    } else {
      data.push({
        date: transactionDate,
        value: Number(transaction.transaction_amount),
        amount: 1
      })
    }

    return data
  }, yearCalendar)

  amountByMonth.sort((a, b) => {
    const dateA = moment(a.date)
    const dateB = moment(b.date)
    if (dateA.isBefore(dateB)) {
      return -1
    }
    if (dateA.isAfter(dateB)) {
      return 1
    }
    return 0
  })

  return amountByMonth
}

function tooltip(props) {
  return (
    <div className="tooltip">
      <span>{moment(props.data.date, 'YYYY-MM-DD').format('MMMM-YY')}: </span>
      <span>{currency(props.value, { precision: 0 }).format()}</span>
    </div>
  )
}

function generateMonths(year) {
  return [
    {
      date: `${year}-01-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-02-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-03-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-04-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-05-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-06-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-07-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-08-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-09-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-10-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-11-01`,
      value: 0,
      amount: 0
    },
    {
      date: `${year}-12-01`,
      value: 0,
      amount: 0
    }
  ]
}
