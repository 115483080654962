import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import dateFormat from '../helpers/dateFormat'
import { User, ExtendedOrder } from '../orders/interfaces'
// layout
import { colors } from '../layout/constants'
import Button from '../layout/shared/button'
import { Card } from '../../urvita-ui'
// services
import { newTransaction } from '../../services/transactions'
import { newTokenTrade } from '../../services/tokenTrades'
import { updateOrder } from '../../services/orders'

const DetailContainer = styled.section`
  .order-info {
    ul {
      font-size: 1.2rem;
      list-style: none;

      a {
        font-weight: 500;
        color: ${colors.primaryColor};

        &:hover {
          color: ${colors.secondaryDark};
        }
      }
    }
  }

  .list-title {
    font-weight: 500;
    margin-right: 0.5rem;
  }

  .order-status {
    h2 {
      margin: 1rem 0;
    }
  }
`

type orderData = {
  order: ExtendedOrder
  user: User
  removeOrder: any
}

const OrderGeneralInfo = ({
  order,
  user,
  removeOrder
}: orderData): JSX.Element => {
  const history = useHistory()
  const [loading, setLoading] = useState<boolean>(false)
  const [totalTransactions, setTotalTransactions] = useState<number>(0)
  const [transactionsCount, setTransactionsCount] = useState<number>(0)
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  const completeBuyOrder = async (): Promise<void> => {
    setLoading(true)
    if (order?.tokens) {
      setTotalTransactions(order.tokens.length)
      setTransactionsCount(0)
      // eslint-disable-next-line no-restricted-syntax
      for (const token of order.tokens) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await newTransaction(userToken, {
            transaction_date: order.order_date,
            user_rfc: user?.rfc,
            target_rfc: 'URV150302I90',
            type: 'CXC_VTA_TOKENS',
            subtotal: order.single_token_value
          })

          // eslint-disable-next-line no-await-in-loop
          await newTokenTrade(userToken, {
            transaction_date: order.order_date,
            token_id: token.token_id,
            buyer_rfc: user?.rfc,
            seller_rfc: 'URV150302I90',
            transaction_amount: order.single_token_value,
            transaction_channel: 'admin'
          })

          setTransactionsCount(transactionsCount + 1)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      }
      setLoading(false)
      await updateOrder(userToken, order.id, {
        order_status: 'completada'
      })
      history.push('/ordenes')
    }
  }

  const completeSellOrder = async (): Promise<void> => {
    setLoading(true)
    if (order?.tokens) {
      setTotalTransactions(order.tokens.length)
      setTransactionsCount(0)
      // eslint-disable-next-line no-restricted-syntax
      for (const token of order.tokens) {
        try {
          // eslint-disable-next-line no-await-in-loop
          await newTransaction(userToken, {
            transaction_date: order.order_date,
            user_rfc: 'URV150302I90',
            target_rfc: user?.rfc,
            type: 'CXC_VTA_TOKENS',
            subtotal: order.single_token_value
          })

          // eslint-disable-next-line no-await-in-loop
          await newTokenTrade(userToken, {
            transaction_date: order.order_date,
            token_id: token.token_id,
            buyer_rfc: 'URV150302I90',
            seller_rfc: user?.rfc,
            transaction_amount: order.single_token_value,
            transaction_channel: 'admin'
          })

          setTransactionsCount(transactionsCount + 1)
        } catch (error) {
          console.error(error)
          setLoading(false)
        }
      }
      setLoading(false)
      await updateOrder(userToken, order.id, {
        order_status: 'completada'
      })
      history.push('/ordenes')
    }
  }

  const selectTransaction = (orderType: string): void => {
    if (orderType === 'compra') {
      completeBuyOrder()
    } else if (orderType === 'venta') {
      completeSellOrder()
    }
  }

  return (
    <Card>
      <DetailContainer>
        <header className="order-header">
          <h1>
            Orden de {order.order_type} #{order.id}
          </h1>
          <Button
            text="Eliminar"
            clickHandler={() => removeOrder()}
            disabled={false}
            small
            danger
          />
        </header>

        <section className="order-info">
          <ul>
            <li>
              <span className="list-title">Fecha:</span>{' '}
              {dateFormat(order.order_date, 'full')}
            </li>
            <li>
              <span className="list-title">
                {order.order_type === 'compra' ? 'Comprador:' : null}
                {order.order_type === 'venta' ? 'Vendedor:' : null}
                {order.order_type !== 'venta' && order.order_type !== 'compra'
                  ? 'Usuario:'
                  : null}
              </span>
              <Link to={`/usuarios/${user.id}`}>
                {user.name} {user.last_name}
              </Link>
            </li>
            {order.order_type === 'compra' && (
              <li>
                <span className="list-title">Vendedor:</span>{' '}
                <Link to="/usuarios/8}"> Urvita</Link>
              </li>
            )}
            {order.order_type === 'venta' && (
              <li>
                <span className="list-title">Comprador: </span>
                <Link to="/usuarios/8}"> Urvita</Link>
              </li>
            )}
          </ul>

          <div className="order-status">
            <h3>Status de la Orden</h3>
            <h2>{order.order_status}</h2>
          </div>

          {(order.order_type === 'compra' || order.order_type === 'venta') && (
            <div>
              {loading ? (
                <div>
                  Processando... {transactionsCount} / {totalTransactions}
                </div>
              ) : (
                <Button
                  text={`Generar ${order.order_type}`}
                  clickHandler={() => selectTransaction(order.order_type)}
                  disabled={false}
                  small={false}
                  danger={false}
                />
              )}
            </div>
          )}
        </section>
      </DetailContainer>
    </Card>
  )
}

export default OrderGeneralInfo
