import React, { useEffect, useState, useMemo } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'
// layout
import DefaultLayout from '../layout'
// Components
import SearchHeader from '../layout/shared/searchHeader'
import Highlights from './highlights'
import UsersList from './usersList'
// services
import { getUsers } from '../../services/users'
import { getTokens } from '../../services/tokens'
// types
import { Token, ExtendedUser } from '../orders/interfaces'

const UsersContainer = styled.section`
  padding: calc(52px + 1rem) 2rem 1rem 2rem;

  h1 {
    font-size: 40px;
  }
`

const Users = (): JSX.Element => {
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  const [users, setUsers] = useState<ExtendedUser[]>([])
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [segmentFilter, setSegmentFilter] = useState<string>('')
  const [filteredUser, setFilteredUser] = useState<ExtendedUser[]>([])
  const [sortedUsers, setSortedUsers] = useState<ExtendedUser[]>([])

  useEffect(() => {
    const usersList = async (): Promise<void> => {
      const usersResult = await getUsers(userToken)
      const tokensResult = await getTokens(userToken)

      const completedUsers = usersResult.data.map((user: ExtendedUser) => {
        // eslint-disable-next-line no-param-reassign
        user.tokens = tokensResult.filter(
          (token: Token) => token.owner_rfc === user.rfc
        ).length
        return user
      })

      setUsers(completedUsers)
    }

    usersList()
  }, [userToken])

  useMemo(() => {
    // Search by name or email with searchbox
    if (users) {
      const filterResult = users.filter((user: ExtendedUser) => {
        const fullName = (user.name + user.last_name).toLocaleLowerCase().trim()
        const email = user.email.toLocaleLowerCase().trim()
        return (
          fullName.includes(searchFilter.toLocaleLowerCase().trim()) ||
          email.includes(searchFilter.toLocaleLowerCase().trim())
        )
      })
      setFilteredUser(filterResult)
    }
  }, [users, searchFilter])

  useMemo(() => {
    if (users) {
      const filterResult = users.filter((user) => {
        switch (segmentFilter) {
          case 'new-users':
            return !user.verificated
          case 'verificated-users':
            return user.verificated
          case 'pending-users':
            return (
              user.contract_status === 'toSign' ||
              user.contract_status === 'toCreate'
            )
          case 'signed-users':
            return user.contract_status === 'signed'
          default:
            return true
        }
      })
      setFilteredUser(filterResult)
    }
  }, [users, segmentFilter])

  useMemo(() => {
    if (users && segmentFilter === 'balance-users') {
      const sort = [...users].sort((a, b) => b.balance - a.balance)
      setSortedUsers(sort)
    }
  }, [users, segmentFilter])

  useMemo(() => {
    if (users && segmentFilter !== 'balance-users') {
      const sortedResult = filteredUser.sort(
        (a: ExtendedUser, b: ExtendedUser) => {
          const isAfter = moment(a.createdAt).isAfter(b.createdAt)
          if (isAfter) {
            return -1
          }
          if (!isAfter) {
            return 1
          }
          return 0
        }
      )
      setSortedUsers(sortedResult)
    }
  }, [users, filteredUser])

  return (
    <DefaultLayout sidenav>
      <SearchHeader setSearchFilter={setSearchFilter} />
      <UsersContainer>
        <h1>Usuarios</h1>
        <Highlights
          count={filteredUser.length}
          segmentFilter={segmentFilter}
          setSegmentFilter={setSegmentFilter}
        />
        {users && <UsersList users={sortedUsers} />}
      </UsersContainer>
    </DefaultLayout>
  )
}

export default Users
