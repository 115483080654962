/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import 'moment/locale/es'
// components
import ChargeModal from './chargeModal'
import SubscriptionRow from './SubscriptionRow'
// layout
import { colors } from '../layout/constants'
import Modal from '../layout/shared/modal'
import { NewDataSubscription, Subscription } from '../../interfaces'
import { EditSubscriptionForm } from './editSubscriptionForm'

const StyledHeader = styled.h2`
  font-size: 1.2em;
  color: ${colors.white};
  letter-spacing: 0.5px;
  font-weight: 500;
`

interface props {
  subscriptions: Subscription[]
  setSubscription: (value: Subscription[]) => void
}

const BasicTable = ({ subscriptions, setSubscription }: props): JSX.Element => {
  const [chargeModal, setChargeModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [newData, setNewData] = useState<NewDataSubscription>({
    newAmount: 0,
    newChargeDate: 0,
    newStatus: false
  })
  const [editSubscription, setEditSubscription] = useState<boolean>(false)
  const [activeSubscription, setActiveSubscription] = useState<Subscription>()

  const toggleModal = (value: boolean): void => {
    setModal(value)
    setChargeModal(value)
  }

  const openModal = (type: string): void => {
    if (type === 'charge') {
      setChargeModal(true)
    } else {
      setModal(true)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead
          sx={{
            backgroundColor: colors.primaryColor
          }}
        >
          <TableRow>
            <TableCell>
              <StyledHeader>#</StyledHeader>
            </TableCell>
            <TableCell align="left">
              <StyledHeader>Usuario</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Estatus</StyledHeader>
            </TableCell>
            <TableCell align="right">
              <StyledHeader>Monto</StyledHeader>
            </TableCell>
            <TableCell align="right">
              <StyledHeader>Detalles</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Fecha de cobro</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Último cargo</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Acción</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Editar</StyledHeader>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscriptions.map((subscription, index) => (
            <SubscriptionRow
              key={subscription.id}
              subscription={subscription}
              index={index}
              openModal={openModal}
              setActiveSubscription={setActiveSubscription}
              setEditSubscription={setEditSubscription}
              setNewData={setNewData}
            />
          ))}
        </TableBody>
      </Table>

      {chargeModal && activeSubscription && !editSubscription && (
        <Modal toggleModal={toggleModal}>
          <ChargeModal activeSubscription={activeSubscription} />
        </Modal>
      )}

      {modal && activeSubscription && editSubscription && (
        <Modal toggleModal={toggleModal}>
          <EditSubscriptionForm
            newData={newData}
            setNewData={setNewData}
            setSubscription={setSubscription}
            subscription={activeSubscription}
            setEditSubscription={setEditSubscription}
          />
        </Modal>
      )}
    </TableContainer>
  )
}

export default BasicTable
