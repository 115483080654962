import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// helpers
import { getBuildings } from '../../../services/buildings'
import { getPeriodRecords } from '../../../services/valueRecords'
import { buildingsOperations } from '../../../services/monthOperations'
import dateFormat from '../../helpers/dateFormat'
// componets
import BuildingsTable from './buildingsTable'
import AddTrackRecord from './addTrackRecord'
// layout
import Button from '../../layout/shared/button'
import SectionTitle from '../../layout/shared/sectionTitle'
import Loader from '../../layout/shared/loader'

const BuildingsSection = ({ period, lastPeriod }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // State
  const [buildingsList, setBuildingsList] = useState([])
  const [lastValues, setLastValues] = useState([])
  const [presentValues, setPresentValues] = useState([])
  const [hasTrackRecord, setHasTrackRecord] = useState(false)
  const [missingValues, setMissingValues] = useState(true)
  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)

  // Get intial data
  useEffect(() => {
    if (period) {
      askForBuildings()
      askForLastValues()
      askForPeriodValues()
    }
  }, [period, lastPeriod])

  // Show update month button if values are missing
  useEffect(() => {
    if (presentValues.length) {
      setMissingValues(false)
    } else {
      setMissingValues(true)
    }
  }, [buildingsList, presentValues])

  // Manage loading feedabk
  const toggleModal = (value) => {
    setModal(value)
  }

  // Get all data
  const askForBuildings = async () => {
    const buildings = await getBuildings(userToken)
    const sortedBuildings = buildings.data.sort((a, b) => {
      if (a.id > b.id) {
        return 1
      }
      if (a.id < b.id) {
        return -1
      }
    })
    setBuildingsList(sortedBuildings)
    askForPeriodValues()
  }

  const askForLastValues = async () => {
    const lastPeriodValues = await getPeriodRecords(userToken, lastPeriod)
    setLastValues(lastPeriodValues)
  }

  const askForPeriodValues = async () => {
    try {
      const periodValues = await getPeriodRecords(userToken, period)
      if (periodValues) {
        setPresentValues(periodValues)
      }
      // setPresentValues([])
    } catch (error) {
      console.error(error)
    }
  }

  // Change of month operations
  const generateOperation = async () => {
    setLoading(true)
    const results = await buildingsOperations(userToken, period)
    await askForPeriodValues()
    setLoading(false)
    return results
  }

  return (
    <BuildingsStyled>
      {loading ? (
        <Loader text="Generando nuevos valores..." />
      ) : (
        <>
          <AddTrackRecord
            setHasTrackRecord={setHasTrackRecord}
            modal={modal}
            toggleModal={toggleModal}
          />

          <SectionTitle title="Resumen de edificios" />
          <BuildingsTable
            lastPeriod={lastPeriod}
            period={period}
            lastValues={lastValues}
            presentValues={presentValues}
            buildingsList={buildingsList}
            setHasTrackRecord={setHasTrackRecord}
            toggleModal={toggleModal}
            askForBuildings={askForBuildings}
          />

          {missingValues && (
            <div className="button-container">
              <Button
                disabled={!hasTrackRecord}
                text={`Generar valores de ${dateFormat(period, 'month')}`}
                clickHandler={() => generateOperation()}
              />
            </div>
          )}
        </>
      )}
    </BuildingsStyled>
  )
}

const BuildingsStyled = styled.section`
  margin: 40px 0;

  .button-container {
    text-align: center;
  }
`

export default BuildingsSection
