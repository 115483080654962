import React from 'react'
import currency from 'currency.js'
import moment from 'moment'
import styled from 'styled-components'
import { Transaction, User } from './types'
import {
  getPricesMarkets,
  getAccumulatedBalance,
  getAccumulatedOrders,
  filterTransactions,
  sumTransactions
} from './utils'
import dateFormat from '../helpers/dateFormat'
import { colors } from '../../urvita-ui/constants'

interface SummarySectionProps {
  transactions: Transaction[]
  users: User[]
  activePeriod: string
  loading: boolean
}

const SummaryContainer = styled.section`
  background-color: ${colors.lightBackground};
  border-radius: 8px;
  padding: 20px 0;
`

const SummaryTitle = styled.h2`
  color: ${colors.primaryColor};
  font-size: 24px;
  margin-bottom: 20px;
`

const SummaryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
`

const SummaryCard = styled.article`
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
`

const CardTitle = styled.h2`
  color: ${colors.primaryDark};
  font-size: 20px;
  margin-bottom: 5px;
`

const CardSubtitle = styled.h3`
  color: ${colors.secondaryDark};
  font-size: 1.1rem;
`

const CardDetails = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
`

const DetailText = styled.p`
  color: ${colors.darkText};
  font-size: 14px;
  margin: 2px 0;
`

const LoadingText = styled.div`
  color: ${colors.darkText};
  font-size: 16px;
  text-align: center;
  padding: 20px;
`

const SummarySection: React.FC<SummarySectionProps> = ({
  transactions,
  users,
  activePeriod,
  loading
}) => {
  const { primaryMarketResult, secondaryMarketResult } =
    getPricesMarkets(transactions)
  const accumulatedBalance = getAccumulatedBalance(users)
  const { orders, total } = getAccumulatedOrders(transactions)

  return (
    <SummaryContainer>
      <SummaryTitle>
        Resumen de {dateFormat(activePeriod, 'month')}{' '}
        {moment(activePeriod).format('YYYY')}
      </SummaryTitle>
      {loading && <LoadingText>Cargando información...</LoadingText>}
      {!loading && transactions && (
        <SummaryGrid>
          <SummaryCard>
            <CardSubtitle>Total en compras</CardSubtitle>
            <CardTitle>
              {currency(
                sumTransactions(
                  filterTransactions(transactions, 'CXC_VTA_TOKENS')
                )
              ).format()}
            </CardTitle>
            <CardDetails>
              <DetailText>
                Mercado primario:{' '}
                {currency(primaryMarketResult - secondaryMarketResult).format()}
              </DetailText>
              <DetailText>
                Mercado secundario: {currency(secondaryMarketResult).format()}
              </DetailText>
            </CardDetails>
          </SummaryCard>
          <SummaryCard>
            <CardSubtitle>Total en suscripciones</CardSubtitle>
            <CardTitle>
              {currency(
                sumTransactions(
                  filterTransactions(
                    transactions,
                    'COBRANZA_DEPOSITO_RECURRENTE'
                  )
                )
              ).format()}
            </CardTitle>
          </SummaryCard>
          <SummaryCard>
            <CardSubtitle>Total en abonos</CardSubtitle>
            <CardTitle>
              {currency(
                sumTransactions(
                  filterTransactions(transactions, 'COBRANZA_DEPOSITO')
                )
              ).format()}
            </CardTitle>
          </SummaryCard>
          <SummaryCard>
            <CardSubtitle>Balance acumulado (Actual)</CardSubtitle>
            <CardTitle>{currency(accumulatedBalance).format()}</CardTitle>
          </SummaryCard>
          <SummaryCard>
            <CardSubtitle>Ordenes de retiro</CardSubtitle>
            <CardTitle>
              {currency(total).format()} | {orders}
            </CardTitle>
          </SummaryCard>
          <SummaryCard>
            <CardSubtitle>Total en ganancias</CardSubtitle>
            <CardTitle>
              {currency(
                sumTransactions(
                  filterTransactions(transactions, 'CXP_REINVERTIR')
                )
              ).format()}
            </CardTitle>
          </SummaryCard>
        </SummaryGrid>
      )}
    </SummaryContainer>
  )
}

export default SummarySection
