import React, { useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
// helpers
import translateTransactions from '../../helpers/translateTransactions'
import { deleteTransaction } from '../../../services/transactions'
// layout
import SectionTitle from '../../layout/shared/sectionTitle'
import Modal from '../../layout/shared/modal'
import Button from '../../layout/shared/button'
import { colors } from '../../layout/constants'

const TransactionRow = ({ transaction, handleUpdate }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)

  const [modal, setModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const toggleModal = (value) => {
    setModal(value)
  }

  const removeTransaction = async (id) => {
    setLoading(true)
    try {
      const deletedTransaction = await deleteTransaction(userToken, id)
    } catch (error) {
      console.error(error)
    }
    setLoading(false)
    setModal(false)
    handleUpdate()
  }

  return (
    <RowStyled>
      <span>{moment(transaction.transaction_date).format('DD MMM YY')}</span>
      <span>{translateTransactions(transaction.type)}</span>
      <NumberFormat
        className={
          transaction.indicator === 'withdrawal' ||
          transaction.indicator === 'buy'
            ? 'mono withdrawal'
            : 'mono'
        }
        value={Number(transaction.net_amount).toFixed(2)}
        displayType="text"
        thousandSeparator
        prefix={
          transaction.indicator === 'withdrawal' ||
          transaction.indicator === 'buy'
            ? '-'
            : ''
        }
      />
      <NumberFormat
        className={
          transaction.new_user_balance < 0 ? 'mono withdrawal' : 'mono'
        }
        value={Number(transaction.new_user_balance).toFixed(2)}
        displayType="text"
        thousandSeparator
      />

      <span className="delete-button" onClick={() => toggleModal(true)}>
        X
      </span>

      {modal && (
        <Modal toggleModal={toggleModal}>
          <SectionTitle title="Borrar transacción" />

          <p className="modal-text">
            Si borras esta transacción se eliminará permanentemente del
            registro.
          </p>
          <p className="modal-text">
            Ten en cuenta que si quieres revertir la operación tendrías que
            realizar la operación contraria para cancelarla y luego borrar
            ambas. Ejemplo: Habría que hacer un retiro por $50,000 para cancelar
            un depósito por $50,000
          </p>

          <div className="button-container">
            <Button
              text={loading ? 'Eliminando...' : 'Eliminar transacción'}
              disabled={loading}
              danger
              small
              clickHandler={() => removeTransaction(transaction.id)}
            />
            <Button
              text="Cancelar"
              disabled={loading}
              small
              clickHandler={() => toggleModal(false)}
            />
          </div>
        </Modal>
      )}
    </RowStyled>
  )
}

const RowStyled = styled.li`
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 2fr 1fr 1fr max-content;
  grid-column-gap: 20px;
  padding: 5px 0;
  border-bottom: 1px solid ${colors.gridGray};

  .mono {
    font-family: monospace;
    text-align: right;
  }

  .withdrawal {
    color: red;
  }

  .delete-button {
    border: 1px solid red;
    border-radius: 8px;
    color: red;
    cursor: pointer;
    display: inline-block;
    font-size: 20px;
    padding: 1px;
    text-align: center;
    width: 20px;

    :hover {
      background-color: red;
      color: white;
    }
  }

  .modal-text {
    max-width: 280px;
    margin: 10px auto;
  }
`

export default TransactionRow
