import React from 'react'
import { Link, useRouteMatch, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { removeUserSession } from '../../../utils/sesionManagment'
import { SidenavStyles } from './styles'
import urvitaLogo from '../../../assets/img/urvita-white.png'

const Sidenav = ({ active, toggleSidenav }) => {
  const { path } = useRouteMatch()
  const history = useHistory()

  // Redux hooks
  const dispatch = useDispatch()
  const userType = useSelector((state) => state.user.type)

  // Manage Logout
  const LOGOUT = () => {
    dispatch({
      type: 'LOGOUT'
    })
  }

  const logoutHandler = () => {
    history.push('/login')
    removeUserSession()
    LOGOUT()
  }

  const views = [
    { path: 'dashboard', name: 'Resumen', authorization: 'SALES' },
    { path: 'ordenes', name: 'Ordenes', authorization: 'SALES' },
    { path: 'suscripciones', name: 'Suscripciones', authorization: 'SALES' },
    // { path: 'plan-de-pagos', name: 'Plan de pagos', authorization: 'SALES' },
    { path: 'usuarios', name: 'Usuarios' },
    { path: 'usuariosNew', name: 'Usuarios 2.0' },
    { path: 'edificios', name: 'Edificios' },
    { path: 'tokens', name: 'Tokens' },
    { path: 'cambio-de-mes', name: 'Resumen de mes' },
    { path: 'settings', name: 'Configuración' }
  ]

  return (
    <SidenavStyles active={active}>
      <section className="background" onClick={() => toggleSidenav(false)} />
      <section className="sidenav-content">
        <img className="logo" src={urvitaLogo} alt="Logo de Urvita" />

        <ul className="sidenav-navegation">
          {views
            .filter((view) =>
              userType !== 'ADMIN' ? userType === view.authorization : true
            )
            .map((view, index) => (
              <li
                key={index}
                className="sidenav-item"
                data-active={path === `/${view.path}`}
              >
                <Link to={`/${view.path}`}>{view.name}</Link>
              </li>
            ))}
          <li className="sidenav-item">
            <a href="https://admin.urvita.com/">Admin 2.0</a>
          </li>
          <li className="sidenav-item" onClick={() => logoutHandler()}>
            Cerrar sesión
          </li>
        </ul>
      </section>
    </SidenavStyles>
  )
}

export default Sidenav
