import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// layout
import Button from '../../layout/shared/button'
import Modal from '../../layout/shared/modal'
import SectionTitle from '../../layout/shared/sectionTitle'
// services
import { deleteUser, resetPassword } from '../../../services/users'

const UserActions = ({
  userName,
  userId,
  userRfc,
  loading,
  setLoading,
  getUsersList,
  setHighlightId
}) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // State
  const [deleteModal, setDeleteModal] = useState(false)
  const [passwordModal, setPasswordModal] = useState(false)

  const toggleModal = (value, modal) => {
    if (modal === 'password') {
      setPasswordModal(value)
    } else {
      setDeleteModal(value)
    }
  }

  const resetUserPassword = async (id) => {
    setLoading(true)
    toggleModal(false, 'password')
    const resetedPassword = await resetPassword(userToken, id)
    setLoading(false)
  }

  const removeUser = async (id) => {
    setLoading(true)
    toggleModal(false, 'delete')
    const deletedUser = await deleteUser(userToken, id)
    setHighlightId('')
    setLoading(false)
    getUsersList()
  }

  return (
    <ActionsStyled>
      <Button
        text="Reset password"
        disabled={loading}
        small
        clickHandler={() => toggleModal(true, 'password')}
      />
      <Button
        text="Borrar usuario"
        disabled={loading}
        small
        danger
        clickHandler={() => toggleModal(true, 'delete')}
      />

      {deleteModal && (
        <Modal toggleModal={() => toggleModal(false, 'delete')}>
          <SectionTitle title={`Borrar a ${userName}`} />

          <p>
            ¿Seguro que quieres eliminar a {userName} con el RFC {userRfc}?
          </p>

          <div className="button-container">
            <Button
              text="Elimina"
              disabled={loading}
              small
              danger
              clickHandler={() => removeUser(userId)}
            />
            <Button
              text="Cancelar"
              disabled={loading}
              small
              clickHandler={() => toggleModal(false, 'delete')}
            />
          </div>
        </Modal>
      )}

      {passwordModal && (
        <Modal toggleModal={() => toggleModal(false, 'password')}>
          <SectionTitle title={`Cambiar contraseña a ${userName}`} />

          <p>
            Se reiniciará la contraseña de {userName} con el rfc {userRfc} a
            "urvita2020" y se le pedirá que la cambie la próxima vez que inicie
            sesión en su cuenta.
          </p>

          <div className="button-container">
            <Button
              text="Cambiar contraseña"
              disabled={loading}
              small
              clickHandler={() => resetUserPassword(userId)}
            />
            <Button
              text="Cancelar"
              disabled={loading}
              small
              danger
              clickHandler={() => toggleModal(false, 'password')}
            />
          </div>
        </Modal>
      )}
    </ActionsStyled>
  )
}

const ActionsStyled = styled.section`
  display: flex;
  justify-content: space-around;

  p {
    max-width: 300px;
    margin: 0 auto;
  }

  .button-container {
    display: flex;
    justify-content: space-around;
  }
`

export default UserActions
