import React from 'react'
// Components
import DefaultLayout from '../layout'
import SettingsView from './settingsView'

const Settings = () => (
  <DefaultLayout sidenav>
    <SettingsView />
  </DefaultLayout>
)

export default Settings
