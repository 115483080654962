import React from 'react'
import styled from 'styled-components'
import currency from 'currency.js'
import { Link } from 'react-router-dom'
import Dropdown from './statusDropdown'
// layout
import dateFormat from '../helpers/dateFormat'
import { colors, layoutValues } from '../layout/constants'
// types
import { ExtendedOrder } from './interfaces'

const RowContainer = styled.li`
  align-items: center;
  color: ${colors.darkText};
  display: grid;
  grid-template-columns: 100px 2fr 100px 120px repeat(3, 1fr) 120px 100px;
  grid-gap: 1rem;
  text-align: center;
  padding: 1rem 0;

  .order-id {
    font-size: 2rem;
    text-align: center;

    a {
      color: ${colors.gridGray};
      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  .name {
    color: ${colors.darkText};
    font-weight: 500;
    text-align: left;
    text-transform: lowercase;
    text-transform: capitalize;

    :hover {
      color: ${colors.secondaryDark};
    }
  }

  .type-label {
    border-radius: ${layoutValues.cardRadius};
    display: inline-block;
    padding: 8px;
    text-transform: capitalize;
    text-align: center;
    width: 100px;

    &.compra {
      color: white;
      background-color: ${colors.primaryLight};
    }

    &.abono {
      background-color: lightblue;
    }

    &.venta {
      background-color: gold;
    }

    &.reserva {
      color: white;
      background-color: #237c23;
    }
  }
  .curency {
    font-size: 1.2rem;
  }
`

type orderData = { order: ExtendedOrder }

const OrderRow = ({ order }: orderData): JSX.Element => (
  <RowContainer key={order.id}>
    <div className="order-id">
      <Link to={`/ordenes/${order.id}`}>#{order.id}</Link>
    </div>
    <div>
      <Link to={`/usuarios/${order.user_id}`}>
        <h3 className="name">{order?.user_name}</h3>
      </Link>
    </div>
    <div>{dateFormat(order.createdAt, 'short')}</div>
    <div>
      <article className={`type-label ${order.order_type}`}>
        {order.order_type}
      </article>
    </div>
    <div>
      {order.phase_name && (order.phase_name.match(/P001/g) || []).length}
    </div>
    <div>{order.phase_name?.substring(0, 60).concat(' ...')}</div>
    <div className="curency">
      {currency(order.subtotal_value || order.total_tokens_value).format()}
    </div>
    <div>
      <Dropdown
        orderId={order.id}
        orderType={order.order_type}
        orderStatus={order.order_status}
      />
    </div>
    <div>{dateFormat(order.updatedAt, 'short')}</div>
  </RowContainer>
)

export default OrderRow
