import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { User, Token } from '../orders/interfaces'
// layout
import DefaultLayout from '../layout'
import { Card, colors } from '../../urvita-ui'
// services
import { getUsers } from '../../services/users'
import { createOrder } from '../../services/orders'
import { updateToken } from '../../services/tokens'
// components
import UserPicker from './userPicker'
import TokenPicker from './tokenPicker'
import Checkout from './checkout'

const OrderContainer = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2rem;
  padding: 1rem 2rem;

  .title {
    font-size: 2rem;
    grid-column: 1/3;
  }

  .section {
    margin: 2rem 0;

    .sub-title {
      margin-bottom: 1rem;
    }
  }

  .types-cointainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    text-transform: capitalize;
  }

  .input-container {
    background-color: white;
    border: 1px solid black;
    border-radius: 16px;
    padding: 5px 10px;
    input {
      background-color: white;
      border: none;
      outline: none;
    }
  }
`

const orderTypes = [
  'abono',
  'abono recurrente',
  'ganancia',
  'compra',
  'venta',
  'retiro',
  'intercambio',
  'reserva'
]

const NewOrder = (): JSX.Element => {
  const history = useHistory()
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  // filter variables
  const [userList, setUsersList] = useState<User[]>()
  const [userRfc, setUsersRfc] = useState<string>()
  const [userName, setUserName] = useState<string>()

  // order variables
  const [orderType, setOrderType] = useState<string>('')
  const [orderUser, setOrderUser] = useState<number>()
  const [orderTokens, setOrderTokens] = useState<Token[]>([])
  const [orderValue, setOrderValue] = useState<number>(0)
  const [tokenPrice, setTokenPrice] = useState<number>(0)
  const [orderDate, setOrderDate] = useState<string>('')
  const [orderBuilding, setOrderBuilding] = useState<string>('')

  useEffect(() => {
    const getUsersFunction = async (): Promise<void> => {
      const users = await getUsers(userToken)
      setUsersList(users.data)
    }
    getUsersFunction()
  }, [])

  const sendOrder = async (): Promise<void> => {
    const tokensIds = orderTokens.map((token) => token.token_id)
    const tokenString = tokensIds.join(', ')

    const order = {
      order_date: orderDate,
      user_id: orderUser,
      order_type: orderType,
      order_status: 'pendiente de pago',
      subtotal_value: orderValue,
      phase_name: tokenString,
      order_tokens: tokensIds,
      single_token_value: tokenPrice
    }

    try {
      await createOrder(userToken, order)
      tokensIds.forEach(async (id) => {
        await updateToken(userToken, id, { available: false })
      })
      history.push('/ordenes')
    } catch (error) {
      alert('error al crear la orden')
    }
  }

  const resetOrder = (): void => {
    setOrderType('')
    setOrderValue(0)
    setOrderTokens([])
  }

  return (
    <DefaultLayout sidenav>
      <OrderContainer>
        <section>
          <h2 className="title">Nueva Orden</h2>
          <form>
            <section className="section">
              <UserPicker
                setUsersRfc={setUsersRfc}
                setUserName={setUserName}
                orderUser={orderUser}
                userList={userList}
                userName={userName}
                setOrderUser={setOrderUser}
              />
            </section>

            {orderUser && (
              <section className="section">
                <h3 className="sub-title">Selecciona un tipo de orden</h3>
                {orderType ? (
                  <section className="types-cointainer">
                    <Card interactive onClick={() => resetOrder()}>
                      <h3>{orderType}</h3>
                    </Card>
                  </section>
                ) : (
                  <section className="types-cointainer">
                    {orderTypes.map((value) => (
                      <Card
                        key={value}
                        interactive
                        onClick={() => setOrderType(value)}
                      >
                        <h3>{value}</h3>
                      </Card>
                    ))}
                  </section>
                )}
              </section>
            )}

            {orderUser && (orderType === 'compra' || orderType === 'venta') ? (
              <section className="section">
                <TokenPicker
                  userRfc={userRfc}
                  orderType={orderType}
                  orderTokens={orderTokens}
                  setOrderTokens={setOrderTokens}
                  setOrderBuilding={setOrderBuilding}
                />
              </section>
            ) : (
              orderUser &&
              orderType && (
                <section className="section">
                  <h4 className="sub-title">Monto</h4>
                  <div className="input-container">
                    <input
                      onChange={(event) =>
                        setOrderValue(Number(event.target.value))
                      }
                    />
                  </div>
                </section>
              )
            )}
          </form>
        </section>

        <Checkout
          setOrderDate={setOrderDate}
          orderTokens={orderTokens}
          setOrderValue={setOrderValue}
          sendOrder={sendOrder}
          orderType={orderType}
          orderValue={orderValue}
          setTokenPrice={setTokenPrice}
          tokenPrice={tokenPrice}
          orderDate={orderDate}
          userRfc={userRfc}
          userName={userName}
          orderBuilding={orderBuilding}
        />
      </OrderContainer>
    </DefaultLayout>
  )
}

export default NewOrder
