async function getTokens(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tokens`, {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  }).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

async function getBuildingTokens(userToken, phase_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/tokens/building/${phase_id}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const tokens = await response.json()
  return tokens.data
}

async function getUserTokens(userToken, user_rfc) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/tokens/user/${user_rfc}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

async function createToken(userToken, newToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/tokens`, {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(newToken)
  }).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

async function updateToken(userToken, id, newValues) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/tokens/${id}`,
    {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(newValues)
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function deleteToken(userToken, id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/tokens/${id}`,
    {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

export { createToken, getTokens, getBuildingTokens, getUserTokens, updateToken, deleteToken }
