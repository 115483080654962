/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { User } from '../orders/interfaces'
import { Button } from '../../urvita-ui'
// assets
import profile from '../../assets/img/profile.png'
// services
import { editUser } from '../../services/users'

const InfoContainer = styled.section`
  .button-container {
    text-align: center;
    margin: 1rem 0;
  }
`

const GridContainer = styled.section`
  width: 100%;

  .image-container {
    grid-column: 1/3;
    align-items: center;
    display: flex;
    gap: 1rem;
    img {
      width: 100px;
    }

    .profile {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0.5rem;
    }

    .name {
      font-weight: 600;
      font-size: 2rem;
    }

    .subtitle {
      font-weight: 400;
      font-size: 1.5rem;
    }

    .intercom-button {
      background-color: #334afa;
      padding: 0.5rem 1rem;
      color: white;
      font-size: 0.8rem;
      border-radius: 4px;
      border: none;
    }
  }

  .regime {
    display: flex;
    gap: 1rem;
  }

  .verificado {
    border-radius: 8px;
    background-color: #2b89e2;
    color: white;
    padding: 2px 4px;
    font-size: 0.8rem;
    vertical-align: middle;
    margin: 0 0.5rem;
  }

  .contract {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .row {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
    align-items: center;
  }

  .contract-button {
    cursor: pointer;
    margin: 1rem 0;
  }

  .contract-button {
    padding: 0.3rem 0.5rem;
    background-color: #fbcfa4;
    font-weight: 450;
    border-radius: 1rem;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 0.8rem;
    color: black;
    font-size: 1rem;

    &:hover {
      background-color: orange;
    }

    &:disabled {
      background-color: lightgray;
      cursor: default;
    }
  }

  .generar-contrato > a {
    color: inherit;
  }
`

interface Params {
  user: User
  getUser: () => void
}
const BasicInfo = ({ user, getUser }: Params): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const [editRegime, setEditRegime] = useState(false)
  const [regime, setRegime] = useState(user.tax_regime || 'P_FISICA')

  const updateUser = async (): Promise<void> => {
    await editUser(userToken, user.id, { tax_regime: regime })
    setEditRegime(false)
    getUser()
  }

  return (
    <InfoContainer>
      <GridContainer>
        <section className="image-container">
          <img src={profile} alt="foto de perfil" />
          <section className="profile">
            <h2 className="name">
              <span>
                {user.name} {user.last_name}
                {user.verificated && (
                  <span className="verificado">Verificado</span>
                )}
              </span>
            </h2>

            {!editRegime && (
              <article className="regime">
                <p className="subtitle">
                  {user.tax_regime === 'P_FISICA'
                    ? 'Persona Física'
                    : user.tax_regime}
                </p>
                <Button text="Editar" onClick={() => setEditRegime(true)} />
              </article>
            )}

            {editRegime && (
              <article className="regime">
                <select
                  value={regime}
                  onChange={(e) => {
                    setRegime(e.target.value)
                  }}
                >
                  <option value="P_FISICA">Persona Física</option>
                  <option value="P_MORAL">Persona Moral</option>
                  <option value="P_FISICA_EX_USA">
                    Persona Física Extranjera
                  </option>
                  <option value="P_MORAL_EX_ES">
                    Persona Moral Extranjera
                  </option>
                </select>
                <Button text="Guardar" onClick={() => updateUser()} />
              </article>
            )}

            {user.intercom_id && (
              <a
                className="intercom-button"
                // eslint-disable-next-line max-len
                href={`https://app.intercom.com/a/apps/otyuzn6u/users/${user?.intercom_id}/all-conversations`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Intercom
              </a>
            )}
          </section>
        </section>
      </GridContainer>

      {/* <IntercomBlock user={user} /> */}
    </InfoContainer>
  )
}

export default BasicInfo
