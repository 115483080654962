import styled from 'styled-components'
import { colors } from '../layout/constants'

const UsersStyles = styled.section`
  .header {
    font-size: 2em;
    font-weight: 500;
  }

  .detail {
    color: ${colors.gridGray};
    font-size: 0.8em;
  }
  .user-tokens {
    margin: 40px 0;
  }

  .token-chip {
    padding: 5px;
    border-radius: 4px;
    margin: 3px;
    background-color: #4765ff;
    color: white;
    display: inline-block;
    font-size: 11px;
    cursor: pointer;
    width: 100px;
    overflow: hidden;
    transition: all 0.6s;
    text-align: center;
    :hover {
      filter: brightness(1.2);
    }
  }
`

export { UsersStyles }
