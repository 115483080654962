export async function getPaymentPlans(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/payment_plans`,
      {
        method: 'GET',
        headers: myHeaders
      }
    )

    const data = await response.json()
    console.log(data)
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export async function getUserPlan(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/payment_plans/user/${userId}`,
      {
        method: 'GET',
        headers: myHeaders
      }
    )

    const data = await response.json()
    console.log(data)
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}
