import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Card, Dropdown, Button, colors } from '../../urvita-ui'
import { ExtendedOrder } from './interfaces'

const HighlightContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;
  gap: 1rem;
`

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  height: 100%;

  .select {
    display: flex;
    margin: 1rem 0;
  }

  input {
    margin-left: 1rem;
    width: 130px;
  }

  h2 {
    font-size: 4rem;
    color: ${colors.primaryColor};
  }

  h3 {
    text-transform: capitalize;
  }
`

type Filter = {
  type: string
  value: string | number
}

type FilterFunction = (Filter: Filter) => void

const DateFilter = ({
  setFilter
}: {
  setFilter: FilterFunction
}): JSX.Element => {
  const [date, setDate] = useState<string>('')
  const [dateType, setDateType] = useState<string>('el día')
  const options = ['el día', 'antes de', 'después de']

  return (
    <Card>
      <Wrapper>
        <h3>Filtrar por Fecha</h3>
        <div className="select">
          <Dropdown
            options={options}
            onChange={(value: string) => setDateType(value)}
          />
          <input
            type="date"
            onChange={(event) => setDate(event.target.value)}
          />
        </div>
        <Button
          text="Filtrar"
          onClick={() => setFilter({ type: dateType, value: date })}
        />
      </Wrapper>
    </Card>
  )
}

const AmountFilter = ({
  setFilter
}: {
  setFilter: FilterFunction
}): JSX.Element => {
  const [value, setValue] = useState<number>(0)
  const [type, setType] = useState<string>('igual a')
  const options = ['igual a', 'menor a', 'mayor a']

  return (
    <Card>
      <Wrapper>
        <h3>Filtrar por Cantidad</h3>
        <div className="select">
          <Dropdown
            options={options}
            onChange={(inputValue: string) => setType(inputValue)}
          />
          <input
            type="number"
            onChange={(event) => setValue(Number(event.target.value))}
          />
        </div>
        <Button text="Filtrar" onClick={() => setFilter({ type, value })} />
      </Wrapper>
    </Card>
  )
}

const TypeFilter = ({
  setFilter
}: {
  setFilter: FilterFunction
}): JSX.Element => {
  const [value, setValue] = useState<string>('abono')
  const options = [
    'abono',
    'abono recurrente',
    'ganancia',
    'compra',
    'venta',
    'retiro',
    'intercambio',
    'reserva'
  ]

  return (
    <Card>
      <Wrapper>
        <h3>Filtrar por Tipo</h3>
        <div className="select">
          <Dropdown
            options={options}
            onChange={(inputValue: string) => setValue(inputValue)}
          />
        </div>
        <Button
          text="Filtrar"
          onClick={() => setFilter({ type: 'tipo', value })}
        />
      </Wrapper>
    </Card>
  )
}

const StatusFilter = ({
  setFilter
}: {
  setFilter: FilterFunction
}): JSX.Element => {
  const [value, setValue] = useState<string>('pagada')
  const options = [
    'pagada',
    'completada',
    'cancelada',
    'pendiente de factura',
    'pendiente de firma',
    'pendiente de pago',
    'por facturar',
    'por pagar',
    'DRAFT',
    'PAID'
  ]

  return (
    <Card>
      <Wrapper>
        <h3>Filtrar por Status</h3>
        <div className="select">
          <Dropdown
            options={options}
            onChange={(inputValue: string) => setValue(inputValue)}
          />
        </div>
        <Button
          text="Filtrar"
          onClick={() => setFilter({ type: 'status', value })}
        />
      </Wrapper>
    </Card>
  )
}

const OrdersHighlight = ({
  filter,
  setFilter,
  count,
  orders
}: {
  filter: Filter
  setFilter: FilterFunction
  count: number
  orders: ExtendedOrder[]
}): JSX.Element => {
  const [allOrdersCount, setAllOrdersCount] = useState(0)
  const [activeOrdersCount, setActiveOrdersCount] = useState(0)
  const [unpaidOrdersCount, setUnpaidOrdersCount] = useState(0)
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0)

  useEffect(() => {
    const allOrders = orders.filter(
      (order) => order.order_status !== 'DRAFT' && order.order_status !== 'PAID'
    )

    const activeOrders = orders.filter(
      (order) =>
        order.order_status !== 'DRAFT' &&
        order.order_status !== 'PAID' &&
        order.order_status !== 'completada' &&
        order.order_status !== 'pagada' &&
        order.order_status !== 'cancelada'
    )

    const unpaidOrders = orders.filter(
      (order) =>
        order.order_status === 'pendiente de pago' ||
        order.order_status === 'por pagar'
    )

    const completedOrders = orders.filter(
      (order) =>
        order.order_status === 'completada' || order.order_status === 'pagada'
    )

    setAllOrdersCount(allOrders.length)
    setActiveOrdersCount(activeOrders.length)
    setUnpaidOrdersCount(unpaidOrders.length)
    setCompletedOrdersCount(completedOrders.length)
  }, [orders])

  return (
    <HighlightContainer>
      <Card
        active={filter.type === 'active'}
        onClick={() => setFilter({ type: 'active', value: '' })}
        interactive
      >
        <Wrapper>
          <h3 className="content">Ordenes abiertas</h3>
          <h2>{activeOrdersCount}</h2>
        </Wrapper>
      </Card>
      <Card
        active={filter.type === 'unpaid'}
        onClick={() => setFilter({ type: 'unpaid', value: '' })}
        interactive
      >
        <Wrapper>
          <h3 className="content">Pendientes de pago</h3>
          <h2>{unpaidOrdersCount}</h2>
        </Wrapper>
      </Card>
      <Card
        active={filter.type === 'closed'}
        onClick={() => setFilter({ type: 'closed', value: '' })}
        interactive
      >
        <Wrapper>
          <h3 className="content">Completadas</h3>
          <h2>{completedOrdersCount}</h2>
        </Wrapper>
      </Card>

      <DateFilter setFilter={setFilter} />
    </HighlightContainer>
  )
}

export default OrdersHighlight
