async function getUsers(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users`, {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  }).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function getIntercomUser(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/intercom/${userId}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function getUsersByType(userToken, user_type) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/type/${user_type}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function getSingleUser(userToken, user_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/${user_id}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp.data
}

async function createUser(userToken, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/signup`,
    {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function editUser(userToken, id, newValues) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/${id}`,
    {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(newValues)
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function resetPassword(userToken, id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/${id}`,
    {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({
        password: 'urvita2020',
        to_change_password: true
      })
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function deleteUser(userToken, id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/users/${id}`,
    {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

async function sendVerificationMail(email) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/send_verification_mail`,
    {
      method: 'POST',
      headers: myHeaders,
      body: JSON.stringify({
        email
      })
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

export {
  getUsers,
  getUsersByType,
  getSingleUser,
  editUser,
  createUser,
  resetPassword,
  deleteUser,
  sendVerificationMail,
  getIntercomUser
}
