import React, { FC, HTMLAttributes } from 'react'
import styled from 'styled-components'
import { colors, layoutValues } from './constants'

interface StyledProps {
  active: boolean
  interactive: boolean
  backgroundColor: string | undefined
  noSidePadding: boolean
}

const CardWrapper = styled.article<StyledProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : 'white'};
  box-sizing: border-box;
  box-shadow: ${layoutValues.cardShadow};
  border-radius: ${layoutValues.cardRadius};
  cursor: ${(props) => (props.interactive ? 'pointer' : 'auto')};
  padding: ${(props) => (props.noSidePadding ? '1rem 0' : '1rem')};
  align-items: center;
  flex-grow: 1;

  border: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.noSidePadding
      ? 'none'
      : props.active
      ? `5px solid ${colors.secondaryColor}`
      : '5px solid transparent'};

  .content {
    color: ${colors.gridGray};
    font-size: 1.5rem;
  }

  &:hover {
    box-shadow: ${(props) =>
      props.interactive
        ? layoutValues.cardHighlightShadow
        : layoutValues.cardShadow};
  }
`

export interface Props extends HTMLAttributes<HTMLDivElement> {
  children?: JSX.Element | JSX.Element[]
  onClick?: () => void
  active?: boolean
  interactive?: boolean
  noSidePadding?: boolean
  backgroundColor?: string | undefined
}
const Card: FC<Props> = ({
  children,
  onClick,
  active,
  interactive,
  backgroundColor,
  noSidePadding
}) => (
  <CardWrapper
    onClick={() => (onClick ? onClick() : null)}
    active={!!active}
    interactive={!!interactive}
    noSidePadding={!!noSidePadding}
    backgroundColor={backgroundColor}
  >
    {children}
  </CardWrapper>
)

export default Card
