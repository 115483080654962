import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import currency from 'currency.js'
import { Link } from 'react-router-dom'
import { useSelector, useStore } from 'react-redux'
// layout
import dateFormat from '../helpers/dateFormat'
import { colors, layoutValues } from '../layout/constants'
// types
import { ExtendedUser } from '../orders/interfaces'
import { getIntercomUser } from '../../services/users'

const RowContainer = styled.li`
  align-items: center;
  color: ${colors.darkText};
  display: grid;
  grid-template-columns: 1fr 2fr repeat(6, 1fr);
  padding: 1rem 0;

  .user-id {
    font-size: 2rem;

    a {
      color: ${colors.gridGray};
      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  .big {
    font-size: 1.2rem;
    font-weight: 500;
  }

  .name {
    color: ${colors.darkText};
    text-align: left;
    text-transform: lowercase;
    text-transform: capitalize;

    :hover {
      color: ${colors.secondaryDark};
    }
  }

  .mono {
    font-family: monospace;
    text-align: right;
  }

  .type-label {
    border-radius: ${layoutValues.cardRadius};
    display: inline-block;
    padding: 8px;
    text-transform: capitalize;
    text-align: center;
    width: 100px;

    &.signed {
      color: white;
      background-color: ${colors.primaryLight};
    }

    &.toSign {
      background-color: lightblue;
    }

    &.toCreate {
      background-color: gold;
    }
  }
`

type userData = { user: ExtendedUser }

const UserRow = ({ user }: userData): JSX.Element => {
  const [externalId, setExternalId] = useState<string | undefined>()
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  useEffect(() => {
    const getExternalId = async (): Promise<void> => {
      const { data } = await getIntercomUser(userToken, user.id)
      setExternalId(data.external_id)
    }
    getExternalId()
  }, [user.intercom_id, userToken])

  const contractTranslation = (status: string | undefined): string => {
    switch (status) {
      case 'toCreate':
        return 'Por Generar'
      case 'toSign':
        return 'Pendiente de firma'
      case 'signed':
        return 'Firmado'
      default:
        return '-'
    }
  }

  const regimeTranslation = (status: string | undefined): string => {
    switch (status) {
      case 'P_FISICA':
        return 'Persona Física'
      case 'P_MORAL':
        return 'Persona Moral'
      default:
        return status || '-'
    }
  }

  return (
    <RowContainer key={user.id}>
      <div className="center big">{externalId}</div>
      <div>
        <Link to={`/usuarios/${user.id}`}>
          <h3 className="name big">
            {user?.name} {user?.last_name}
          </h3>
        </Link>
        <p>{user.email}</p>
      </div>
      <div className="center">{dateFormat(user.createdAt, 'short')}</div>
      <div className="center">{user.verificated ? 'si' : '-'}</div>
      <div className="center">
        <article className={`type-label ${user.contract_status}`}>
          {contractTranslation(user.contract_status)}
        </article>
      </div>
      <div className="center big">{user.tokens}</div>
      <div className="center">{regimeTranslation(user.tax_regime)}</div>
      <div className="mono left">
        {user.balance ? currency(user.balance).format() : 0}
      </div>
    </RowContainer>
  )
}

export default UserRow
