import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Switch, Route, Link, useRouteMatch } from 'react-router-dom'
// layout
import { Card } from '../../layout/card'
import Wrapper from '../../layout/wrapper'
import SectionTitle from '../../layout/shared/sectionTitle'
import { colors } from '../../layout/constants'
// components
import InfoCard from './dashboardInfoCard'
import TokenList from './dashboardTokenList'
import ValueRecord from './dashboardValueRecord'
import FormContainer from './buildingForm'
import { BuildingMetrics } from './buildingMetrics'
// services
import { getBuildingTokenTrades } from '../../../services/tokenTrades'
import { deleteBuilding } from '../../../services/buildings'
import { getBuildingTokens } from '../../../services/tokens'

const BuildingDashboard = ({ activeBuilding, userToken }) => {
  const [tokenList, setTokenList] = useState([])
  const [tokenTrades, setTokenTrades] = useState([])
  const { path } = useRouteMatch()
  const all = useRouteMatch()

  useEffect(() => {
    getTokenTrades()
    getTokens()
  }, [])

  const getTokens = async () => {
    const buildingTokens = await getBuildingTokens(
      userToken,
      activeBuilding.phase_id
    )

    if (buildingTokens.length) {
      setTokenList(sortTokenList(buildingTokens))
    }
  }

  const sortTokenList = (buildingTokens) =>
    buildingTokens.sort((a, b) => {
      if (a.token_id > b.token_id) {
        return 1
      }
      if (a.token_id < b.token_id) {
        return -1
      }
      return 0
    })

  const getTokenTrades = async () => {
    const trades = await getBuildingTokenTrades(
      userToken,
      activeBuilding.phase_id
    )
    setTokenTrades(trades.rows)
  }

  const removeBuilding = (phaseId) => {
    deleteBuilding(userToken, phaseId).then((resp) => {})
  }

  return (
    <BuildingDashboardStyles>
      <Wrapper>
        <SectionTitle
          title={activeBuilding.name}
          subtitle={activeBuilding.phase_id}
          link="/edificios"
          linkText="Regresar"
        />
        <section className="building-content">
          <Card>
            <InfoCard
              activeBuilding={activeBuilding}
              tokenList={tokenList}
              removeHandler={removeBuilding}
            />
          </Card>
          <div className="content">
            <Tabs>
              <Link
                className={
                  path === `/edificios/${activeBuilding.phase_id}/fool`
                    ? 'active tab'
                    : 'tab'
                }
                to={`/edificios/${activeBuilding.phase_id}`}
              >
                Resumen
              </Link>
              <Link
                className={
                  path === `/edificios/${activeBuilding.phase_id}/historial`
                    ? 'active tab'
                    : 'tab'
                }
                to={`/edificios/${activeBuilding.phase_id}/historial`}
              >
                Historial
              </Link>
              <Link
                className={
                  path === `/edificios/${activeBuilding.phase_id}/tokens`
                    ? 'active tab'
                    : 'tab'
                }
                to={`/edificios/${activeBuilding.phase_id}/tokens`}
              >
                Tokens
              </Link>
              <Link
                className={
                  path === `/edificios/${activeBuilding.phase_id}/info`
                    ? 'active tab'
                    : 'tab'
                }
                to={`/edificios/${activeBuilding.phase_id}/info`}
              >
                Información
              </Link>
            </Tabs>

            <Switch>
              <Route path={`/edificios/${activeBuilding.phase_id}`} exact>
                <BuildingMetrics
                  activeBuilding={activeBuilding}
                  userToken={userToken}
                />
              </Route>

              <Route
                path={`/edificios/${activeBuilding.phase_id}/historial`}
                exact
              >
                <ValueRecord
                  activeBuilding={activeBuilding}
                  userToken={userToken}
                  tokenTrades={tokenTrades}
                />
              </Route>

              <Route path={`/edificios/${activeBuilding.phase_id}/tokens`}>
                <TokenList
                  userToken={userToken}
                  activeBuilding={activeBuilding}
                  tokenList={tokenList}
                  getTokens={getTokens}
                />
              </Route>

              <Route path={`/edificios/${activeBuilding.phase_id}/info`}>
                <FormContainer
                  building={activeBuilding}
                  userToken={userToken}
                />
              </Route>
            </Switch>
          </div>
        </section>
      </Wrapper>
    </BuildingDashboardStyles>
  )
}

const BuildingDashboardStyles = styled.section`
  background-color: ${colors.lightBackground};
  color: ${colors.darkText};
  padding: 15px 0;

  .building-content {
    margin: 10px 0;
  }

  .content {
    align-items: flex-start;
    display: grid;
    gap: 1rem;
  }
`

const Tabs = styled.section`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;

  .tab {
    display: block;
    border: 1px solid ${colors.gridGray};
    border-radius: 8px;
    color: ${colors.darkText};
    cursor: pointer;
    font-weight: 500;
    padding: 20px 0;
    text-align: center;

    &:hover {
      background-color: ${colors.secondaryColor};
    }

    &.active {
      background-color: ${colors.secondaryDark};
    }
  }
`

export default BuildingDashboard
