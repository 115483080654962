/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import moment from 'moment'
import Swal from 'sweetalert2'
// types
import { Document } from '../../utils/types'
import { Button } from '../../urvita-ui'
// services
import { getDocument } from '../../services/weeTrust'

const DocumentsContainer = styled.section`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;

  .row {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    align-items: center;
  }

  .center {
    justify-content: center;
  }

  th,
  td {
    border-left: 1px solid;
    /* border-right: 1px solid; */
    padding: 0.5rem;
  }
`

interface Props {
  documentId: string
  deleteDocument: any
}

const Documents = ({ documentId, deleteDocument }: Props): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const [document, setDocument] = useState<Document>()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const getContractFromWeetrust = async (): Promise<void> => {
      setLoading(true)
      try {
        const { responseData } = await getDocument(userToken, documentId)

        if (responseData.documentID) {
          setDocument(responseData)
          console.log(responseData)
        }
        setLoading(false)
      } catch (error) {
        setLoading(false)
      }
    }

    if (userToken && documentId) {
      getContractFromWeetrust()
    }
  }, [documentId, userToken])

  const confirmDelate = (): void => {
    Swal.fire({
      title: '¿Quieres borrar el contrato?',
      showCancelButton: true,
      confirmButtonText: 'Borrar',
      cancelButtonText: `Cancelar`
    }).then(async (result) => {
      if (result.isConfirmed) {
        const resp = await deleteDocument(documentId)
        if (resp.success) {
          Swal.fire('¡Contrato borrado!', '', 'success')
        } else {
          Swal.fire(resp.message, '', 'error')
        }
      }
    })
  }

  return loading ? (
    <div>Cargando documentos..</div>
  ) : (
    <DocumentsContainer>
      {document && (
        <>
          <div>
            <p>Status: {document?.status}</p>
            <p>Creado: {moment(document?.addedOn).format('DD-MMMM-YYYY')}</p>
          </div>

          <table>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Estado</th>
                <th>Fecha de expiración</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {document?.signatory?.map((signer: any) => (
                <tr key={signer.signatoryID}>
                  <td>{signer.name}</td>
                  <td>{signer.isSigned ? 'Firmado' : 'Pendiente'}</td>
                  <td>
                    {moment(signer.signing.expiry).format('DD-MMMM-YYYY')}
                  </td>
                  {!signer.isSigned && (
                    <td>
                      <a
                        href={signer.signing.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button text="Ir a firmar" onClick={() => null} />
                      </a>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>

          <div className="row center">
            {document && (
              <a
                href={document?.sharedWith[0]?.url}
                target="_blank"
                rel="noreferrer"
              >
                <Button text="Ver documento" onClick={() => null} />
              </a>
            )}

            {document && (
              <Button
                text={loading ? 'Cargando' : 'Eliminar documento'}
                onClick={() => confirmDelate()}
              />
            )}
          </div>
        </>
      )}
    </DocumentsContainer>
  )
}

export default Documents
