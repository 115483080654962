import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
// layout
import DefaultLayout from '../layout'
import SectionTitle from '../layout/shared/sectionTitle'
import { colors, layoutValues } from '../layout/constants'
// components
import LoginForm from './loginForm'
// utils
import { setUserSession } from '../../utils/sesionManagment'
// state
import {
  setUserToken,
  setUserType,
  setUserEmail,
  setUserId
} from '../../state/actions'
// services
import { login } from '../../services/admin'

const Login = ({ history }) => {
  // // Redux hooks
  const dispatch = useDispatch()
  // state
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  // login service and error handler
  const loginHandler = async (userCredentials) => {
    setLoading(true)
    setError(null)
    try {
      const credentials = await login(userCredentials)
      setLoading(false)
      // Handle error
      if (credentials.error) {
        setError('Email o contraseña incorrectos')
        return credentials
      }

      // Save in session storage
      setUserSession(credentials.token, credentials.user.userType)

      // Save in redux state
      setUserToken(dispatch, credentials.token)
      setUserEmail(dispatch, userCredentials.email)
      setUserType(dispatch, credentials.user.userType)
      setUserId(dispatch, credentials.user.userId)
      history.push('/ordenes')
    } catch (error) {
      setLoading(false)
      setError('Algo salió mal, revisa tu conexión a internet')
      console.error(error)
    }
  }

  return (
    <ContainerStyled>
      <Card>
        <h1>Urvita Admin</h1>
        <h2>Iniciar sesión</h2>
        <LoginForm
          loginHandler={loginHandler}
          loading={loading}
          error={error}
        />
      </Card>
    </ContainerStyled>
  )
}

const ContainerStyled = styled.section`
  background-color: ${colors.primaryDark};
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100;
`

const Card = styled.section`
  background-color: ${colors.white};
  box-shadow: ${layoutValues.cardShadow};
  border-radius: ${layoutValues.cardRadius};
  padding: 2rem 1rem;

  h1 {
    font-size: 2em;
    letter-spacing: 1px;
    text-align: center;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 1.5em;
    letter-spacing: 1px;
    text-align: center;
  }
`

export default Login
