import React from 'react'
import styled from 'styled-components'

const Wrapper = ({ children }) => <WrapperStyled>{children}</WrapperStyled>

const WrapperStyled = styled.div`
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 5px;
  width: 100%;

  @media (max-width: 968px) {
    padding: 0 15px;
  }
`

export default Wrapper
