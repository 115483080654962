import React, { useState } from 'react'
import { NewFormStyles } from '../styles'
import { newBuilding } from '../../../services/buildings'

const NewBuildingForm = ({ toggleModal, updateBuildings, userToken }) => {
  const [loading, setLoading] = useState(false)

  const submitHandler = async (event) => {
    event.preventDefault()
    setLoading(true)
    const form = event.target

    // Setup variables
    const date = new Date()
    const name = form.name.value.toUpperCase()

    let phase_status = null
    if (date >= new Date(form.operation.value)) {
      phase_status = 'OPERATING'
    } else if (date >= new Date(form.construction.value)) {
      phase_status = 'CONSTRUCTION'
    } else {
      phase_status = 'PRECONSTRUCTION'
    }

    // Building body information
    const body = {
      creation_date: form.funding.value,
      construction_start_date: form.construction.value,
      operation_start_date: form.operation.value,
      phase_id: `${name.substring(0, 3)}P001`,
      name,
      phase_status,
      a_tokens: form.tokensA.value,
      b_tokens: form.tokensB.value,
      phase_cost: form.phaseCost.value,
      funding_token_noi: form.fundingTokenNoi.value,
      cap_rate: form.capRate.value,
      construction_rent_scale: form.constructionRentScale.value,
      preconstruction_value_generation: form.preconstValueGeneration.value,
      service_fee: form.serviceFee.value,
      poolup_order: form.poolupOrder.value,
      min_gain: form.minGain.value,
      max_gain: form.maxGain.value
    }

    try {
      const createdBuilding = await newBuilding(userToken, body)
      toggleModal(false)
      updateBuildings()
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  return (
    <NewFormStyles>
      <form onSubmit={submitHandler}>
        <div>
          <label htmlFor="name">Nombre</label>
          <input id="name" type="text" />
          <label htmlFor="funding">Fecha de fundación</label>
          <input id="funding" type="date" />
          <label htmlFor="construction">Fecha de inicio de contrucción</label>
          <input id="construction" type="date" />
          <label htmlFor="operation">Fecha de inicio de Operación</label>
          <input id="operation" type="date" />
          <label htmlFor="tokensA">Tokens A</label>
          <input id="tokensA" type="number" />
          <label htmlFor="tokensB">Tokens B</label>
          <input id="tokensB" type="number" />
          <label htmlFor="phaseCost">Costo de construcción</label>
          <input id="phaseCost" type="number" />
          <label htmlFor="fundingTokenNoi">Token NOI</label>
          <input id="fundingTokenNoi" type="number" step="0.00001" />
        </div>
        <div>
          <label htmlFor="capRate">Cap Rate</label>
          <input id="capRate" type="number" defaultValue="0.05" />
          <label htmlFor="constructionRentScale">Escala de renta</label>
          <input id="constructionRentScale" type="number" defaultValue="0.06" />
          <label htmlFor="preconstValueGeneration">Value generation</label>
          <input
            id="preconstValueGeneration"
            type="number"
            defaultValue="0.4"
          />
          <label htmlFor="serviceFee">Service Fee</label>
          <input id="serviceFee" type="number" defaultValue="0.35" />
          <label htmlFor="poolupOrder">Poolup Order</label>
          <input id="poolupOrder" type="number" defaultValue="0.50" />
          <label htmlFor="minGain">Min Gain</label>
          <input id="minGain" type="number" defaultValue="0.04" />
          <label htmlFor="maxGain">Max Gain</label>
          <input id="maxGain" type="number" defaultValue="0.10" />
        </div>
        <input
          className="submit-button"
          type="submit"
          value={loading ? 'Creando...' : 'Crear edificio'}
          disabled={loading}
        />
      </form>
    </NewFormStyles>
  )
}

export default NewBuildingForm
