import React, { useState } from 'react'
import styled from 'styled-components'
// views
import TransactionRow from './transactionRow'
import NewTransaction from '../newTransaction'
// layout
import SectionTitle from '../../layout/shared/sectionTitle'
import Modal from '../../layout/shared/modal'
import { colors } from '../../layout/constants'

const UserTransactions = ({
  userTransactions,
  highlightId,
  highlightRfc,
  handleUpdate
}) => {
  const [modal, setModal] = useState(false)

  const toggleModal = (value) => {
    setModal(value)
  }

  return (
    <TransactionsStyled>
      <SectionTitle
        subtitle="Transacciones"
        action="Crear transacción"
        handleClick={() => toggleModal(true)}
      />

      <ul className="transactions-list">
        {userTransactions.map((transaction) => (
          <TransactionRow
            key={transaction.id}
            transaction={transaction}
            handleUpdate={handleUpdate}
          />
        ))}
        <li>
          <span className="title">Fecha</span>
          <span className="title">Operación</span>
          <span className="title">Monto</span>
          <span className="title">Balance</span>
        </li>
      </ul>

      {modal && (
        <Modal toggleModal={toggleModal}>
          <NewTransaction
            highlightId={highlightId}
            highlightRfc={highlightRfc}
            handleUpdate={handleUpdate}
            toggleModal={toggleModal}
          />
        </Modal>
      )}
    </TransactionsStyled>
  )
}

const TransactionsStyled = styled.section`
  .transactions-list {
    list-style: none;
    display: flex;
    flex-direction: column-reverse;
  }

  li {
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 2fr 1fr 1fr max-content;
    grid-column-gap: 20px;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.gridGray};
    .title {
      font-weight: 500;
    }
  }
`

export default UserTransactions
