import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import currency from 'currency.js'
import { colors } from '../../layout/constants'
import moment from 'moment'
// components
import { Card } from '../../layout/card'
import { TransactionsTable } from './transactionsTable'
import { TransactionsGraph } from './transactionsGraph'
// services
import { getTokens, getBuildingTokens } from '../../../services/tokens'
import {
  getRecords,
  getBuildingRecords,
  getPeriodRecords
} from '../../../services/valueRecords'
import {
  getAllTokenTrades,
  getBuildingTokenTrades
} from '../../../services/tokenTrades'
import { getUsers } from '../../../services/users'
import { getOrders } from '../../../services/orders'

const GridContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 1rem;

  .secondary-background {
    background-color: ${colors.primaryColor};
    color: white;
  }

  .grid-2 {
    grid-column: span 2;
  }

  .grid-4 {
    grid-column: span 4;
  }

  .row-2 {
    grid-row: span 2;
  }

  .center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    h3 {
      font-weight: 300;
    }

    span {
      color: ${colors.secondaryDark};
    }
  }
`

export const BuildingMetrics = ({
  activeBuilding = { phase_id: 'all' },
  userToken
}) => {
  const [tokens, setTokens] = useState([])
  const [historicValues, setHistoricValues] = useState([])
  const [historicTrades, setHistoricTrades] = useState([])
  const [users, setUsers] = useState([])
  const [activeOrders, setActiveOrders] = useState([])
  const [currentValue, setCurrentValue] = useState(0)
  const [averageValue, setAverageValue] = useState(0)
  const [averageTokenValue, setAverageTokenValue] = useState(0)

  useEffect(() => {
    fetchTokens(userToken, activeBuilding.phase_id, setTokens)
    fetchHistoricValues(userToken, activeBuilding.phase_id, setHistoricValues)
    fetchHistoricTrades(userToken, activeBuilding.phase_id, setHistoricTrades)
    fetchUsers(userToken, setUsers)
    fetchOrders(userToken, activeBuilding.phase_id, setActiveOrders)
    if (activeBuilding.phase_id === 'all') {
      fetchPeriodValues(userToken, new Date(), setAverageTokenValue)
    }
  }, [])

  useEffect(() => {
    setCurrentValue(getCurrentValue(historicValues, activeBuilding.phase_id))
  }, [historicValues])

  return (
    <GridContainer>
      {/* Resumen principal */}
      <Card className="grid-2 secondary-background center">
        <h2>{activeBuilding.name}</h2>
      </Card>
      <Card className="grid-2">
        <div className="center">
          <h3>
            {activeBuilding.phase_id === 'all'
              ? 'Precio promedio del Token'
              : 'Precio del token'}
          </h3>
          <h2>
            {currency(
              activeBuilding.phase_id === 'all'
                ? averageTokenValue
                : currentValue,
              { precision: 0 }
            ).format()}
          </h2>
        </div>
      </Card>
      <Card className="grid-2">
        <div className="center">
          <h3>Porcentaje vendido</h3>
          <h2>{getSoldPercentage(tokens)}</h2>
        </div>
      </Card>

      {/* Ventas */}
      <Card className="grid-4 row-2">
        <TransactionsGraph transactions={historicTrades} />
      </Card>
      <Card className="grid-2 row-2">
        {users && historicTrades && (
          <TransactionsTable
            transactions={historicTrades}
            users={users}
            setAverageValue={setAverageValue}
          />
        )}
      </Card>

      {/* Resumen de tokens # */}
      <Card>
        <div className="center">
          <h3># de tokens</h3>
          <h2>{tokens.length}</h2>
          <span>100%</span>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3># Tokens de tipo B</h3>
          <h2>{getUrvitaTokens(tokens)}</h2>
          <span>{getBPercentage(tokens)}</span>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3># Tokens a la venta</h3>
          <h2>{getSellingTokens(tokens)}</h2>
          <span>{getAvailablePercentage(tokens)}</span>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3># Tokens de usuarios</h3>
          <h2>{getUsersTokens(tokens)}</h2>
          <span>{getUserPercentage(tokens)}</span>
        </div>
      </Card>
      <Card className="grid-2">
        <div className="center">
          <h3>Compra promedio</h3>
          <h2>{currency(averageValue, { precision: 0 }).format()}</h2>
        </div>
      </Card>

      {/* Resumen de tokens $ */}
      <Card>
        <div className="center">
          <h3>Valor Total</h3>
          <h2>
            {currency(getTotalTokens(tokens, true), { precision: 0 }).format()}
          </h2>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3>Valor Tokens tipo B</h3>
          <h2>
            {currency(getUrvitaTokens(tokens, true), {
              precision: 0
            }).format()}
          </h2>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3>Valor de inventario</h3>
          <h2>
            {currency(getSellingTokens(tokens, true), {
              precision: 0
            }).format()}
          </h2>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3>Valor en Usuarios</h3>
          <h2>
            {currency(getUsersTokens(tokens, true), {
              precision: 0
            }).format()}
          </h2>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3>Mercado secundario</h3>
          <h2>{activeOrders}</h2>
          <span>Ordenes activas</span>
        </div>
      </Card>
      <Card>
        <div className="center">
          <h3>Mercado secundario</h3>
          <h2>
            {currency(getSecondaryMarketValue(historicTrades), {
              precision: 0
            }).format()}
          </h2>
          <span>Total transaccionado</span>
        </div>
      </Card>
    </GridContainer>
  )
}

// Helper functions
async function fetchUsers(userToken, setUsers) {
  try {
    const { data: users } = await getUsers(userToken)
    setUsers(users)
  } catch (err) {
    console.error(err) // handle error
  }
}

async function fetchTokens(userToken, phase_id, setTokens) {
  let tokens
  try {
    if (phase_id === 'all') {
      tokens = await getTokens(userToken)
    } else {
      tokens = await getBuildingTokens(userToken, phase_id)
    }
    setTokens(tokens)
  } catch (err) {
    console.error(err) // handle error
  }
}

async function fetchHistoricValues(userToken, phase_id, setHistoricValues) {
  let values
  try {
    if (phase_id === 'all') {
      values = await getRecords(userToken)
    } else {
      values = await getBuildingRecords(userToken, phase_id)
    }
    setHistoricValues(values)
  } catch (err) {
    console.error(err) // handle error
  }
}

async function fetchPeriodValues(userToken, date, setAverageTokenValue) {
  const formattedDate = moment(date)
    .subtract(2, 'month')
    .endOf('month')
    .format('YYYY-MM-DD')
  try {
    const values = await getPeriodRecords(userToken, formattedDate)
    const sum = values.reduce((acc, value) => {
      return acc + Number(value.value_a)
    }, 0)
    const average = sum / values.length
    setAverageTokenValue(average)
  } catch (err) {
    console.error(err) // handle error
  }
}

async function fetchHistoricTrades(userToken, phase_id, setHistoricTrades) {
  let trades
  try {
    if (phase_id === 'all') {
      trades = await getAllTokenTrades(userToken)
    } else {
      const response = await getBuildingTokenTrades(userToken, phase_id)
      trades = response.rows
    }
    setHistoricTrades(trades)
  } catch (err) {
    console.error(err) // handle error
  }
}

async function fetchOrders(userToken, phase_id, setActiveOrders) {
  try {
    const { data: orders } = await getOrders(userToken)
    const sellOrders = orders.filter((order) => {
      if (
        order?.order_type === 'venta' &&
        order.phase_name &&
        order.order_status === 'pendiente de pago'
      ) {
        if (phase_id === 'all') {
          return order
        } else {
          return order.phase_name.includes(phase_id)
        }
      }
    })

    const tokensInOrder = sellOrders.map((order) => {
      let regex = new RegExp(phase_id, 'g')

      if (phase_id === 'all') {
        regex = new RegExp('P001', 'g')
      }

      let count = (order.phase_name.match(regex) || []).length
      return count
    })

    const sum = tokensInOrder.reduce(
      (partialSum, a) => partialSum + Number(a),
      0
    )

    setActiveOrders(sum)
  } catch (err) {
    console.error(err) // handle error
  }
}

function getTotalTokens(tokens, amount = false) {
  if (!amount) {
    return tokens.length
  }

  return getTotalValue(tokens)
}

function getUrvitaTokens(tokens, amount = false) {
  const filteredTokens = tokens.filter((token) => token.type === 'TOKEN-B')

  if (!amount) {
    return filteredTokens.length
  }

  return getTotalValue(filteredTokens)
}

function getSellingTokens(tokens, amount = false) {
  let filteredTokens = tokens.filter(
    (token) => token.owner_rfc === 'URV150302I90' && token.type === 'TOKEN-A'
  )

  if (!amount) {
    return filteredTokens.length
  }

  return getTotalValue(filteredTokens)
}

function getUsersTokens(tokens, amount = false) {
  const filteredTokens = tokens.filter(
    (token) => token.owner_rfc !== 'URV150302I90'
  )

  if (!amount) {
    return filteredTokens.length
  }

  return getTotalValue(filteredTokens)
}

function getCurrentValue(historicValue, phase_id) {
  if (historicValue.length) {
    if (phase_id === 'all') {
    } else {
      return historicValue[0].value_a
    }
  }
  return 0
}

function getTotalValue(filteredTokens) {
  const tokenValues = filteredTokens.map((token) => token.current_value)

  const totalAmount = tokenValues.reduce((acc, value) => acc + Number(value), 0)
  return totalAmount
}

function getSoldPercentage(tokens) {
  const sold = getUsersTokens(tokens)
  const tokenB = getUrvitaTokens(tokens)
  const total = tokens.length
  const percentage = ((sold + tokenB) / total) * 100
  return percentage.toFixed(2) + '%'
}

function getUserPercentage(tokens) {
  const sold = getUsersTokens(tokens)
  const total = tokens.length
  const percentage = (sold / total) * 100
  return percentage.toFixed(2) + '%'
}

function getAvailablePercentage(tokens) {
  const selling = getSellingTokens(tokens)
  const total = tokens.length
  const percentage = (selling / total) * 100
  return percentage.toFixed(2) + '%'
}

function getBPercentage(tokens) {
  const tokenB = getUrvitaTokens(tokens)
  const total = tokens.length
  const percentage = (tokenB / total) * 100
  return percentage.toFixed(2) + '%'
}

function getOpenOrders(orders) {
  return orders.filter(
    (order) =>
      order.order_status === 'por pagar' ||
      order.order_status === 'pendiente de pago'
  ).length
}

function getSecondaryMarketValue(transactions) {
  const tokensSold = transactions.filter(
    (transaction) => transaction.buyer_rfc === 'URV150302I90'
  )

  const amount = tokensSold.reduce((acc, token) => {
    acc += Number(token.transaction_amount)
    return acc
  }, 0)

  return amount
}
