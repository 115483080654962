import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// helpers
import { newTransaction } from '../../services/transactions'
import { newTokenTrade } from '../../services/tokenTrades'
// layout
import SectionTitle from '../layout/shared/sectionTitle'
import Button from '../layout/shared/button'
import { colors } from '../layout/constants'
import Swal from 'sweetalert2'

const NewTransaction = ({ highlightRfc, handleUpdate, handleUpdateList }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  const userEmail = useSelector((state) => state.user.email)

  // State
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const [textNote, setTextNote] = useState('')

  // Transaction
  const [type, setType] = useState('CXC_VTA_TOKENS')
  const [date, setDate] = useState('')
  const [subtotal, setSubtotal] = useState(0)

  // Token Trade
  const [tokenId, setTokenId] = useState('')
  const [channel] = useState('MARKETPLACE')

  const sendTransactionData = async () => {
    Swal.fire({
      title: '¿Quieres guardar los cambios?',
      showCancelButton: true,
      confirmButtonText: 'Guardar',
      cancelButtonText: `Cancelar`
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire('Guardados!', '', 'success')
        setLoading(true)

        if (type === 'CXC_VTA_TOKENS') {
          try {
            // Trade body information
            const trade = {
              transaction_date: date,
              token_id: tokenId,
              buyer_rfc: highlightRfc,
              seller_rfc: 'URV150302I90',
              transaction_amount: subtotal,
              transaction_channel: channel,
              note: textNote,
              owner: userEmail
            }
            const newTrade = await newTokenTrade(userToken, trade)
            if (newTrade.error) {
              setError(true)
              setErrorMessage(
                'Hubo un problema al realizar el intercambio de tokens.'
              )
              return
            }
            const newOperation = await newTransaction(userToken, body)
            setLoading(false)
            if (newOperation.error) {
              setError(true)
              setErrorMessage('Hubo un problema al registrar la transacción.')
              return
            }
            handleUpdate()
          } catch (error) {
            setError(true)
            setErrorMessage('Hubo un problema con la operación.')
            console.error(error)
          }
        } else {
          // Transaction body information
          try {
            const body = {
              transaction_date: date,
              user_rfc: highlightRfc,
              target_rfc: type === 'CXC_VTA_TOKENS' ? 'URV150302I90' : null,
              type,
              subtotal,
              note: textNote,
              owner: userEmail
            }
            const newOperation = await newTransaction(userToken, body)
            setLoading(false)
            if (newOperation.error) {
              setError(true)
              setErrorMessage('Hubo un problema al registrar la transacción.')
              return
            }
            handleUpdate()
          } catch (error) {
            setError(true)
            setErrorMessage('Hubo un problema con la operación.')
            console.error(error)
          }
        }
        handleUpdateList()
      }
    })
  }

  return (
    <FromStyled>
      <SectionTitle title="Nueva transacción" />
      <form className="transaction-form">
        <label>Tipo</label>
        <select value={type} onChange={(event) => setType(event.target.value)}>
          <option value="CXC_VTA_TOKENS">Venta de tokens</option>
          <option value="COBRANZA_DEPOSITO">Abono</option>
          <option value="CXP_PROVEEDOR">Intercambio</option>
          <option value="COBRANZA_DEPOSITO_RECURRENTE">Abono recurrente</option>
          <option value="PAGO_RETIRO">Retiro de usuario</option>
          <option value="CXP_REINVERTIR_POR_COMISION">
            Ganancia por comisión
          </option>
          <option value="CXP_REINVERTIR_POR_PROMOCION">
            Ganancia por promoción
          </option>
          <option value="CXC_RESERVAS">Hospedaje</option>
          <option value="CXP_AJUSTE">Ajuste positivo</option>
          <option value="CXC_AJUSTE">Ajuste negativo</option>
          <option value="BONO">Bono</option>
        </select>
        <label>Fecha</label>
        <input
          type="date"
          value={date}
          onChange={(event) => setDate(event.target.value)}
        />
        {type === 'CXC_VTA_TOKENS' ? (
          <>
            <label>token id</label>
            <input
              type="text"
              value={tokenId}
              onChange={(event) => setTokenId(event.target.value)}
            />
          </>
        ) : null}
        <label>Subtotal</label>
        <input
          type="number"
          value={subtotal}
          onChange={(event) => setSubtotal(event.target.value)}
        />
        <label>Nota</label>
        <textarea
          placeholder="Nota..."
          onChange={(e) => setTextNote(e.target.value)}
        />
        <Button
          text={loading ? 'Creando...' : 'Crear transacción'}
          clickHandler={sendTransactionData}
        />
        {error ? <p className="error">{errorMessage}</p> : null}
      </form>
    </FromStyled>
  )
}

const FromStyled = styled.section`
  .transaction-form {
    display: flex;
    flex-direction: column;

    input,
    select,
    textarea {
      display: block;
      margin: 5px 0 10px 0;
    }
  }

  textarea {
    padding: 5px;
    resize: vertical;
  }

  .error {
    color: ${colors.highlightColor};
  }
`

export default NewTransaction
