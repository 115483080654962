import React from 'react'
import styled from 'styled-components'
import { layoutValues } from '../layout/constants'
// types
import { ExtendedOrder } from './interfaces'
// Components
import OrderRow from './orderRow'

const ListContainer = styled.section`
  background-color: white;
  border-radius: ${layoutValues.cardRadius};
  border-top-left-radius: 0;
  box-shadow: ${layoutValues.cardShadow};
  padding: 1rem;
`

const ListHeader = styled.header`
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  color: gray;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 100px 2fr 100px 120px repeat(3, 1fr) 120px 100px;
  text-align: center;

  .name {
    text-align: left;
  }

  .sort-button {
    cursor: pointer;
    :after {
      content: ' ↓';
    }
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column-reverse;
  list-style: none;
  padding: 0;
`

type Sort = {
  type: string
  value: string | number
}

type SortFunction = (Sort: Sort) => void

const OrdersList = ({
  orders,
  setSort,
  simulations,
  sells
}: {
  orders: ExtendedOrder[]
  setSort: SortFunction
  simulations: boolean
  sells: boolean
}): JSX.Element => {
  const filterOrders = orders.filter((order: ExtendedOrder) => {
    if (simulations) {
      return order.order_status === 'open'
    }

    if (sells) {
      return order.order_type === 'venta'
    }

    return order.order_status !== 'open'
  })

  return (
    <ListContainer>
      <ListHeader>
        <span
          className="sort-button"
          onClick={() => setSort({ type: 'id', value: 'asc' })}
          onKeyDown={() => setSort({ type: 'id', value: 'asc' })}
          role="button"
          tabIndex={0}
        >
          # de Orden
        </span>
        <span className="name">Usuario</span>
        <span
          className="sort-button"
          onClick={() => setSort({ type: 'id', value: 'asc' })}
          onKeyDown={() => setSort({ type: 'id', value: 'asc' })}
          role="button"
          tabIndex={0}
        >
          Creada
        </span>
        <span>Tipo</span>
        <span>Cantidad de Tokens</span>
        <span>Lista de token</span>
        <span
          className="sort-button"
          onClick={() => setSort({ type: 'subtotal', value: 'asc' })}
          onKeyDown={() => setSort({ type: 'subtotal', value: 'asc' })}
          role="button"
          tabIndex={0}
        >
          Subtotal
        </span>
        <span>Status</span>
        <span
          className="sort-button"
          onClick={() => setSort({ type: 'date', value: 'asc' })}
          onKeyDown={() => setSort({ type: 'date', value: 'asc' })}
          role="button"
          tabIndex={0}
        >
          Actualizado
        </span>
      </ListHeader>
      <List>
        {filterOrders.map((order: ExtendedOrder) => (
          <OrderRow key={order.id} order={order} />
        ))}
      </List>
    </ListContainer>
  )
}

export default OrdersList
