import React from 'react'
import NewBuildingForm from './newBuildingForm'
import SectionTitle from '../../layout/shared/sectionTitle'

const NewBuildingView = ({ toggleModal, updateBuildings, userToken }) => (
  <>
    <SectionTitle title="Nuevo edificio" />
    <NewBuildingForm
      userToken={userToken}
      toggleModal={toggleModal}
      updateBuildings={updateBuildings}
    />
  </>
)

export default NewBuildingView
