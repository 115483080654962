import React, { useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
// layout
import DefaultLayout from '../layout'
import Wrapper from '../layout/wrapper'
// components
import FilterHeader from './filterHeader'
import OwnersTable from './ownersTable'
// services
import { getTokens } from '../../services/tokens'

const Tokens = () => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  const [activeTab, setActiveTab] = useState('MICP001')

  // Query
  const { isLoading, isError, data, error } = useQuery('tokens', () =>
    getTokens(userToken)
  )

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  const getBuildings = (data) => {
    const buildings = data.reduce((list, token, indice, vector) => {
      const updatedList = new Set([...list, token.building_phase_id])

      return updatedList
    }, [])
    return Array.from(buildings)
  }

  const getFilteredTokens = (data) => {
    const tokens = data.filter((token) => token.building_phase_id === activeTab)
    return tokens
  }

  return (
    <DefaultLayout sidenav>
      <Wrapper>
        <FilterHeader
          buildingList={getBuildings(data)}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <GridContainer>
          <OwnersTable tokens={getFilteredTokens(data)} />
          <div />
          <div />
          <div />
          <div />
        </GridContainer>
      </Wrapper>
    </DefaultLayout>
  )
}

const GridContainer = styled.section`
  display: grid;
  grid-template-columns: 1fr;
`
export default Tokens
