import React from 'react'
import styled from 'styled-components'
// helpers
import dateFormat from '../../helpers/dateFormat'
// components
import UserRow from './userRow'
import moment from 'moment'

const UsersTable = ({
  period,
  usersList,
  transactionsList,
  previewList,
  reinvestBuys
}) => {
  const month = moment(period).format('MM')
  // Filter transactions by user
  const filteredTransactions = (userRfc) => {
    const userTransactions = transactionsList.filter(
      (transaction) => transaction.user_rfc === userRfc
    )

    return userTransactions
  }

  const filteredBuys = (userRfc) => {
    const userTransactions = reinvestBuys.filter(
      (transaction) => transaction.user_rfc === userRfc
    )

    return userTransactions
  }

  return (
    <TableStyled>
      <li className="user-item">
        <h3>Nombre</h3>
        <h3>Fecha</h3>
        <h3>Operaciones de {dateFormat(period, 'month')}</h3>
        <h3 className="amount-column">Subtotal</h3>
        <h3 className="amount-column">Monto</h3>
        <h3 className="amount-column">Balance</h3>
      </li>
      {usersList && transactionsList
        ? usersList.map((user, index) => (
            <UserRow
              key={user.id}
              id={index + 1}
              name={user.name}
              lastName={user.last_name}
              rfc={user.rfc}
              userTransactions={filteredTransactions(user.rfc)}
              userBuys={filteredBuys(user.rfc)}
              periodMonth={month}
            />
          ))
        : null}
    </TableStyled>
  )
}

const TableStyled = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  .user-item {
    align-items: center;
    border-bottom: 2px solid black;
    display: grid;
    grid-template-columns: 300px 100px 280px 160px 160px 160px;
    padding: 10px 0;
  }

  .amount-column {
    text-align: right;
  }
`

export default UsersTable
