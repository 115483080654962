import React, { useEffect, useState } from 'react'
import { InfoCardStyles } from '../styles'
// assets
import pascualImg from '../../../assets/img/buildings/pascual.png'
import micaelaImg from '../../../assets/img/buildings/micaela.jpg'
import noeImg from '../../../assets/img/buildings/noe.jpg'
import victoriaImg from '../../../assets/img/buildings/victoria.jpg'
import hernanImg from '../../../assets/img/buildings/hernan.jpeg'
import evaImg from '../../../assets/img/buildings/eva.jpg'
import juanitoImg from '../../../assets/img/buildings/juanito.jpg'
import genericImg from '../../../assets/img/buildings/generic.jpg'

const InfoCard = ({ activeBuilding, removeHandler, tokenList }) => {
  const [availableTokens, setAvailableTokens] = useState()
  const [totalTokensA, setTotalTokensA] = useState()
  const [totalTokensB, setTotalTokensB] = useState()
  const nameLower = activeBuilding.name.toLowerCase()

  useEffect(() => {
    const tokens = tokenList.filter(
      (token) => token.available && token.type === 'TOKEN-A'
    )
    const tokensA = tokenList.filter((token) => token.type === 'TOKEN-A')
    const tokensB = tokenList.filter((token) => token.type === 'TOKEN-B')
    setAvailableTokens(tokens.length)
    setTotalTokensA(tokensA.length)
    setTotalTokensB(tokensB.length)
  }, [tokenList])

  let image = ''

  switch (nameLower) {
    case 'micaela':
      image = micaelaImg
      break
    case 'noe ':
      image = noeImg
      break
    case 'pascual':
      image = pascualImg
      break
    case 'victoria':
      image = victoriaImg
      break
    case 'hernan':
      image = hernanImg
      break
    case 'eva':
      image = evaImg
      break
    case 'juanito':
      image = juanitoImg
      break
    default:
      image = genericImg
      break
  }

  return (
    <InfoCardStyles>
      <figure className="image-container">
        <img className="building-img" src={image} alt={activeBuilding.name} />
      </figure>
      <ul className="list">
        <li className="row">
          <h4 className="title">Nombre</h4>
          <p className="text">{activeBuilding.name}</p>
        </li>
        <li className="row">
          <h4 className="title">id</h4>
          <p className="text">{activeBuilding.phase_id}</p>
        </li>
        <li className="row">
          <h4 className="title">Fundación</h4>
          <p className="text">{activeBuilding.creation_date}</p>
        </li>
        <li className="row">
          <h4 className="title">Estatus</h4>
          <p className="text">{activeBuilding.phase_status}</p>
        </li>
        <li className="row">
          <h4 className="title">Tokens A (disponibles)</h4>
          <p className="text">
            {availableTokens} / {totalTokensA}
          </p>
        </li>
        <li className="row">
          <h4 className="title">Tokens B</h4>
          <p className="text">{totalTokensB}</p>
        </li>
      </ul>
      <button
        className="delete-button"
        onClick={() => removeHandler(activeBuilding.phase_id)}
        disabled
      >
        Borrar edificio
      </button>
    </InfoCardStyles>
  )
}

export default InfoCard
