import { Transaction, User } from './types'

export const getPricesMarkets = (
  transactions: Transaction[]
): { primaryMarketResult: number; secondaryMarketResult: number } => {
  const PRIMARY_RFC = 'URV150302I90'
  const { primaryMarketResult, secondaryMarketResult } = transactions.reduce(
    (acc: any, transaction: Transaction) => {
      const subtotal = Number(transaction.subtotal)
      if (transaction.target_rfc === PRIMARY_RFC) {
        acc.primaryMarketResult += subtotal
      } else if (
        transaction.target_rfc !== PRIMARY_RFC &&
        transaction.type === 'CXC_VTA_TOKENS'
      ) {
        acc.secondaryMarketResult += subtotal
      }
      return acc
    },
    { primaryMarketResult: 0, secondaryMarketResult: 0 }
  )

  return { primaryMarketResult, secondaryMarketResult }
}

export const getAccumulatedBalance = (users: User[]): number => {
  const filteredUser = users.filter((user) => user.rfc !== 'URV150302I90')
  const total = filteredUser.reduce(
    (accumulator, currentValue) => accumulator + Number(currentValue.balance),
    0
  )
  return total
}

export const getAccumulatedOrders = (
  transactions: Transaction[]
): { orders: number; total: number } => {
  const filteredOrders: Transaction[] = transactions.filter(
    (transaction: Transaction) => transaction.type === 'PAGO_RETIRO'
  )

  const total: number = filteredOrders.reduce(
    (accumulator: number, transaction: Transaction) =>
      accumulator + Number(transaction.subtotal),
    0
  )

  return { orders: filteredOrders.length, total }
}

export const filterTransactions = (
  transactions: Transaction[],
  type: string
): Transaction[] => {
  if (type === 'CXP_REINVERTIR') {
    return transactions.filter(
      (transaction: Transaction) =>
        (transaction.type === 'CXP_REINVERTIR' ||
          transaction.type === 'CXP_RECIBIR' ||
          transaction.type === 'CXP_RECIBIR_DE_BALANCE' ||
          transaction.type === 'CXP_REINVERTIR_DE_BALANCE') &&
        transaction.subtotal > 0 &&
        transaction.user_rfc !== 'URV150302I90'
    )
  }

  return transactions.filter(
    (transaction: Transaction) =>
      transaction.type === type &&
      transaction.subtotal > 0 &&
      transaction.user_rfc !== 'URV150302I90'
  )
}

export const sumTransactions = (transactions: Transaction[]): number =>
  transactions.reduce(
    (total: number, transaction: Transaction) =>
      total + Number(transaction.subtotal),
    0
  )
