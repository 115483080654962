import React, { useState } from 'react'
import styled from 'styled-components'
import Sidenav from './sidenav'
import Footer from './footer'
import { colors } from './constants'

const DefaultLayoutStyled = styled.section`
  display: grid;
  grid-template-columns: 200px 1fr;
  font-family: 'calibre';

  .container {
    background-color: ${colors.lightBackground};
    min-height: 100vh;
  }

  @media (max-width: 968px) {
    grid-template-columns: 1fr;
  }
`

const Layout = (props) => {
  const { children, sidenav } = props

  const [showSidenav, setShowSidenav] = useState(false)

  const toggleSidenav = (isActive) => {
    setShowSidenav(isActive)
  }

  return (
    <DefaultLayoutStyled>
      {sidenav && (
        <Sidenav active={showSidenav} toggleSidenav={toggleSidenav} />
      )}
      <section className="container">
        {children}
        {/* <Footer /> */}
      </section>
    </DefaultLayoutStyled>
  )
}

export default Layout
