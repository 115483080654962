import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Chip from '@mui/material/Chip'
import { Link } from 'react-router-dom'
import currency from 'currency.js'
import moment from 'moment'
// components
import LastTransaction from './lastTransaction'
// layout
import { colors } from '../layout/constants'
import Button from '../layout/shared/button'
import { NewDataSubscription, Subscription } from '../../interfaces'

const StyledTitle = styled.h3`
  color: ${colors.darkText};
  :hover {
    color: orange;
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

interface props {
  subscription: Subscription
  index: number
  openModal: (type: string) => void
  setActiveSubscription: (subscription: Subscription) => void
  setEditSubscription: (value: boolean) => void
  setNewData: (value: NewDataSubscription) => void
}

const SubscriptionRow = ({
  subscription,
  index,
  openModal,
  setActiveSubscription,
  setEditSubscription,
  setNewData
}: props): JSX.Element => {
  const [lastPay, setLastPay] = useState<Date>()
  const [payed, setPayed] = useState<boolean>()

  useEffect(() => {
    if (lastPay) {
      const currentMonth = Number(moment().format('MM'))
      const lastPayMonth = Number(moment(lastPay).format('MM'))

      if (currentMonth === lastPayMonth) {
        setPayed(true)
      } else {
        setPayed(false)
      }
    }
  }, [lastPay])

  return (
    <TableRow
      key={subscription.id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        backgroundColor: payed ? '#e8f7eb' : 'transparent'
      }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell align="left">
        <Link to={`/usuarios/${subscription.user_id}`}>
          <StyledTitle>
            {subscription?.user.name} {subscription?.user.last_name}
          </StyledTitle>
        </Link>
      </TableCell>
      <TableCell align="center">
        {subscription.subscription_active ? (
          <Chip label="Activada" color="success" />
        ) : (
          <Chip label="En Pausa" variant="outlined" />
        )}
      </TableCell>
      <TableCell align="right">
        {currency(subscription.subscription_amount).format()}
      </TableCell>
      <TableCell align="right">
        {subscription.subscription_stripe_subscription_id ? '2/12' : '-'}{' '}
      </TableCell>
      <TableCell align="center">
        {subscription.subscription_charge_date}
      </TableCell>
      <TableCell align="center">
        <LastTransaction
          userId={subscription.user_id}
          lastPay={lastPay}
          setLastPay={setLastPay}
        />
      </TableCell>
      <TableCell align="center">
        {subscription.subscription_stripe_subscription_id ? (
          <a
            href={`https://dashboard.stripe.com/subscriptions/${subscription.subscription_stripe_subscription_id}`}
            target="_blank"
            rel="noreferrer"
          >
            Ver en Stripe
          </a>
        ) : (
          <Button
            text="Realizar cobro"
            clickHandler={() => {
              setActiveSubscription(subscription)
              setEditSubscription(false)
              openModal('charge')
            }}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <StyledButton
          type="button"
          onClick={() => {
            setEditSubscription(true)
            setNewData({
              newAmount: Number(subscription.subscription_amount),
              newChargeDate: Number(subscription.subscription_charge_date),
              newStatus: subscription.subscription_active
            })
            setActiveSubscription(subscription)
            openModal('edit')
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M21.65 3.434a4.889 4.889 0 1 1 6.915 6.914l-.902.901l-6.914-6.914zM19.335 5.75L4.357 20.73a3.7 3.7 0 0 0-1.002 1.84l-1.333 6.22a1 1 0 0 0 1.188 1.188l6.22-1.333a3.7 3.7 0 0 0 1.84-1.002l14.98-14.98z"
            />
          </svg>
        </StyledButton>
      </TableCell>
    </TableRow>
  )
}

export default SubscriptionRow
