import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getToken } from '../utils/sesionManagment'

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      !getToken() ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/ordenes' }} />
      )
    }
  />
)

export default PublicRoute
