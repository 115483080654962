import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// helpers
import { getUsersByType } from '../../../services/users'
import { getPeriodTransactions } from '../../../services/transactions'
import { usersOperationsPreview } from '../../../services/monthOperations'
import dateFormat from '../../helpers/dateFormat'
// components
import UsersTable from './usersTable'
import PreviewTable from './previewTable'
// layout
import Button from '../../layout/shared/button'
import SectionTitle from '../../layout/shared/sectionTitle'
import Loader from '../../layout/shared/loader'

const UsersSection = ({ period, lastPeriod, nextPeriod }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // State
  const [usersList, setUsersList] = useState([])
  const [transactionsList, setTransactionsList] = useState([])
  const [previewList, setPreviewList] = useState([])
  const [reinvestBuys, setReinvestBuys] = useState([])
  const [loading, setLoading] = useState(false)
  const [toGenerate, setToGenerate] = useState(true)
  const [selectedPeriod, setSelectedPeriod] = useState('')

  // Get intial data
  useEffect(() => {
    if (period) {
      askForUsers()
      setSelectedPeriod(period)
    }
  }, [period, lastPeriod])

  useEffect(() => {
    if (selectedPeriod) {
      askForTransactions(selectedPeriod)
    }
  }, [selectedPeriod])

  // Check if reinvestment operations are present to disable double gain generation
  useEffect(() => {
    const reinvestOperations = transactionsList.find(
      (transaction) =>
        (transaction.indicator === 'gain-reinvest' &&
          transaction.type !== 'CXP_REINVERTIR_POR_COMISION' &&
          transaction.type !== 'CXP_REINVERTIR_POR_PROMOCION') ||
        transaction.indicator === 'gain-withdraw'
    )
    if (reinvestOperations) {
      setToGenerate(false)
    }
  }, [transactionsList])

  // Get Users data
  const askForUsers = async () => {
    const users = await getUsersByType(userToken, 'investor')
    setUsersList(users.data)
  }

  // Get Preview data
  const getPreviewData = async () => {
    setLoading(true)
    const preview = await usersOperationsPreview(userToken, period, false)
    setPreviewList(preview.usersPreview.userMovements)
    setLoading(false)
  }

  // Get final transactions
  const askForTransactions = async (period) => {
    const transactions = await getPeriodTransactions(userToken, period)
    const nextTransactions = await getPeriodTransactions(userToken, nextPeriod)
    setTransactionsList(transactions.data)
    setReinvestBuys(nextTransactions.data)
  }

  // Generate end of month operations
  const generateOperation = async () => {
    setLoading(true)
    await usersOperationsPreview(userToken, period, true)
    await askForTransactions()
    setLoading(false)
  }

  const getUserName = (user_rfc) => {
    const singleUser = usersList.find((user) => user.rfc === user_rfc)
    return singleUser
  }

  const downloadMovements = () => {
    const formattedList = transactionsList.map((transaction) => {
      const user = getUserName(transaction.user_rfc)
      const userName = `${user.name} ${user.last_name}`
      const userEmail = user.email

      return [
        user.sai_provider_id,
        user.sai_customer_id,
        userName,
        transaction.user_rfc,
        userEmail,
        transaction.transaction_date,
        transaction.type,
        Number(transaction.subtotal).toFixed(2),
        Number(transaction.iva).toFixed(2),
        Number(transaction.isr).toFixed(2),
        Number(transaction.retention).toFixed(2),
        Number(transaction.net_amount).toFixed(2)
      ]
    })

    formattedList.unshift([
      'id de provedor',
      'id de cliente',
      'Nombre',
      'RFC',
      'Email',
      'Fecha',
      'Tipo',
      'Subtotal',
      'iva',
      'isr',
      'retención',
      'neto'
    ])

    const csvContent = `data:text/csv;charset=utf-8,${formattedList
      .map((e) => e.join(','))
      .join('\n')}`

    const encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }

  return (
    <UsersStyled>
      {loading ? (
        <Loader text="Calculando balance y ganancias..." />
      ) : (
        <>
          <SectionTitle
            title="Resumen de usuarios"
            action="Descargar movimientos"
            handleClick={downloadMovements}
          />
          <div>
            <span>Seleccionar periodo a mostrar: </span>
            <input
              type="date"
              defaultValue={selectedPeriod}
              onChange={(event) => setSelectedPeriod(event.target.value)}
            ></input>
          </div>

          {toGenerate ? (
            <PreviewTable
              period={selectedPeriod}
              usersList={usersList}
              previewList={previewList}
              transactionsList={transactionsList}
            />
          ) : (
            <UsersTable
              period={selectedPeriod}
              usersList={usersList}
              transactionsList={transactionsList}
              reinvestBuys={reinvestBuys}
            />
          )}

          {toGenerate && (
            <div className="button-container">
              {!previewList.length ? (
                <Button
                  text={`Visualizar operaciones de fin de ${dateFormat(
                    selectedPeriod,
                    'month'
                  )}`}
                  clickHandler={() => getPreviewData()}
                  disabled={!toGenerate}
                />
              ) : (
                <Button
                  text={`Generar operaciones de fin de ${dateFormat(
                    selectedPeriod,
                    'month'
                  )}`}
                  clickHandler={() => generateOperation()}
                  disabled={!toGenerate}
                />
              )}
            </div>
          )}
        </>
      )}
    </UsersStyled>
  )
}

const UsersStyled = styled.section`
  margin: 40px 0;

  .button-container {
    text-align: center;
  }
`

export default UsersSection
