// Translate transaction concept
const translateName = (name) => {
  let translate = ''
  switch (name) {
    case 'COBRANZA_DEPOSITO':
      translate = 'Abono'
      break
    case 'COBRANZA_DEPOSITO_RECURRENTE':
      translate = 'Abono recurrente'
      break
    case 'CXP_REINVERTIR':
      translate = 'Ganancias'
      break
    case 'CXP_REINVERTIR_DE_BALANCE':
      translate = 'Ganancias por balance'
      break
    case 'CXP_RECIBIR':
      translate = 'Ganancias'
      break
    case 'CXP_RECIBIR_DE_BALANCE':
      translate = 'Ganancias por balance'
      break
    case 'CXC_VTA_TOKENS':
      translate = 'Compra de tokens'
      break
    case 'CXP_CPA_TOKENS':
      translate = 'Venta de tokens'
      break
    case 'CXP_REINVERTIR_POR_COMISION':
      translate = 'Ganancia por comisión'
      break
    case 'CXP_REINVERTIR_POR_PROMOCION':
      translate = 'Ganancia por promoción'
      break
    case 'PAGO_RETIRO':
      translate = 'Retiro transferido a tu cuenta'
      break
    case 'CXP_PROVEEDOR':
      translate = 'Abono por prestación de servicios'
      break
    case 'CXC_RESERVAS':
      translate = 'Hospedaje'
      break
    default:
      translate = name
      break
  }
  return translate
}

export default translateName
