import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useQuery, useQueryCache } from 'react-query'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
// layout
import Button from '../layout/shared/button'
import { colors } from '../layout/constants'
import SectionTitle from '../layout/shared/sectionTitle'
// services
import { createAdminUser } from '../../services/admin'

const NewUser = ({ toggleModal }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // Query
  const { isIdle, isLoading, isError, data, error, refetch, isFetching } =
    useQuery(
      'todos',
      () => {
        createAdminUser(userToken, {
          email: userEmail,
          password: `Urvita-${userType === 'SALES' ? 'ventas' : ''}20!`,
          user_type: userType
        })
      },
      {
        enabled: false
      }
    )

  const [userEmail, setUserEmail] = useState('')
  const [userType, setUserType] = useState('')

  return (
    <NewStyled>
      <SectionTitle title="Nuevo usuario" />

      <div className="form">
        <TextField
          label="Correo electrónico"
          value={userEmail}
          onChange={(event) => setUserEmail(event.target.value)}
        />

        <TextField
          onChange={(event) => setUserType(event.target.value)}
          value={userType}
          label="Tipo de usuario"
          select
        >
          <MenuItem value="SALES">Ventas</MenuItem>
          <MenuItem value="ADMIN">Admin</MenuItem>
        </TextField>

        <Button
          text={
            isIdle
              ? 'Crear Usuario'
              : isLoading
              ? 'Creando...'
              : 'Crear Usuario'
          }
          clickHandler={() => {
            toggleModal(false)
            refetch()
          }}
          disabled={!!(!userEmail || isLoading)}
        />
      </div>
    </NewStyled>
  )
}

const NewStyled = styled.section`
  .form {
    display: flex;
    flex-direction: column;
  }
`

export default NewUser
