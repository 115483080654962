import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useHistory, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
// layout
import DefaultLayout from '../layout'
import { Card } from '../../urvita-ui'
import { colors } from '../layout/constants'
// Components
import { User, ExtendedOrder } from '../orders/interfaces'
import OrderGeneralInfo from './orderGeneralInfo'
import OrdersTokenList from './orderTokensList'
// services
import { getSingleOrder, deleteOrder } from '../../services/orders'
import { getSingleUser } from '../../services/users'
import { getFundingInstructions } from '../../services/stripe'

const OrdersContainer = styled.section`
  padding: 1rem 2rem;
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;

  article:first-child {
    grid-column: 1/3;
  }

  .order-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  h1 {
    font-size: 40px;
  }

  .order-info {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .steps-heading {
    margin-bottom: 1rem;
  }
`

const SpeiContainer = styled.article`
  h2 {
    color: ${colors.primaryDark};
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    margin-bottom: 1rem;
  }

  .info-title {
    font-weight: bold;
    color: ${colors.primaryColor};
  }
`
interface BankData {
  bank_name: string
  clabe: string
  bank_code: string
}
interface ParamTypes {
  id: string
}

const Orders = (): JSX.Element => {
  const history = useHistory()
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const { id } = useParams<ParamTypes>()
  const [order, setOrder] = useState<ExtendedOrder>()
  const [user, setUser] = useState<User>()
  const [bankData, setBankData] = useState<BankData | undefined>()

  useEffect(() => {
    const ordersList = async (): Promise<void> => {
      const { data: ordersResult } = await getSingleOrder(userToken, id)
      const usersResult = await getSingleUser(userToken, ordersResult.user_id)

      setOrder(ordersResult)
      setUser(usersResult)
    }

    ordersList()
  }, [userToken, id])

  useEffect(() => {
    const getInstructions = async (): Promise<void> => {
      if (user) {
        const instructions = await getFundingInstructions(userToken, user.id)
        setBankData(instructions)
      }
    }

    getInstructions()
  }, [user, userToken])

  const removeOrder = async (): Promise<void> => {
    try {
      await deleteOrder(userToken, id)
      history.push('/ordenes')
    } catch (error) {
      alert('error al eliminar orden')
    }
  }

  return (
    <DefaultLayout sidenav>
      <OrdersContainer>
        {order && user && (
          <OrderGeneralInfo
            order={order}
            user={user}
            removeOrder={removeOrder}
          />
        )}

        <Card>
          <div>
            {order?.tokens &&
              (order?.order_type === 'compra' ||
                order?.order_type === 'venta') && (
                <>
                  <h2 className="steps-heading">Tokens de la compra</h2>
                  {user && (
                    <OrdersTokenList
                      phase_name={order?.phase_name}
                      tokens={order?.tokens}
                      user={user}
                    />
                  )}
                </>
              )}
          </div>
        </Card>

        <Card>
          {bankData && (
            <SpeiContainer>
              <h2 className="steps-heading">Pago con SPEI</h2>
              <div>
                <ul className="bank-info">
                  <li>
                    <p className="info-title">Beneficiario</p>
                    <p className="info">URVITA EVERYTHING SAPI DE CV</p>
                  </li>
                  <li>
                    <p className="info-title">RFC</p>
                    <p className="info">UEV190704AJ6</p>
                  </li>
                  <li>
                    <p className="info-title">Banco</p>
                    <p className="info">{bankData.bank_name}</p>
                  </li>
                  <li>
                    <p className="info-title">CLABE</p>
                    <p className="info">{bankData.clabe}</p>
                  </li>
                  <li>
                    <p className="info-title">Código del Banco</p>
                    <p className="info">{bankData.bank_code}</p>
                  </li>
                  <li>
                    <p className="info-title">Correo</p>
                    <p className="info">finanzas@urvita.mx</p>
                  </li>
                  {bankData.bank_name === 'BANAMEX' && (
                    <>
                      <li>
                        <p className="info-title">Código Swift</p>
                        <p className="info">BNMXMXMMXXX</p>
                      </li>
                      <li>
                        <p className="info-title">Dirección del Banco</p>
                        <p className="info">
                          ACTUARIO ROBERTO MEDELLIN 800, COL. SANTA FE, CDMX,
                          México
                        </p>
                      </li>
                    </>
                  )}
                </ul>
              </div>
            </SpeiContainer>
          )}
        </Card>
      </OrdersContainer>
    </DefaultLayout>
  )
}

export default Orders
