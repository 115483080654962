import React from 'react'
import styled from 'styled-components'
import { colors } from '../constants'

const Button = ({
  text,
  clickHandler,
  disabled = false,
  small = false,
  danger = false
}) => (
  <ButtonStyled
    type="button"
    onClick={() => clickHandler()}
    disabled={disabled}
    small={small}
    danger={danger}
  >
    <p>{text}</p>
  </ButtonStyled>
)

const ButtonStyled = styled.button`
  background-color: ${(props) =>
    props.danger ? 'red' : colors.secondaryColor};
  border-radius: 8px;
  border: 0;
  color: ${(props) => (props.danger ? colors.white : colors.darkText)};
  cursor: pointer;
  display: inline-block;
  font-size: ${(props) => (props.small ? '.8em' : '1em')};
  grid-column: 1/3;
  padding: ${(props) => (props.small ? '5px 10px' : '10px 20px')};
  transition: all 0.4s;
  width: ${(props) => (props.small ? '200px' : '300px')};

  :hover {
    background-color: ${(props) =>
      props.danger ? 'darkred' : colors.secondaryDark};
  }
  :active {
    transform: translateY(1px);
  }
  :focus {
    outline: none;
  }
  :disabled {
    background-color: ${colors.gridGray};
  }
`

export default Button
