import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { Card } from '../../../layout/card'
// layout
import { colors } from '../../../layout/constants'
// services
import { updateBuilding } from '../../../../services/buildings'
import {
  getBuildingUnits,
  newUnit,
  deleteUnit
} from '../../../../services/units'

const Form = ({ building, userToken }) => {
  const [loading, setLoading] = useState(false)
  const [unitLoading, setUnitLoading] = useState(false)
  const [units, setUnits] = useState([])
  const [activeUnit, setActiveUnit] = useState(false)
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  useEffect(() => {
    getUnits()
  }, [building])

  const getUnits = async () => {
    const buildingUnits = await getBuildingUnits(userToken, building.phase_id)
    setUnits(buildingUnits)
  }

  const onBuildingSubmit = async (data) => {
    setLoading(true)
    const resp = await updateBuilding(userToken, building.phase_id, data)
    setLoading(false)
  }

  const onUnitSubmit = async (data) => {
    setUnitLoading(true)
    const resp = await newUnit(userToken, data)
    getUnits()
    setUnitLoading(false)
  }

  const removeUnit = async (unit_id) => {
    const resp = await deleteUnit(userToken, unit_id)
    getUnits()
  }

  return (
    <Card>
      <FormContainer>
        <form onSubmit={handleSubmit(onBuildingSubmit)} id="buildingForm" />
        <form onSubmit={handleSubmit(onUnitSubmit)} id="unitForm" />
        <SectionContainer>
          <section>
            <h2 className="title">Sobre el edificio</h2>
            <TextField
              form="buildingForm"
              fullWidth
              multiline
              label="Descripción"
              type="text"
              variant="outlined"
              defaultValue={building.description}
              margin="normal"
              {...register('description')}
            />
          </section>
          <section>
            <h2 className="title">Datos clave</h2>
            <TextField
              form="buildingForm"
              id="units"
              fullWidth
              label="Unidades"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.units}
              {...register('units')}
            />
            <TextField
              form="buildingForm"
              multiline
              fullWidth
              label="Niveles"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.floors}
              {...register('floors')}
            />
            <TextField
              form="buildingForm"
              multiline
              fullWidth
              label="Metros Rentables"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.rent_meters}
              {...register('rent_meters')}
            />
            <TextField
              form="buildingForm"
              multiline
              fullWidth
              label="Premios"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.awards}
              {...register('awards')}
            />
          </section>
        </SectionContainer>

        <SectionContainer>
          <section>
            <h2 className="title">Sobre la ubicación</h2>
            <TextField
              form="buildingForm"
              fullWidth
              multiline
              label="Descripción"
              type="text"
              variant="outlined"
              defaultValue={building.neighborhood_description}
              margin="normal"
              {...register('neighborhood_description')}
            />
          </section>
          <section>
            <h2 className="title">Referencias</h2>
            <TextField
              form="buildingForm"
              id="neighborhood"
              fullWidth
              label="Vecindario"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.neighborhood}
              {...register('neighborhood')}
            />
            <TextField
              form="buildingForm"
              id="indications"
              fullWidth
              label="Indicaciones"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.address_indications}
              {...register('address_indications')}
            />
          </section>
          <section className="address">
            <TextField
              form="buildingForm"
              className="street"
              label="Calle"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.address_street}
              {...register('address_street')}
            />
            <TextField
              form="buildingForm"
              label="Número"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.address_street_number}
              {...register('address_street_number')}
            />
            <TextField
              form="buildingForm"
              label="Colonia, Municipio, Del. o Alc."
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.address_suburb}
              {...register('address_suburb')}
            />
            <TextField
              form="buildingForm"
              label="Ciudad"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.address_city}
              {...register('address_city')}
            />
            <TextField
              form="buildingForm"
              label="Estado"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.address_state}
              {...register('address_state')}
            />
            <TextField
              form="buildingForm"
              label="Pais"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.address_country}
              {...register('address_country')}
            />
            <TextField
              form="buildingForm"
              label="Código Postal"
              type="text"
              variant="outlined"
              margin="normal"
              defaultValue={building.zip_code}
              {...register('zip_code')}
            />
          </section>
        </SectionContainer>

        <h2 className="title">Unidades</h2>
        <SectionContainer>
          <ul className="unit-list">
            {units &&
              units.map((unit) => (
                <li key={unit.id} className="unit-item">
                  <h3>
                    {unit.unit_id} - {unit.type}
                    <span
                      className="delete-unit"
                      onClick={() => removeUnit(unit.unit_id)}
                    >
                      Borrar
                    </span>
                  </h3>
                  <p>{unit.description}</p>
                </li>
              ))}

            {activeUnit && (
              <li>
                <TextField
                  form="unitForm"
                  label="Id"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  {...register('unit_id')}
                />
                <TextField
                  form="unitForm"
                  label="Tipo"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  {...register('type')}
                />
                <TextField
                  form="unitForm"
                  fullWidth
                  multiline
                  label="Descripción"
                  type="text"
                  variant="outlined"
                  margin="normal"
                  {...register('description')}
                />
                <input
                  form="unitForm"
                  className="button"
                  type="submit"
                  value={unitLoading ? 'Agregando...' : 'Agregar'}
                />
              </li>
            )}
          </ul>

          {!activeUnit && (
            <article className="new-unit" onClick={() => setActiveUnit(true)}>
              Agregar unidad
            </article>
          )}
        </SectionContainer>

        <input
          form="buildingForm"
          className="button"
          type="submit"
          value={loading ? 'Guardando...' : 'Guardar'}
        />
      </FormContainer>
    </Card>
  )
}

const FormContainer = styled.section`
  display: grid;
  gap: 2rem;

  .button {
    border-radius: 8px;
    border: none;
    background-color: ${colors.secondaryColor};
    cursor: pointer;
    padding: 1rem 0.5rem;

    :hover {
      background-color: ${colors.secondaryDark};
    }
  }
`

const SectionContainer = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;

  .address {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;
  }

  .street {
    grid-column: 1/3;
  }

  .unit-list {
    list-style: none;
    padding: 0;
  }

  .new-unit {
    align-items: center;
    background-color: ${colors.secondaryColor};
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;

    &:active {
      background-color: ${colors.secondaryDark};
    }
  }

  .unit-item {
    margin: 1rem 0;
  }

  .delete-unit {
    cursor: pointer;
    color: ${colors.warningColor};
    margin: 0 1rem;

    &:hover {
      filter: brightness(1.4);
    }
  }
`

export default Form
