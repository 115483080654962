import moment from 'moment'
// Get month and year date format
const dateFormat = (period, format) => {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre'
  ]

  const shortMonths = [
    'Ene',
    'Feb',
    'Mar',
    'Abr',
    'May',
    'Jun',
    'Jul',
    'Ago',
    'Sep',
    'Oct',
    'Nov',
    'Dic'
  ]

  const dayNumber = moment(period).format('DD')
  const monthNumber = moment(period).format('M')
  const yearNumber = moment(period).format('YYYY')
  const shortYear = moment(period).format('YY')

  if (format === 'month') {
    return months[monthNumber - 1]
  }
  if (format === 'full') {
    return `${dayNumber}/${months[monthNumber - 1]}/${yearNumber}`
  }
  if (format === 'short') {
    return `${dayNumber}-${shortMonths[monthNumber - 1]}-${shortYear}`
  }
  return `${months[monthNumber - 1]} ${yearNumber}`
}

export default dateFormat
