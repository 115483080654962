import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { UsersStyles } from '../styles'
// helpers
import { getSingleUser } from '../../../services/users'
import { getReport } from '../../../services/reports'
import { getUserTransactions } from '../../../services/transactions'
import { getUserTokens } from '../../../services/tokens'
// views
import UserInfo from './userInfo'
import UserTransactions from './userTransactions'
import UserActions from './userActions'
// layout
import Loader from '../../layout/shared/loader'

const UserDetails = ({ highlightId, setHighlightId, getUsersList }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // State
  const [loading, setLoading] = useState(false)

  // User data
  const [userInfo, setUserInfo] = useState({})
  const [userReport, setUserReport] = useState({})
  const [userTransactions, setUserTransactions] = useState([])
  const [userTokens, setUserTokens] = useState([])

  useEffect(() => {
    if (highlightId) {
      setLoading(true)
      getUserInfo(highlightId)
    }
  }, [highlightId])

  const getUserInfo = async (id) => {
    const user = await getSingleUser(userToken, id)
    const report = await getReport(userToken, id)
    const transactions = await getUserTransactions(userToken, user.rfc)
    const tokens = await getUserTokens(userToken, user.rfc)
    setLoading(false)
    setUserInfo(user)
    setUserReport(report)
    setUserTransactions(transactions)
    setUserTokens(tokens)
  }

  const handleUpdate = () => {
    getUsersList(getUsersList)
    getUserInfo(highlightId)
    setLoading(true)
  }

  return (
    <UsersStyles className="users-info">
      {loading ? (
        <Loader text="Cargando la información de usuario" />
      ) : (
        <>
          <UserInfo
            userInfo={userInfo}
            loading={loading}
            userReport={userReport}
          />

          <UserActions
            userId={highlightId}
            userRfc={userInfo.rfc}
            userName={userInfo.name}
            loading={loading}
            setLoading={setLoading}
            getUsersList={getUsersList}
            setHighlightId={setHighlightId}
          />

          <section className="user-tokens">
            <header className="header">
              <div>
                <h1>
                  Tokens <span className="detail">{userTokens.length}</span>
                </h1>
              </div>
            </header>
            <ul>
              {userTokens && !loading ? (
                userTokens.map((token) => (
                  <li key={token.token_id} className="token-chip">
                    {token.token_id}
                  </li>
                ))
              ) : (
                <li>Cargando Tokens...</li>
              )}
            </ul>
          </section>

          <UserTransactions
            userTransactions={userTransactions}
            highlightId={highlightId}
            highlightRfc={userInfo.rfc}
            handleUpdate={handleUpdate}
          />
        </>
      )}
    </UsersStyles>
  )
}

export default UserDetails
