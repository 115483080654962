import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import NumberFormat from 'react-number-format'
// componments
import BuyChart from './buyChart'
// helpers
import dateFormat from '../../helpers/dateFormat'
// layout
import { colors } from '../../layout/constants'
// services
import { getAllTokenTrades } from '../../../services/tokenTrades'
import { getUsers } from '../../../services/users'

const TokenTransactionsView = () => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // state
  const [transactions, setTransactions] = useState([])

  useEffect(() => {
    askForTransaction()
  }, [])

  const askForTransaction = async () => {
    try {
      const reponse = await getAllTokenTrades(userToken)
      sortTransactions(reponse, 'transaction_date')
    } catch (error) {
      console.error(error)
    }
  }

  const sortTransactions = (transactions, key) => {
    const sortedList = transactions.sort((a, b) => {
      if (a[key] > b[key]) {
        return -1
      }

      if (a[key] < b[key]) {
        return 1
      }

      return 0
    })
    setTransactions(sortedList)
  }

  const downloadCsv = async () => {
    const users = await getUsers(userToken)
    const buyList = transactions.map((transaction) => {
      const userInfo = users.data.find(
        (user) => user.rfc === transaction.buyer_rfc
      )

      const transactionRow = [
        `${userInfo.name} ${userInfo.last_name}`,
        userInfo.rfc,
        dateFormat(transaction.transaction_date, 'full'),
        Number(transaction.transaction_amount).toFixed(2),
        transaction.token_id.substring(0, 3),
        transaction.token_id,
        transaction.transaction_channel
      ]
      return transactionRow
    })

    buyList.unshift([
      'Nombre',
      'RFC',
      'Fecha',
      'Importe',
      'Edificio',
      'Token',
      'Canal'
    ])

    const csvContent = `data:text/csv;charset=utf-8,${buyList
      .map((e) => e.join(','))
      .join('\n')}`

    const encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }

  return (
    <TransactionsContainer>
      <UrvitaTable>
        <BuyChart transactions={transactions} />
        <TableTitle>
          <div>
            Compra de Tokens
            <span className="count"> ({transactions.length})</span>
          </div>
          <button className="table-action " onClick={() => downloadCsv()}>
            Descargar
          </button>
        </TableTitle>
        <HeaderRow>
          <p>Inversionista</p>
          <p
            className="sort-button"
            onClick={() => sortTransactions(transactions, 'transaction_date')}
          >
            Fecha{' '}
            <span role="img" aria-label="down">
              ⬇️
            </span>
          </p>
          <p>Importe</p>
          <p>Proyecto</p>
          <p>Vendedor</p>
          <p
            className="sort-button"
            onClick={() => sortTransactions(transactions, 'token_id')}
          >
            Token ID{' '}
            <span role="img" aria-label="down">
              ⬇️
            </span>
          </p>
          <p>Canal</p>
        </HeaderRow>
        {transactions.map((transaction, index) => (
          <ItemRow key={index}>
            <span>{transaction.buyer_rfc}</span>
            <span>{dateFormat(transaction.transaction_date, 'full')}</span>
            <NumberFormat
              className="number"
              value={Number(transaction.transaction_amount).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
            <span>{transaction.token_id.substring(0, 3)}</span>
            <span>{transaction.seller_rfc}</span>
            <span>{transaction.token_id}</span>
            <span>{transaction.transaction_channel}</span>
          </ItemRow>
        ))}
      </UrvitaTable>
    </TransactionsContainer>
  )
}

const TransactionsContainer = styled.section`
  .table-action {
    background-color: ${colors.secondaryColor};
    border: none;
    border-radius: 8px;
    color: ${colors.darkText};
    cursor: pointer;
    padding: 0.5rem 1rem;

    :hover {
      background-color: ${colors.highlightColor};
    }
  }
`

const UrvitaTable = styled.ul`
  border-radius: 8px;
  list-style: none;
  margin: 1rem 0;
  overflow: hidden;
`

const TableTitle = styled.h2`
  background-color: ${colors.darkBackground};
  color: ${colors.white};
  display: flex;
  font-size: 1.2rem;
  font-weight: 200;
  padding: 1rem 0.5rem;
  justify-content: space-between;

  .count {
    font-size: 1.5rem;
    font-weight: 400;
  }
`

const HeaderRow = styled.li`
  align-items: center;
  padding: 5px 0.5rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 2rem;

  background-color: ${colors.gridGray};
  color: ${colors.white};
  padding: 0.5rem;

  .sort-button {
    cursor: pointer;
  }

  p {
    font-size: 1.2rem;
    text-align: center;
  }
`

const ItemRow = styled.li`
  align-items: center;
  padding: 10px 0.5rem;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-column-gap: 2rem;
  text-align: center;

  border-bottom: 1px solid black;
  :nth-child(odd) {
    background-color: ${colors.gridSecondary};
  }

  &.modal-row {
    grid-template-columns: 200px 1fr;
  }
`

export default TokenTransactionsView
