import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// layout
import { Card, colors } from '../../urvita-ui'
import { User } from '../orders/interfaces'

const PickerContainer = styled.section`
  .user-search-input {
    background-color: white;
    border: none;
    outline: none;
    font-size: 1rem;
    width: 300px;
  }

  .users-search-results {
    margin: 0.25rem 0;
  }

  .user-search-option {
    background-color: ${colors.white};
    display: block;
    cursor: pointer;
    padding: 0.5rem;
    border: none;
    text-align: left;
    width: 100%;

    &:hover {
      background-color: ${colors.gridSecondary};
    }
  }

  .error-text {
    color: ${colors.warningColor};
    margin: 0.25rem 0 0 0.5rem;
  }
`

type pickerData = {
  orderUser: number | undefined
  userList: User[] | undefined
  userName: string | undefined
  setUserName: (userName: string | undefined) => void
  setOrderUser: (orderUser: number | undefined) => void
  setUsersRfc: (userRfc: string | undefined) => void
}

const UserPicker = ({
  setUsersRfc,
  setUserName,
  userName,
  orderUser,
  userList,
  setOrderUser
}: pickerData): JSX.Element => {
  const [userSearch, setUserSearch] = useState<string>('')
  const [userEmail, setUserEmail] = useState<string | undefined>()
  const [error, setError] = useState<boolean>(false)

  useEffect(() => {
    const showUserName = (): void => {
      const selectedUser = userList?.find((user: User) => user.id === orderUser)
      setError(false)
      if (selectedUser && !selectedUser.rfc) {
        setError(true)
      }
      setUserName(
        selectedUser
          ? `${selectedUser.name} ${selectedUser.last_name}`
          : undefined
      )
      setUserEmail(selectedUser?.email)
    }
    showUserName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderUser])

  const showQueryUsers = (): JSX.Element => {
    const completeName = (user: User): string =>
      `${user.name?.toLocaleLowerCase()} ${user.last_name?.toLocaleLowerCase()}`

    const matchUsers = userList?.filter(
      (user) =>
        completeName(user).includes(userSearch) ||
        user.email.includes(userSearch)
    )

    return userSearch ? (
      <div className="users-search-results">
        <Card noSidePadding>
          {matchUsers?.map((user) => (
            <button
              className="user-search-option"
              key={user.id}
              onClick={() => {
                setOrderUser(user.id)
                setUsersRfc(user.rfc)
              }}
              onKeyDown={() => {
                setOrderUser(user.id)
                setUsersRfc(user.rfc)
              }}
              type="button"
              tabIndex={0}
            >
              {user.name} {user.last_name} - {user.email}
            </button>
          ))}
        </Card>
      </div>
    ) : (
      <section />
    )
  }

  return (
    <PickerContainer>
      <h3 className="sub-title">Selecciona un usuario para la orden</h3>
      {orderUser ? (
        <Card interactive onClick={() => setOrderUser(undefined)}>
          <p>
            {userName} - {userEmail}
          </p>
        </Card>
      ) : (
        <div>
          <Card className="input-container">
            <input
              className="user-search-input"
              onChange={(event) =>
                setUserSearch(event.target.value.toLocaleLowerCase())
              }
              placeholder="Buscar..."
            />
          </Card>

          {userList && showQueryUsers()}
        </div>
      )}

      {error && (
        <p className="error-text">
          Completa los datos del usuario (RFC) para poder generar una orden a su
          nombre
        </p>
      )}
    </PickerContainer>
  )
}

export default UserPicker
