import React, { useEffect, useState } from 'react'
import NumberFormat from 'react-number-format'
import moment from 'moment'
import { ValueRecordStyles } from '../styles'
import { Card } from '../../layout/card'
import styled from 'styled-components'
import { colors } from '../../../urvita-ui/constants'
// Services
import {
  getBuildingRecords,
  deleteRecord,
  fillRecords
} from '../../../services/valueRecords'

const StyledButton = styled.button`
  background-color: ${colors.secondaryColor};
  border: none;
  color: black;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 16px;
  margin: 4px 2px 4px auto;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: ${colors.secondaryDark};
  }
`;

const ValueRecord = ({ activeBuilding, userToken, tokenTrades }) => {
  const [valueRecord, setValueRecord] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (valueRecord.length <= 0) {
      getRecords()
    }
  }, [valueRecord])

  const tokensBuyByMonth = (period) => {
    const monthTransactions = tokenTrades.filter((trade) => {
      const tradeDate = moment(trade.transaction_date).format('YYYY-MM')
      const periodDate = moment(period).format('YYYY-MM')
      if (tradeDate === periodDate) {
        return true
      }
    })

    return monthTransactions.length
  }

  const getRecords = async () => {
    const records = await getBuildingRecords(userToken, activeBuilding.phase_id)
    if (records.length) {
      setValueRecord(sortValueList(records))
    }
  }

  const sortValueList = (buildingRecords) =>
    buildingRecords.sort((a, b) => {
      if (a.period > b.period) {
        return 1
      }
      if (a.period < b.period) {
        return -1
      }
      return 0
    })

  const deleteThisRecord = (id) => {
    deleteRecord(userToken, id).then(() => setValueRecord([]))
  }

  const fillOperatingRecords = () => {
    setLoading(true)
    fillRecords(activeBuilding.phase_id).then((records) => {
      setValueRecord(records)
      setLoading(false)
    })
  }

  const downloadCSV = () => {
    const headers = ['Period', 'NOI A', 'NOI B', 'Value A', 'Period Scale', 'Tokens Buy'];
    const sortedValueRecord = [...valueRecord].sort((a, b) => new Date(b.period) - new Date(a.period));
    const csvContent = [
      headers.join(','),
      ...sortedValueRecord.map(record => [
        record.period,
        record.noi_a,
        record.noi_b,
        record.value_a,
        record.period_scale,
        tokensBuyByMonth(record.period)
      ].join(','))
    ].join('\n');

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', activeBuilding.name + '.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Card>
      <ValueRecordStyles>
        <h1>Historial</h1>
        <StyledButton onClick={downloadCSV}>
          Descargar CSV
        </StyledButton>
        <li key="title" className="record-item title-item">
          <span>Periodo</span>
          <span>NOI A</span>
          <span>NOI B</span>
          <span>Valor de Token</span>
          <span>Plusvalía anualizada</span>
          <span>Tokens vendidos</span>
        </li>
        {valueRecord.length > 0 ? (
          <>
            <ul className="list-container">
              {valueRecord.map((record) => (
                <li key={record.id} className="record-item">
                  <span>{moment(record.period).format('MMM YYYY')}</span>
                  <span>
                    <NumberFormat
                      value={Number(record.noi_a).toFixed(2)}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </span>
                  <span>
                    <NumberFormat
                      value={Number(record.noi_b).toFixed(2)}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </span>
                  <span>
                    <NumberFormat
                      value={Number(record.value_a).toFixed(2)}
                      displayType="text"
                      thousandSeparator
                      prefix="$"
                    />
                  </span>
                  {/* <span>
                    <NumberFormat
                      value={Number(record.value_b).toFixed(2)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </span> */}
                  <span>
                    <NumberFormat
                      value={Number(record.period_scale * 100).toFixed(2)}
                      displayType="text"
                      thousandSeparator
                      suffix="%"
                    />
                  </span>
                  <span>
                    <NumberFormat
                      value={tokensBuyByMonth(record.period)}
                      displayType="text"
                      thousandSeparator
                    />
                  </span>
                  <span>
                    <button
                      className="delete-button"
                      onClick={() => deleteThisRecord(record.id)}
                    >
                      X
                    </button>
                  </span>
                </li>
              ))}
            </ul>

          </>
        ) : (
          <h2 className="loading-title">{loading ? 'Cargando...' : ''}</h2>
        )}
      </ValueRecordStyles>
    </Card>
  )
}

export default ValueRecord
