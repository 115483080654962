import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import currency from 'currency.js'
// layout
import { colors } from '../../layout/constants'

const StyledTable = styled.section`
  height: 400px;
  overflow-y: scroll;
  text-align: center;

  h3 {
    margin-bottom: 1rem;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    border-bottom: 1px solid ${colors.gridGray};
    padding: 1rem 0;
  }

  td,
  th {
    text-align: center;
    padding: 0.25rem;

    :nth-child(2) {
      text-align: left;
    }

    :last-child {
      font-family: 'Roboto Mono', monospace;
      text-align: right;
      font-size: 0.8rem;
    }
  }
`

export const TransactionsTable = ({ transactions, users, setAverageValue }) => {
  const list = aggregateTransactions(transactions, users, setAverageValue)

  return (
    <StyledTable>
      <h3>Dueños de tokens</h3>
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Nombre</th>
            <th>Tokens</th>
            <th>Valor</th>
          </tr>
        </thead>
        <tbody>
          {list.map((transaction, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{transaction.name}</td>
              <td>{transaction.count}</td>
              <td>
                {currency(transaction?.value, {
                  precision: 0
                }).format()}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </StyledTable>
  )
}

// helper functions
const aggregateTransactions = (transactions, users, setAverageValue) => {
  const reducedTransactions = transactions.reduce((acc, transaction) => {
    if (acc[transaction.buyer_rfc]) {
      acc[transaction.buyer_rfc].value += Number(
        transaction.token.current_value
      )
      acc[transaction.buyer_rfc].count += 1
    } else {
      acc[transaction.buyer_rfc] = {
        value: Number(transaction.token.current_value),
        count: 1
      }
    }
    return acc
  }, {})

  const aggregatedTransactions = []
  for (let key in reducedTransactions) {
    aggregatedTransactions.push({
      rfc: key,
      value: Number(reducedTransactions[key].value.toFixed(2)),
      count: Number(reducedTransactions[key].count)
    })
  }

  aggregatedTransactions.sort((a, b) => b.value - a.value)

  const withUsers = aggregatedTransactions.map((transaction) => {
    const user = users?.find((user) => user.rfc === transaction.rfc)
    return {
      ...transaction,
      name: user ? `${user.name} ${user.last_name}` : 'Anónimo'
    }
  })

  const averageValue =
    withUsers.reduce((acc, user) => acc + user.value, 0) / withUsers.length

  setAverageValue(averageValue.toFixed(2))

  return withUsers
}
