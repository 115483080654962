import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// helpers
import { createUser } from '../../services/users'
// layout
import Button from '../layout/shared/button'
import { colors } from '../layout/constants'
import SectionTitle from '../layout/shared/sectionTitle'

const NewUserView = ({ toggleModal, updateUsers }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)

  // Inputs
  const [name, setName] = useState('')
  const [lastName, setLastName] = useState('')
  const [rfc, setRfc] = useState('')
  const [mail, setMail] = useState('')
  const [taxRegime, setTaxRegime] = useState('P_FISICA')

  const [loading, setLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [error, setError] = useState('')
  const [validData, setValidData] = useState(false)

  useEffect(() => {
    if (name && lastName && rfc && mail && taxRegime) {
      setValidData(true)
      return
    }
    setValidData(false)
  }, [name, lastName, rfc, mail, taxRegime])

  const sendUserData = async () => {
    const userData = {
      email: mail,
      password: 'urvita2020',
      name,
      last_name: lastName,
      rfc,
      tax_regime: taxRegime,
      user_type: 'investor',
      balance: 0,
      last_rent_setting: 'REINVEST',
      rent_setting: 'REINVEST',
      reinvest_setting: 'SAME_BUILDING',
      to_change_password: true
    }

    try {
      setLoading(true)
      const newUser = await createUser(userToken, userData)
      setLoading(false)

      if (!newUser.error) {
        setHasError(false)
        toggleModal(false)
        updateUsers()
      }

      setHasError(true)
      setError(newUser.error.detail)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <UserFormStyled>
      <SectionTitle title="Nuevo usuario" />

      <form>
        <label>Nombre</label>
        <input
          type="text"
          name="name"
          onChange={(event) => setName(event.target.value)}
          value={name}
        />

        <label>Apellido</label>
        <input
          type="text"
          name="lastName"
          onChange={(event) => setLastName(event.target.value)}
          value={lastName}
        />

        <label>RFC</label>
        <input
          type="text"
          name="rfc"
          onChange={(event) => setRfc(event.target.value)}
          value={rfc}
        />

        <label>Mail</label>
        <input
          type="text"
          name="mail"
          onChange={(event) => setMail(event.target.value)}
          value={mail}
        />

        <label>Regimen Fiscal</label>
        <select
          name="tax_regime"
          onChange={(event) => setTaxRegime(event.target.value)}
          value={taxRegime}
        >
          <option value="P_FISICA">Persona Física</option>
          <option value="P_MORAL">Persona Moral</option>
        </select>

        <Button
          text={loading ? 'Creando...' : 'Crear Usuario'}
          clickHandler={() => sendUserData()}
          disabled={!!(!validData || loading)}
        />

        {hasError && <span className="error-message">{error}</span>}
      </form>
    </UserFormStyled>
  )
}

const UserFormStyled = styled.section`
  form {
    display: flex;
    flex-direction: column;
  }

  input,
  select {
    margin: 5px 0 15px 0;
  }

  .error-message {
    color: ${colors.highlightColor};
  }
`

export default NewUserView
