import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Link } from 'react-router-dom'
import currency from 'currency.js'
import moment from 'moment'
// components
import LastTransaction from './lastTransaction'
// layout
import { colors } from '../layout/constants'
import Button from '../layout/shared/button'
import { NewDataSubscription, Plan } from '../../interfaces'

const StyledTitle = styled.h3`
  color: ${colors.darkText};
  :hover {
    color: orange;
  }
`

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
`

interface props {
  plan: Plan
  index: number
  openModal: (type: string) => void
  setActivePlan: (subscription: Plan) => void
  setEditPlan: (value: boolean) => void
  setNewData: (value: NewDataSubscription) => void
}

const PlanRow = ({
  plan,
  index,
  openModal,
  setActivePlan,
  setEditPlan,
  setNewData
}: props): JSX.Element => {
  const [lastPay, setLastPay] = useState<Date>()
  const [payed, setPayed] = useState<boolean>()

  useEffect(() => {
    if (lastPay) {
      const currentMonth = Number(moment().format('MM'))
      const lastPayMonth = Number(moment(lastPay).format('MM'))

      if (currentMonth === lastPayMonth) {
        setPayed(true)
      } else {
        setPayed(false)
      }
    }
  }, [lastPay])

  return (
    <TableRow
      key={plan.id}
      sx={{
        '&:last-child td, &:last-child th': { border: 0 },
        backgroundColor: payed ? '#e8f7eb' : 'transparent'
      }}
    >
      <TableCell component="th" scope="row">
        {index + 1}
      </TableCell>
      <TableCell align="left">
        <Link to={`/usuarios/${plan.user_id}`}>
          <StyledTitle>
            {plan?.user.name} {plan?.user.last_name}
          </StyledTitle>
        </Link>
      </TableCell>
      <TableCell align="right">
        {currency(plan.monthly_amount).format()}
      </TableCell>
      <TableCell align="right">
        {plan.stripe_subscription_id ? '2/12' : '-'}{' '}
      </TableCell>
      <TableCell align="center">
        {moment(plan.charge_date).format('DD MMMM')}
      </TableCell>
      <TableCell align="center">Edificios</TableCell>
      <TableCell align="center">
        <LastTransaction
          userId={plan.user_id}
          lastPay={lastPay}
          setLastPay={setLastPay}
        />
      </TableCell>
      <TableCell align="center">
        {plan.stripe_subscription_id ? (
          <a
            href={`https://dashboard.stripe.com/subscriptions/${plan.stripe_subscription_id}`}
            target="_blank"
            rel="noreferrer"
          >
            Ver en Stripe
          </a>
        ) : (
          <Button
            text="Realizar cobro"
            clickHandler={() => {
              setActivePlan(plan)
              setEditPlan(false)
              openModal('charge')
            }}
          />
        )}
      </TableCell>
      <TableCell align="center">
        <StyledButton
          type="button"
          onClick={() => {
            setEditPlan(true)
            setNewData({
              newAmount: Number(plan.monthly_amount),
              newChargeDate: Number(plan.charge_date),
              newStatus: true
            })
            setActivePlan(plan)
            openModal('edit')
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1.5em"
            height="1.5em"
            viewBox="0 0 32 32"
          >
            <path
              fill="currentColor"
              d="M21.65 3.434a4.889 4.889 0 1 1 6.915 6.914l-.902.901l-6.914-6.914zM19.335 5.75L4.357 20.73a3.7 3.7 0 0 0-1.002 1.84l-1.333 6.22a1 1 0 0 0 1.188 1.188l6.22-1.333a3.7 3.7 0 0 0 1.84-1.002l14.98-14.98z"
            />
          </svg>
        </StyledButton>
      </TableCell>
    </TableRow>
  )
}

export default PlanRow
