async function getTransactions(userToken, page, sortedBy, order) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions?page=${page}&sortedBy=${sortedBy}&order=${order}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

async function getAllTransactions(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

async function getUserTransactions(userToken, user_rfc) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/user/${user_rfc}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}
async function getTypeTransactions(userToken, type, page, sortedBy, order) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/type/${type}?page=${page}&sortedBy=${sortedBy}&order=${order}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

async function getPeriodTransactions(
  userToken,
  period,
  page,
  sortedBy,
  order,
  limit
) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/period/${period}?page=${page}&sortedBy=${sortedBy}&order=${order}&limit=${limit}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

async function newTransaction(userToken, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions`,
    {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function updateTransaction(userToken, transaction_id, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/${transaction_id}`,
    {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function deleteTransaction(userToken, transaction_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/transactions/${transaction_id}`,
    {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

export {
  getTransactions,
  getAllTransactions,
  getUserTransactions,
  getTypeTransactions,
  getPeriodTransactions,
  newTransaction,
  updateTransaction,
  deleteTransaction
}
