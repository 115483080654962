import React from 'react'
import styled from 'styled-components'
import { colors } from '../constants'

const HeaderContainer = styled.header`
  position: fixed;
  border-bottom: 1px solid gray;
  background-color: ${colors.lightBackground};
  padding: 1rem 2rem;
  width: 100%;
  z-index: 2;
`

const InputContainer = styled.div`
  background-color: lightgray;
  border-radius: 4px;
  display: inline;
  padding: 4px;

  input {
    background-color: lightgray;
    border: none;

    :focus {
      outline: none;
    }
  }
`

const SearchHeader = ({
  setSearchFilter
}: {
  setSearchFilter: React.Dispatch<React.SetStateAction<string>>
}): JSX.Element => (
  <HeaderContainer>
    <InputContainer>
      <input
        type="text"
        placeholder="Buscar..."
        onChange={(event) => setSearchFilter(event.target.value)}
      />
    </InputContainer>
  </HeaderContainer>
)

export default SearchHeader
