async function login(body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/admin_users/login`,
    {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => console.error('error', err))
  const resp = await response.json()
  return resp.error ? resp : resp.data
}

async function verifyAdminToken(token) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/admin_users/verify/${token}`,
    {
      method: 'GET',
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp.data
}

async function getAdminUsers(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/admin_users`,
    {
      method: 'GET',
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp.data
}

async function getSingleAdminUser(userToken, user_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/admin_users/${user_id}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp.data
}

async function createAdminUser(userToken, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/admin_users/signup`,
    {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => err)
  const resp = await response.json()
  return resp
}

async function deleteAdminUser(userToken, id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/admin_users/${id}`,
    {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

export {
  login,
  getAdminUsers,
  getSingleAdminUser,
  createAdminUser,
  deleteAdminUser,
  verifyAdminToken
}
