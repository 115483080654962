import React, { useState } from 'react'
import styled from 'styled-components'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@material-ui/core'
import currency from 'currency.js'
import { Link } from 'react-router-dom'
// assets
import downArrow from '../../assets/icons/downArrow.png'
import upArrow from '../../assets/icons/upArrow.png'
import { layoutValues } from '../layout/constants'
// helpers
import translateTransaction from '../helpers/translateTransactions'
import dateFormat from '../helpers/dateFormat'

const TransactionsContainer = styled.section`
  width: 100%;

  .trans-table {
    text-align: center;
  }

  .title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 2rem;
  }

  .sorting-div {
    display: flex;
    flex-direction: row;
    padding: 0.3rem 0.7rem;
    justify-content: center;
  }

  .sort-select {
    padding: 1rem;
    margin: 0.5rem;
  }

  .sort-btn {
    border: none;
    background-color: transparent;
  }

  .sorting-arrow {
    height: 1rem;
    width: 1rem;
    padding-right: 0.3rem;
    padding-top: 0.8rem;
    cursor: pointer;
  }

  .user-link,
  .user-link:link,
  .user-link:visited {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
  }

  .type-label {
    border-radius: ${layoutValues.cardRadius};
    display: inline-block;
    padding: 8px;
    text-transform: capitalize;
    text-align: center;
    width: 100px;
    position: relative;
    cursor: pointer;

    &.PENDING {
      background-color: orange;
    }

    &.PAID {
      background-color: lightgreen;
    }

    &.DECLINED {
      color: white;
      background-color: darkred;
    }

    &.CANCEL {
      color: white;
      background-color: darkred;
    }
  }

  .status-dropdown {
    background-color: lightgray;
    position: absolute;
    top: 45px;
    left: 0;
    right: 0;
    display: none;
    border-radius: 8px;

    &.show {
      display: block;
    }

    ul {
      list-style: none;
    }

    li {
      padding: 2px 5px;

      &:hover {
        background-color: gray;
      }
    }
  }
`

export const UrvitaTable = ({ transactions }: any): JSX.Element => {
  const [sortedBy, setSortedBy] = useState('transaction_date')
  const [descendingOrder, setDescendingOrder] = useState(true)

  const handleOrderChange = (sorted: string): void => {
    setSortedBy(sorted)
    setDescendingOrder(!descendingOrder)
  }

  return (
    <TransactionsContainer>
      <TableContainer component={Paper} className="table">
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell align="right">ID de transacción</TableCell>
              <TableCell align="right">
                <button
                  className="sort-btn"
                  type="button"
                  onClick={() => handleOrderChange('transaction_date')}
                >
                  {descendingOrder ? (
                    <img src={downArrow} alt="sort" className="sorting-arrow" />
                  ) : (
                    <img src={upArrow} alt="sort" className="sorting-arrow" />
                  )}
                </button>
                Fecha
              </TableCell>
              <TableCell>Nombre Usuario</TableCell>
              <TableCell>Tipo</TableCell>

              <TableCell align="right">
                <button
                  className="sort-btn"
                  type="button"
                  onClick={() => handleOrderChange('subtotal')}
                >
                  {descendingOrder ? (
                    <img src={downArrow} alt="sort" className="sorting-arrow" />
                  ) : (
                    <img src={upArrow} alt="sort" className="sorting-arrow" />
                  )}
                </button>
                Subtotal
              </TableCell>
              <TableCell align="right">IVA</TableCell>
              <TableCell align="right">ISR</TableCell>
              <TableCell align="right">Retención</TableCell>
              <TableCell align="right">Neto</TableCell>
              <TableCell align="right">Método de Pago</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="t-body">
            {transactions &&
              transactions.map((transaction: any) => (
                <TableRow key={transaction.id}>
                  <TableCell align="center">{transaction.id}</TableCell>
                  <TableCell align="right">
                    {dateFormat(transaction.transaction_date, 'short')}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Link
                      className="user-link"
                      to={`/usuarios/${transaction.user.id}`}
                    >
                      {`${transaction.user.name} 
                        ${transaction.user.last_name}`}
                    </Link>
                  </TableCell>
                  <TableCell align="left">
                    {translateTransaction(transaction.type)}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.subtotal)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.iva)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.isr)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.retention)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {currency(Number(transaction.net_amount)).format()}
                  </TableCell>
                  <TableCell align="right">
                    {transaction.zigu_id && `ZIGU - ${transaction.zigu_id}`}
                    {transaction.stripe_id && (
                      <a
                        href={`https://dashboard.stripe.com/payments/${transaction.stripe_id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Stripe
                      </a>
                    )}
                    {(transaction.type === 'COBRANZA_DEPOSITO' ||
                      transaction.type === 'COBRANZA_DEPOSITO_RECURRENTE') &&
                    !transaction.zigu_id &&
                    !transaction.stripe_id
                      ? 'SEPI'
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </TransactionsContainer>
  )
}
