import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import currency from 'currency.js'
import { Link } from 'react-router-dom'
import { chargeSubscription } from '../../services/zigu'
// layout
import Button from '../layout/shared/button'

interface props {
  activeSubscription: activeSubscription
}

interface activeSubscription {
  id: number
  user_id: number
  user: {
    name: string
    last_name: string
  }
  subscription_amount: number
  subscription_charge_date: number
  subscription_active: boolean
  subscription_start_date: string
}

const StyledModal = styled.section`
  .title {
    text-align: center;
    margin-bottom: 2rem;
  }

  .action {
    text-align: center;
    max-width: 400px;
    margin: 2rem auto;
  }

  .error {
    color: red;
  }

  .success {
    color: green;

    a {
      color: green;
      font-weight: bold;
      :hover {
        text-decoration: underline;
      }
    }
  }
`

const ChargeModal = ({ activeSubscription }: props): JSX.Element => {
  // Redux hooks
  const userToken = useSelector((state: any) => state.user.token)
  // state
  const [status, setStatus] = useState<string>('inactive')
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const chargeUser = async (userId: number): Promise<void> => {
    setLoading(true)
    const response = await chargeSubscription(userToken, userId)
    setLoading(false)
    if (response?.error || !response) {
      setStatus('error')
      setErrorMessage(response?.error)
    } else {
      setStatus('success')
    }
  }

  return (
    <StyledModal>
      <h2 className="title">Cargo de Suscripción</h2>
      <p>
        Al presionar <b>Confirmar cargo</b> se realizará un cobro por el valor
        de <b>{currency(activeSubscription.subscription_amount).format()}</b> a{' '}
        <b>
          {activeSubscription.user.name} {activeSubscription.user.last_name}
        </b>{' '}
        según su suscripción.{' '}
      </p>
      {loading ? (
        <p>Procesando...</p>
      ) : (
        <div className="action">
          {status === 'inactive' ? (
            <Button
              clickHandler={() => chargeUser(activeSubscription.user_id)}
              text="Confirmar cargo"
            />
          ) : (
            <>
              {status === 'error' ? (
                <p className="error">
                  Sucedió un error al procesar el cargo ❌ - {errorMessage}
                </p>
              ) : (
                <p className="success">
                  Cobro Exitoso, puedes ver los detalles en la sección de{' '}
                  <Link to={`/usuarios/${activeSubscription.user_id}`}>
                    transacciónes en la página del usuario
                  </Link>{' '}
                  o en la sección de{' '}
                  <Link to="/ordenes"> transacciónes en Ordenes</Link>
                </p>
              )}{' '}
            </>
          )}
        </div>
      )}
    </StyledModal>
  )
}

export default ChargeModal
