/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from 'react'
import styles from 'styled-components'
import { useSelector } from 'react-redux'
// types
import { User } from '../../utils/types'
// layout
import { colors } from '../../urvita-ui/constants'
// services
import { getUserDocuments, newDocument } from '../../services/documents'
import { generateContract, deleteContract } from '../../services/weeTrust'
// Components
import Documents from './documents'
import { Button } from '../../urvita-ui'

const DocumentsContainer = styles.section`
  .header-row {
    background-color: white;
    border: 1px solid ${colors.gridGray};
    display: flex;
    gap: 1rem;
    cursor: pointer;
    border-radius: 8px;
    margin: 1rem 0;
    padding: .5rem;
    justify-content: space-between;
    align-items: center;

    &:hover {
      background-color: ${colors.secondaryColor};
    }

    button {
      padding: 0.5rem 1rem;
      border-radius: 5px;
      border: none;
      cursor: pointer;
      background-color: transparent;
    }
  }

  .new-doc {
    border-top: 1px solid black;
    padding-top: 1rem;
    margin-top: 1rem;
  }

  .row {
    display: flex;
    gap: 1rem;
    margin: 1rem 0;
    align-items: center;
  }
`

interface Props {
  user: User
}

export default function DocumentsList({ user }: Props): JSX.Element {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  const [documents, setDocuments] = useState([])
  const [activeDocument, setActiveDocument] = useState()
  const [newDocumentView, setNewDocumentView] = useState(false)
  const [loading, setLoading] = useState(false)
  const [contractId, setContractId] = useState<string>()

  useEffect(() => {
    if (userToken && user) {
      loadUserDocuments(userToken, user.id, setDocuments)
    }
  }, [userToken, user])

  const deleteDocument = async (documentId: string): Promise<void> => {
    const resp = await sendDeletion(
      documentId,
      userToken,
      user.id,
      setLoading,
      setDocuments
    )
    return resp
  }

  return (
    <DocumentsContainer>
      <h2>Documentos</h2>
      {documents.map((document: any) => (
        <section key={document.id}>
          <article
            className="header-row"
            key={document.id}
            onClick={() =>
              setActiveDocument(
                activeDocument === document.id ? '' : document.id
              )
            }
          >
            <h3>
              {document.type} - {document.weetrust_id}{' '}
            </h3>
            <span>{activeDocument === document.id ? '▲' : '▼'}</span>
          </article>
          {activeDocument === document.id && (
            <Documents
              documentId={document.weetrust_id}
              deleteDocument={deleteDocument}
            />
          )}
        </section>
      ))}

      {!newDocumentView && (
        <Button
          text="Nuevo Documento"
          onClick={() => setNewDocumentView(true)}
        />
      )}

      {newDocumentView && (
        <section className="new-doc">
          {!documents.length && (
            <div className="row center">
              <p>No hay documentos</p>
              <Button
                onClick={() =>
                  sendGeneration(user.id, userToken, setLoading, setDocuments)
                }
                text={loading ? 'Cargando' : 'Generar Contrato Maestro'}
              />
            </div>
          )}
          <div className="row center">
            <span>Agregar ID del documento: </span>
            <input
              type="text"
              name="contractId"
              onChange={(e) => setContractId(e.target.value)}
            />
            <Button
              text="Agregar"
              onClick={() =>
                addContract(
                  userToken,
                  contractId,
                  user.id,
                  documents.length ? 'ANEXO' : 'CONTRATO_MAESTRO',
                  setLoading,
                  setNewDocumentView,
                  setDocuments
                )
              }
            />
          </div>
        </section>
      )}
    </DocumentsContainer>
  )
}

// Helper Functions
const loadUserDocuments = async (
  userToken: string,
  userId: number,
  setDocuments: React.Dispatch<React.SetStateAction<never[]>>
): Promise<void> => {
  const data = await getUserDocuments(userToken, userId)
  setDocuments(data)
}

const sendGeneration = async (
  userId: number,
  userToken: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDocuments: React.Dispatch<React.SetStateAction<never[]>>
): Promise<void> => {
  setLoading(true)
  try {
    await generateContract(userToken, userId)
    setLoading(false)
    loadUserDocuments(userToken, userId, setDocuments)
  } catch (error) {
    setLoading(false)
  }
}

const addContract = async (
  userToken: string,
  contractId: string | undefined,
  userId: number,
  type: string,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setNewDocumentView: React.Dispatch<React.SetStateAction<boolean>>,
  setDocuments: React.Dispatch<React.SetStateAction<never[]>>
): Promise<void> => {
  setLoading(true)
  try {
    await newDocument(userToken, {
      user_id: userId,
      weetrust_id: contractId,
      type
    })
    loadUserDocuments(userToken, userId, setDocuments)
    setLoading(false)
    setNewDocumentView(false)
  } catch (error) {
    setLoading(false)
  }
}

const sendDeletion = async (
  documentId: string,
  userToken: string,
  userId: number,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setDocuments: React.Dispatch<React.SetStateAction<never[]>>
): Promise<any> => {
  setLoading(true)
  try {
    const resp = await deleteContract(userToken, documentId)
    loadUserDocuments(userToken, userId, setDocuments)
    setLoading(false)
    return resp
  } catch (error) {
    setLoading(false)
    return error
  }
}
