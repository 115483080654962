import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
// helpers
import { getBuildingPeriodRecord } from '../../../services/trackRecords'
// components
import TrackRecordChecker from './trackRecordChecker'
import { Button } from '../../../urvita-ui'
// icons
import ArrowRight from '../../../assets/icons/arrowRight'
// layout
import { colors } from '../../layout/constants'
// service {
import { updateBuilding } from '../../../services/buildings'
import { updateTokenValue } from '../../../services/valueRecords'

const BuildingRow = (props) => {
  const {
    building,
    period,
    lastValues,
    presentValues,
    setMissingRecordCount,
    toggleModal,
    askForBuildings
  } = props

  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  const [buildingHasTrackRecord, setBuildingHasTrackRecord] = useState(false)
  const [isOperating, setIsOperating] = useState(false)

  const [occupancy, setOccupancy] = useState()
  const [serviceFee, setServiceFee] = useState()
  const [editServiceFee, setEditServiceFee] = useState(false)
  const [editOccupancy, setEditOccupancy] = useState(false)

  useEffect(() => {
    checkRecord()
    setIsOperating(building.phase_status === 'OPERATING' ? true : false)
  }, [])

  const checkRecord = async () => {
    if (building.phase_status === 'OPERATING') {
      const resp = await getBuildingPeriodRecord(
        userToken,
        building.phase_id,
        period
      )

      resp[0]
        ? setBuildingHasTrackRecord(true)
        : setBuildingHasTrackRecord(false)
      if (!resp[0]) {
        setMissingRecordCount((prevState) => prevState + 1)
      }
    }
  }

  const saveBuildingData = async (type) => {
    try {
      if (type === 'occupancy') {
        await updateTokenValue(userToken, presentValues.id, {
          occupancy: occupancy / 100
        })
        setEditOccupancy(false)
      } else {
        await updateBuilding(userToken, building.phase_id, {
          [type]: serviceFee / 100
        })
      }
      askForBuildings()
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <RowStyled>
      <h2 className="building-name">{building.name}</h2>
      {lastValues ? (
        <article>
          <span className="number-title">NOI A: </span>
          <span className="number-helper">$ </span>
          <NumberFormat
            className="mono"
            value={Number(lastValues.noi_a).toFixed(2)}
            displayType="text"
            thousandSeparator
          />

          <span className="number-title">NOI B: </span>
          <span className="number-helper">$ </span>
          <NumberFormat
            className="mono"
            value={Number(lastValues.noi_b).toFixed(2)}
            displayType="text"
            thousandSeparator
          />

          <span className="number-title">Valor: </span>
          <span className="number-helper">$ </span>
          <NumberFormat
            className="mono"
            value={Number(lastValues.value_a).toFixed(2)}
            displayType="text"
            thousandSeparator
          />
        </article>
      ) : (
        '-'
      )}
      <ArrowRight
        className="arrow-icon"
        color={presentValues ? colors.secondaryColor : colors.gridGray}
      />
      {presentValues ? (
        <article>
          <span className="number-title">NOI A: </span>
          <span className="number-helper">$ </span>
          <NumberFormat
            className="mono"
            value={Number(presentValues.noi_a).toFixed(2)}
            displayType="text"
            thousandSeparator
          />
          <span className="number-title">NOI B: </span>
          <span className="number-helper">$ </span>
          <NumberFormat
            className="mono"
            value={Number(presentValues.noi_b).toFixed(2)}
            displayType="text"
            thousandSeparator
          />

          <span className="number-title">Valor: </span>
          <span className="number-helper">$ </span>
          <NumberFormat
            className="mono"
            value={Number(presentValues.value_a).toFixed(2)}
            displayType="text"
            thousandSeparator
          />
        </article>
      ) : (
        <TrackRecordChecker
          hasTrackRecord={buildingHasTrackRecord}
          toggleModal={toggleModal}
        />
      )}
      {isOperating && (
        <section className="input-section">
          {editOccupancy ? (
            <div>
              <input
                className="save-input"
                type="number"
                onChange={(event) => setOccupancy(event.target.value)}
              />
              <Button
                text="Guardar"
                small
                onClick={() => {
                  saveBuildingData('occupancy')
                  setEditServiceFee(false)
                }}
              />
            </div>
          ) : (
            <h3 onClick={() => setEditOccupancy(true)}>
              {presentValues?.occupancy
                ? (presentValues.occupancy * 100).toFixed(2)
                : '-'}{' '}
              %
            </h3>
          )}
        </section>
      )}
      {isOperating && (
        <section className="input-section">
          {editServiceFee ? (
            <div>
              <input
                className="save-input"
                type="number"
                onChange={(event) => setServiceFee(event.target.value)}
              />
              <Button
                text="Guardar"
                small
                onClick={() => {
                  saveBuildingData('service_fee')
                  setEditServiceFee(false)
                }}
              />
            </div>
          ) : (
            <h3 onClick={() => setEditServiceFee(true)}>
              {(building.service_fee * 100).toFixed(2)} %
            </h3>
          )}
        </section>
      )}
    </RowStyled>
  )
}

const RowStyled = styled.li`
  align-items: center;
  border-bottom: 2px solid black;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  padding: 10px 0;

  article {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-column-gap: 10px;
    span {
      display: flex;
      justify-content: flex-end;
    }
  }

  .input-section {
    text-align: center;
  }

  .save-input {
    margin: 4px 0;
  }

  .arrow-icon {
    height: 40px;
    width: 40px;
  }

  .building-name {
    font-size: 27px;
    font-weight: 600;
  }

  .mono {
    font-family: monospace;
  }

  .number-title {
    font-weight: 500;
  }
`

export default BuildingRow
