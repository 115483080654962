import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Switch, Route, Link, Redirect, useLocation } from 'react-router-dom'
import moment from 'moment'
// views
import BuildingsView from './buildings'
import UsersView from './users'
// layout
import DefaultLayout from '../layout'
import Wrapper from '../layout/wrapper'
import { colors } from '../layout/constants'

const MonthUpdate = () => {
  const location = useLocation()

  // Set important variables
  const [period, setPeriod] = useState()
  const [lastPeriod, setLastPeriod] = useState()
  const [nextPeriod, setNextPeriod] = useState()

  // Get intial period dates
  useEffect(() => {
    setPeriod(moment().subtract(1, 'months').format('YYYY-MM-DD')) // TODO return to 1
    setLastPeriod(moment().subtract(2, 'months').format('YYYY-MM-DD')) // TODO return to 2
    setNextPeriod(moment().format('YYYY-MM-DD'))
  }, [])

  return (
    <DefaultLayout sidenav>
      <MonthStyled>
        <Wrapper>
          <div className="tab-manager">
            <Link to="/cambio-de-mes/edificios">
              <article
                className={
                  location.pathname === '/cambio-de-mes/edificios'
                    ? 'tab active'
                    : 'tab'
                }
              >
                Edificios
              </article>
            </Link>
            <Link to="/cambio-de-mes/usuarios">
              <article
                className={
                  location.pathname === '/cambio-de-mes/usuarios'
                    ? 'tab active'
                    : 'tab'
                }
              >
                Usuarios
              </article>
            </Link>
          </div>

          <Switch>
            <Route path="/cambio-de-mes" exact>
              <Redirect to="/cambio-de-mes/edificios" />
            </Route>
            <Route path="/cambio-de-mes/edificios" exact>
              <BuildingsView period={period} lastPeriod={lastPeriod} />
            </Route>
            <Route path="/cambio-de-mes/usuarios" exact>
              <UsersView
                period={period}
                lastPeriod={lastPeriod}
                nextPeriod={nextPeriod}
              />
            </Route>
          </Switch>
        </Wrapper>
      </MonthStyled>
    </DefaultLayout>
  )
}

const MonthStyled = styled.section`
  background-color: ${colors.lightBackground};
  color: ${colors.darkText};
  padding: 15px 0;

  .tab-manager {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 20px;
    width: 100%;

    a {
      color: ${colors.darkText};
    }
  }

  .tab {
    border: 1px solid ${colors.gridGray};
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.5em;
    font-weight: 500;
    padding: 10px 0;
    text-align: center;
    transition: all 0.4s;
    width: 100%;

    &.active {
      background-color: ${colors.secondaryColor};
      border: 1px solid ${colors.secondaryColor};
    }

    :hover {
      background-color: ${colors.highlightColor};
      border: 1px solid ${colors.highlightColor};
    }

    :active {
      transform: translateY(1px);
    }
  }
`

export default MonthUpdate
