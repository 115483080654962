import React, { useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// layout
import { colors, layoutValues } from '../layout/constants'
// services
import { updateOrder } from '../../services/orders'

const Dropdown = styled.article`
  text-transform: capitalize;
  position: relative;

  button {
    border: none;
    border-radius: ${layoutValues.cardRadius};
    cursor: pointer;
    font-weight: 600;
    padding: 8px;
    text-transform: capitalize;
    width: 120px;
  }

  .pending-payment,
  .pending-sign {
    background-color: lightgoldenrodyellow;
    color: gold;
  }

  .completed,
  .paid {
    background-color: lightgreen;
    color: green;
  }

  .canceled {
    background-color: lightcoral;
    color: red;
  }

  .por-pagar,
  .por-facturar,
  .pendiente-de-pago,
  .pendiente-de-firma,
  .pendiente-de-factura {
    background-color: lightgoldenrodyellow;
    color: darkgoldenrod;
  }

  .completada,
  .pagada {
    background-color: lightgreen;
    color: ${colors.primaryDark};
  }

  .cancelada {
    background-color: lightcoral;
    color: white;
  }
`

const Options = styled.ul`
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  box-shadow: ${layoutValues.cardShadow};
  border-radius: ${layoutValues.cardRadius};
  position: absolute;
  padding: 0.2rem;
  left: 0;
  top: calc(100% + 2px);
  width: 120px;

  button {
    background-color: none;
    filter: saturate(0.5);
    border-radius: ${layoutValues.cardRadius};
    margin: 2px 0;
    width: 100%;

    &:hover {
      transform: scale(1.05);
    }
  }
`

type statusOpts = { orderId: number; orderType: string; orderStatus: string }

const OrderRow = ({
  orderId,
  orderType,
  orderStatus
}: statusOpts): JSX.Element => {
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const [status, setStatus] = useState<string>(orderStatus)
  const [open, setOpen] = useState<boolean>(false)

  const toggleDropdown = (): void => {
    setOpen(!open)
  }

  const selectStatus = async (selectedStatus: string): Promise<void> => {
    toggleDropdown()
    setStatus(selectedStatus)
    try {
      await updateOrder(userToken, orderId, { order_status: selectedStatus })
    } catch (error) {
      // console.error(error)
    }
  }

  // const validStatus: Record<string, string[]> = {
  //   deposit: ['pending-payment', 'paid', 'canceled'],
  //   subscription: ['pending-payment', 'paid', 'canceled'],
  //   profits: ['pending-invoice', 'paid', 'canceled'],
  //   buy: ['pending-sign', 'completed', 'canceled'],
  //   sell: ['to-sign', 'completed', 'canceled'],
  //   withdrawal: ['pending-invoice', 'to-pay', 'paid', 'canceled'],
  //   exchange: ['pending-invoice', 'completed', 'canceled'],
  //   reservation: ['to-invoice', 'paid', 'canceled']
  // }

  const validStatus: Record<string, string[]> = {
    abono: ['pendiente de pago', 'pagada', 'cancelada'],
    'abono recurrente': ['pendiente de pago', 'pagada', 'cancelada'],
    ganancia: ['pendiente de factura', 'pagada', 'cancelada'],
    compra: ['pendiente de firma', 'completada', 'cancelada'],
    venta: [
      'pendiente de firma',
      'por facturar',
      'pendiente de pago',
      'pagada',
      'cancelada'
    ],
    retiro: ['pendiente de factura', 'por pagar', 'pagada', 'cancelada'],
    intercambio: ['pendiente de factura', 'completada', 'cancelada'],
    reserva: ['por facturar', 'pagada', 'cancelada']
  }

  return (
    <Dropdown>
      <button
        className={status.replace(/\s/g, '-')}
        type="button"
        onClick={() => toggleDropdown()}
      >
        {status}
      </button>
      {open && (
        <Options>
          {orderType &&
            validStatus[orderType].map((optionStatus: string) => (
              <button
                key={optionStatus}
                className={optionStatus.replace(/\s/g, '-')}
                type="button"
                onClick={() => selectStatus(optionStatus)}
              >
                {optionStatus}
              </button>
            ))}
        </Options>
      )}
    </Dropdown>
  )
}

export default OrderRow
