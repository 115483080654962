import styled from 'styled-components'
import { colors } from '../constants'

const FooterStyled = styled.footer`
  --grid-padding: 50px;
  --helper-color: #919191;
  --urvita-black: #000;
  --urvita-white: #fff;
  display: grid;
  grid-column: 1/3;
  grid-template-columns: 1fr 2fr 2fr;
  min-height: 10vh;
  background: ${colors.primaryColor};
  color: var(--urvita-white);
  position: relative;
  z-index: 1;

  .logo {
    width: 60px;
  }

  .instagram {
    margin: 0 1rem;
    vertical-align: middle;
    width: 1rem;
  }

  .footer-section {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
    }
  }

  .footer-title {
    margin: 0 0 20px 0;
  }

  .footer-text {
    color: var(--helper-color);
    line-height: 24px;
  }

  .terms {
    font-size: 13px;
  }

  @media (max-width: 968px) {
    grid-column: 1/2;
    grid-template-columns: 1fr;
    padding: 25px;

    .footer-section {
      padding: 20px 0;
    }
  }
`

export default FooterStyled
