import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'
import { Country, User } from '../orders/interfaces'
import { Button } from '../../urvita-ui'
// services
import { editUser } from '../../services/users'
// layout
import { colors } from '../layout/constants'
import { countries } from '../../utils/countries'

const UserDataContainer = styled.section`
  .data-list {
    span {
      font-weight: 500;
    }
  }

  .table-header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .table-title {
    margin: 1rem 0 0.5rem 0;
  }

  .table-row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    padding: 2px 0;

    .error {
      border-color: red;
    }
  }

  .container-buttons {
    display: flex;
    justify-content: end;
    gap: 10px;
  }

  .submit-input {
    border: none;
    background-color: ${colors.secondaryColor};
    cursor: pointer;
    display: block;
    padding: 5px 1rem;
    transition: all 0.2s;
    border-radius: 8px;
    margin-top: 1rem;

    :hover {
      background-color: ${colors.highlightColor};
    }

    :active {
      transform: translateY(1px);
    }
  }
  .cancelar {
    background-color: #ff0000c4;
    color: white;

    :hover {
      background-color: red;
    }
  }
`

const UserInfo = ({
  user,
  setUser
}: {
  user: User
  setUser: React.Dispatch<React.SetStateAction<User | undefined>>
}): JSX.Element => {
  const [editMode, setEditMode] = useState(false)
  const [error, setError] = useState(false)

  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  const {
    register,
    formState: { errors },
    handleSubmit
  } = useForm()

  const onSubmit = async (data: any): Promise<void> => {
    let modifiedData = { ...data }
    if (user.citizenship !== 'Mexico') {
      modifiedData = { ...data, tax_regime: 'P_FISICA_EX_USA' }
    }

    setError(false)
    setEditMode(false)
    try {
      const updatedUser = await editUser(userToken, user.id, modifiedData)
      setUser(updatedUser.data)
    } catch (err) {
      setError(true)
    }
  }

  // console.log(user)

  const personaFisica = (): JSX.Element => (
    <UserDataContainer>
      <header className="table-header">
        <h2 className="table-title">Datos Personales</h2>
        {!editMode && (
          <Button text="Editar" onClick={() => setEditMode(true)} />
        )}
      </header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="data-list">
          <li className="table-row">
            <span>Nombre:</span>{' '}
            {editMode ? (
              <>
                <input
                  type="text"
                  defaultValue={user.name || undefined}
                  placeholder="nombre"
                  {...register('name')}
                />
                <input
                  type="text"
                  defaultValue={user.last_name || undefined}
                  placeholder="apellidos"
                  {...register('last_name')}
                />
              </>
            ) : (
              `${user.name} ${user.last_name}`
            )}
          </li>
          <li className="table-row">
            <span>Correo:</span>{' '}
            {editMode ? (
              <input
                type="text"
                defaultValue={user.email || undefined}
                placeholder="Correo"
                {...register('email')}
              />
            ) : (
              `${user.email}`
            )}
          </li>
          <li className="table-row">
            <span>Teléfono:</span>{' '}
            {editMode ? (
              <input
                type="text"
                defaultValue={user.phone || 0}
                placeholder="teléfono"
                {...register('phone')}
              />
            ) : (
              `${user.phone}`
            )}
          </li>
          <li className="table-row">
            <span>Fecha de nacimiento:</span>
            {editMode ? (
              <input
                className={
                  errors?.birthday_date?.type === 'required' ? 'error' : ''
                }
                type="date"
                defaultValue={user.birthday_date || undefined}
                {...register('birthday_date')}
              />
            ) : (
              user.birthday_date
            )}
          </li>
          <li className="table-row">
            <span>Nacionalidad:</span>
            {editMode ? (
              <select
                {...register('citizenship')}
                defaultValue={user.citizenship || 'Mexico'}
                name="citizenship"
              >
                {countries.map((country: any) => (
                  <option key={country.label} value={country.label}>
                    {country.label}
                  </option>
                ))}
              </select>
            ) : (
              // <input
              //   type="text"
              //   defaultValue={user.citizenship || ''}
              //   {...register('citizenship')}
              // />
              user.citizenship
            )}
          </li>
          {/* <li className="table-row">
            <span>País de residencia:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.address_country || ''}
                {...register('address_country')}
              />
            ) : (
              user.address_country
            )}
          </li> */}
          <li className="table-row">
            <span>Tipo de identificación:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.id_type || undefined}
                {...register('id_type')}
              />
            ) : (
              user.id_type
            )}
          </li>
          <li className="table-row">
            <span>Número de ID oficial ({user.id_type}):</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={
                  user.citizenship === 'Mexico'
                    ? user.id_number
                    : user.national_id_number
                }
                {...register(
                  user.citizenship === 'Mexico'
                    ? 'id_number'
                    : 'national_id_number'
                )}
              />
            ) : (
              <>
                {user.citizenship === 'Mexico'
                  ? user.id_number
                  : user.national_id_number}
              </>
            )}
          </li>
          <li className="table-row">
            <span>Pasaporte:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.passport_number}
                {...register('passport_number')}
              />
            ) : (
              user.passport_number
            )}
          </li>
          <li className="table-row">
            <span>Id fiscal (RFC):</span>
            {editMode ? (
              <input type="text" defaultValue={user.rfc} {...register('rfc')} />
            ) : (
              <>{user.rfc}</>
            )}
          </li>
          <li className="table-row">
            <span>Estado civil:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.marital_status || undefined}
                {...register('marital_status')}
              />
            ) : (
              user.marital_status
            )}
          </li>
        </ul>

        <h2 className="table-title">Dirección de Facturación</h2>
        <ul className="data-list">
          <li className="table-row">
            <span>Calle y número:</span>
            {editMode ? (
              <>
                <input
                  type="text"
                  defaultValue={user.address_street || undefined}
                  {...register('address_street')}
                />
                <input
                  type="text"
                  defaultValue={user.address_number || undefined}
                  {...register('address_number')}
                />
              </>
            ) : (
              `${user.address_street} ${user.address_number}`
            )}
          </li>
          <li className="table-row">
            <span>Colonia:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.address_suburb || undefined}
                {...register('address_suburb')}
              />
            ) : (
              user.address_suburb
            )}
          </li>
          <li className="table-row">
            <span>Delegación / Municipio: </span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.address_city || undefined}
                {...register('address_city')}
              />
            ) : (
              user.address_city
            )}
          </li>
          <li className="table-row">
            <span>Código postal:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.address_postal_code || undefined}
                {...register('address_postal_code')}
              />
            ) : (
              user.address_postal_code
            )}
          </li>
          <li className="table-row">
            <span>Estado:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.address_state || undefined}
                {...register('address_state')}
              />
            ) : (
              user.address_state
            )}
          </li>
          <li className="table-row">
            <span>País:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={user.address_country || undefined}
                {...register('address_country')}
              />
            ) : (
              user.address_country
            )}
          </li>
        </ul>
        {editMode && (
          <div className="container-buttons">
            <button
              onClick={() => setEditMode(false)}
              type="button"
              className="submit-input cancelar"
            >
              Cancelar
            </button>
            <input className="submit-input" type="submit" value="Guardar" />
          </div>
        )}
      </form>
    </UserDataContainer>
  )

  const personaMoral = (): JSX.Element => (
    <UserDataContainer>
      <header className="table-header">
        <h2 className="table-title">Datos de la Empresa</h2>
        {!editMode && (
          <Button text="Editar" onClick={() => setEditMode(true)} />
        )}
      </header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <ul className="data-list">
          <li className="table-row">
            <span>RAZÓN SOCIAL:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_name}`}
                {...register('company_name')}
              />
            ) : (
              user.company_name
            )}
          </li>
          <li className="table-row">
            <span>RFC:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.rfc}`}
                {...register('rfc')}
              />
            ) : (
              user.rfc
            )}
          </li>
          <li className="table-row">
            <span>No. DE ESCRITURA:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_deeds}`}
                {...register('company_deeds')}
              />
            ) : (
              user.company_deeds
            )}
          </li>
          <li className="table-row">
            <span>FECHA DE CONSTITUCION:</span>
            {editMode ? (
              <input
                type="date"
                defaultValue={`${user.company_constitution_date}`}
                {...register('company_constitution_date')}
              />
            ) : (
              user.company_constitution_date
            )}
          </li>
          <li className="table-row">
            <span>PAÍS DE CONSTITUCION:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_country}`}
                {...register('company_country')}
              />
            ) : (
              user.company_country
            )}
          </li>
          <li className="table-row">
            <span>FOLIO MERCANTIL ELECTRONICO:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_comercial_folio}`}
                {...register('company_comercial_folio')}
              />
            ) : (
              user.company_comercial_folio
            )}
          </li>
          {/* <li className="table-row">
            <span>FECHA DEL FOLIO MERCANTIL ELECTRONICO:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_notary_number}`}
                {...register('company_notary_number')}
              />
            ) : (
              user.company_notary_number
            )}
          </li> */}
          <li className="table-row">
            <span>NOTARIA NÚMERO:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_notary_number}`}
                {...register('company_notary_number')}
              />
            ) : (
              user.company_notary_number
            )}
          </li>
          <li className="table-row">
            <span>NOMBRE DE NOTARIO:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_notary_name}`}
                {...register('company_notary_name')}
              />
            ) : (
              user.company_notary_name
            )}
          </li>
          {/* <li className="table-row">
            <span>CIUDAD DE LA NOTARIA:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.company_constitution_date}`}
                {...register('company_constitution_date')}
              />
            ) : (
              user.company_constitution_date
            )}
          </li> */}
        </ul>

        <h2 className="table-title">Datos del Representante Legal</h2>
        <ul className="data-list">
          <li className="table-row">
            <span>NOMBRE:</span>
            {editMode ? (
              <>
                <input
                  type="text"
                  defaultValue={`${user.name}`}
                  placeholder="nombre"
                  {...register('name')}
                />
                <input
                  type="text"
                  defaultValue={`${user.last_name}`}
                  placeholder="apellidos"
                  {...register('last_name')}
                />
              </>
            ) : (
              `${user.name} ${user.last_name}`
            )}
          </li>
          <li className="table-row">
            <span>FECHA DE NACIMIENTO:</span>
            {editMode ? (
              <input
                type="date"
                defaultValue={`${user.birthday_date}`}
                {...register('birthday_date')}
              />
            ) : (
              user.birthday_date
            )}
          </li>
          <li className="table-row">
            <span>NACIONALIDAD:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.citizenship}`}
                {...register('citizenship')}
              />
            ) : (
              user.citizenship
            )}
          </li>
          <li className="table-row">
            <span>PAIS DE RESIDENCIA:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.address_country}`}
                {...register('address_country')}
              />
            ) : (
              user.address_country
            )}
          </li>
          <li className="table-row">
            <span>NUMERO DE ID OFICIAL ({user.id_type}):</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.id_number}`}
                {...register('id_number')}
              />
            ) : (
              user.id_number
            )}
          </li>
          <li className="table-row">
            <span>RFC DEL REPRESENTANTE LEGAL:</span>
          </li>
        </ul>

        <h2 className="table-title">Dirección de Facturación</h2>
        <ul className="data-list">
          <li className="table-row">
            <span>CALLE Y NUMERO:</span>
            {editMode ? (
              <>
                <input
                  type="text"
                  defaultValue={`${user.address_street}`}
                  {...register('address_street')}
                />
                <input
                  type="text"
                  defaultValue={`${user.address_number}`}
                  {...register('address_number')}
                />
              </>
            ) : (
              `${user.address_street} ${user.address_number}`
            )}
          </li>
          <li className="table-row">
            <span>COLONIA:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.address_suburb}`}
                {...register('address_suburb')}
              />
            ) : (
              user.address_suburb
            )}
          </li>
          <li className="table-row">
            <span>DELEGACION / MUNICIPIO: </span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.address_city}`}
                {...register('address_city')}
              />
            ) : (
              user.address_city
            )}
          </li>
          <li className="table-row">
            <span>CÓDIGO POSTAL:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.address_postal_code}`}
                {...register('address_postal_code')}
              />
            ) : (
              user.address_postal_code
            )}
          </li>
          <li className="table-row">
            <span>ESTADO:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.address_state}`}
                {...register('address_state')}
              />
            ) : (
              user.address_state
            )}
          </li>
          <li className="table-row">
            <span>PAÍS:</span>
            {editMode ? (
              <input
                type="text"
                defaultValue={`${user.address_country}`}
                {...register('address_country')}
              />
            ) : (
              user.address_country
            )}
          </li>
        </ul>
        {editMode && (
          <input className="submit-input" type="submit" value="Guardar" />
        )}
      </form>
    </UserDataContainer>
  )

  return (
    <section>
      {user.tax_regime !== 'P_MORAL' && personaFisica()}
      {user.tax_regime === 'P_MORAL' && personaMoral()}
    </section>
  )
}

export default UserInfo
