// return the user data from the session storage
export const getUser = () => {
  const userStr = sessionStorage.getItem('user')
  if (userStr) return JSON.parse(userStr)
  return null
}

// return the token from the session storage
export const getToken = () => sessionStorage.getItem('token') || null

// remove the token and user from the session storage
export const removeUserSession = () => {
  sessionStorage.removeItem('token')
  sessionStorage.removeItem('user')
  sessionStorage.removeItem('type')
}

// set the token and user from the session storage
export const setUserSession = (token, user, type) => {
  sessionStorage.setItem('token', token)
  sessionStorage.setItem('user', JSON.stringify(user))
  sessionStorage.setItem('type', JSON.stringify(type))
}
