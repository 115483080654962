import React from 'react'
import styled from 'styled-components'
// images
import urvitaIcon from '../../../assets/img/urvita-avatar.png'

const Loader = ({ text }) => (
  <LoaderStyles>
    <img className="loader-image" src={urvitaIcon} />
    <p className="loader-text">{text}</p>
  </LoaderStyles>
)

const LoaderStyles = styled.section`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 60vh;
  padding: 0 15px;
  width: 100%;

  .loader-image {
    animation-name: loading;
    animation-duration: 4s;
    animation-iteration-count: infinite;
    animation-fill-mode: easy-in;
    width: 200px;
  }

  .loader-text {
    font-size: 24px;
    font-weight: 500;
    margin: 20px 0;
    text-align: center;
  }

  @keyframes loading {
    0% {
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export default Loader
