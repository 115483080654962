import styled from 'styled-components'
import { colors } from '../constants'

const SidenavStyles = styled.aside`
  background-color: ${colors.primaryDark};
  color: ${colors.white};
  min-height: 90vh;
  transition: right 0.6s;
  z-index: 1;

  .sidenav-content {
    background-color: ${colors.primaryDark};
    box-sizing: border-box;
    color: ${colors.white};
    padding: 25px;
    height: 100vh;
    position: fixed;
    width: 200px;
  }

  .logo {
    display: block;
    margin: 0 auto;
    width: 100px;
  }

  .sidenav-navegation {
    list-style: none;
    margin-top: 40px;
  }

  .sidenav-item {
    box-sizing: border-box;
    border-bottom: 1px solid ${colors.white};
    cursor: pointer;
    height: 60px;
    padding: 20px 0;
    text-align: center;
    transition: all 0.2s;

    :hover,
    &[data-active='true'] {
      color: ${colors.secondaryColor};
      font-weight: 600;
      font-size: 20px;

      a {
        color: ${colors.secondaryColor};
      }
    }

    a {
      color: ${colors.white};
    }
  }

  .background {
    display: none;
  }

  @media (max-width: 967px) {
    position: fixed;
    right: ${(props) => (props.active ? 0 : '-200px')};

    .sidenav-content {
      position: initial;
    }

    .background {
      background-color: #fff6;
      display: ${(props) => (props.active ? 'block' : 'none')};
      height: 100vh;
      left: 0;
      position: fixed;
      top: 0;
      width: 100vw;
      z-index: -1;
    }
  }
`

export { SidenavStyles }
