/* eslint-disable use-isnan */
import React, { useState } from 'react'
import moment from 'moment'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { CSVReader } from 'react-papaparse'
// helpers
import { newTrackRecord } from '../../../services/trackRecords'
// layout
import { colors } from '../../layout/constants'
import Button from '../../layout/shared/button'
import Modal from '../../layout/shared/modal'
import SectionTitle from '../../layout/shared/sectionTitle'

const AddTrackRecord = ({ setHasTrackRecord, modal, toggleModal }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)

  const [trackRecord, setTrackRecord] = useState([])
  const [hasError, setHasError] = useState()
  const [errorMessage, setErrorMessage] = useState('')
  const [loading, setLoading] = useState(false)

  const handleOnFileLoad = (data) => {
    setHasError(true)
    setErrorMessage('')
    data = data.splice(1, data.length - 1)
    const formattedTrackRecord = data.map((record) => {
      const handleCurrency = (number) => {
        number = parseFloat(record.data[3].replace(/,/g, ''))
        if (number === NaN) {
          setHasError(true)
          setErrorMessage(
            'Error: El formato de los números del archivo no es correcto'
          )
        } else {
          return number
        }
      }

      // Create track record objects
      return {
        period: moment(record.data[0], 'DD/MM/YYYY').format('YYYY-MM-DD'),
        unit_id: record.data[1],
        unit_tokens: Number(record.data[2]),
        unit_rent: handleCurrency(record.data[3])
      }
    })
    setTrackRecord(formattedTrackRecord)
  }

  const uploadTrackRecord = async () => {
    setLoading(true)
    const newRecords = await trackRecord.map(async (record) => {
      try {
        // Create new track records
        const resp = await newTrackRecord(userToken, record)

        // manage response errors
        if (resp.error) {
          setHasError(true)
          if (resp.error.code === 409) {
            setErrorMessage(
              'Alguno de los track records que subiste ya existen'
            )
          }
        }
        return resp
      } catch (error) {
        // manage server errors
        setHasError(true)
        setErrorMessage(
          'Ocurrió un error al subir el archivo, comprueba tu conexión a internet o contacta a los desarrolladores.'
        )
      }
    })

    const createdRecords = await Promise.all(newRecords)
    setLoading(false)
    setHasTrackRecord(true)
    toggleModal(false)
    window.location.reload()
  }

  return (
    <AddStyled>
      {modal && (
        <Modal toggleModal={toggleModal}>
          <SectionTitle title="Agregar Track Record" />
          <p className="description-text">
            Puedes subir un archivo tipo .csv para agregar el track record de
            este mes.
          </p>
          <p>
            <a
              href="https://docs.google.com/spreadsheets/d/1ZKpnaLdXz5bXDe_anRcWB0C33T6GbxB2MO1gHBYChaA/edit?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              Aquí puedes consultar un ejemplo.
            </a>
          </p>
          <p className="description-text">
            Ojo: Hay que revisar que los decimales se separen con puntos.
          </p>

          <CSVReader
            onFileLoad={handleOnFileLoad}
            addRemoveButton
            onRemoveFile={() => setTrackRecord([])}
          >
            <span className="drop-box">
              Arrastra un archivo .csv o da click para subrilo.
            </span>
          </CSVReader>

          <div className="button-container">
            <Button
              text={loading ? 'Cargando...' : 'Subir Track Record'}
              disabled={!trackRecord[0] || loading}
              clickHandler={() => uploadTrackRecord()}
            />
          </div>
          {hasError && <p className="error-message">{errorMessage}</p>}
        </Modal>
      )}
    </AddStyled>
  )
}

const AddStyled = styled.section`
  .description-text {
    margin-bottom: 20px;
  }

  a {
    color: ${colors.secondaryColor};
    :hover {
      color: ${colors.highlightColor};
    }
  }

  .drop-box {
    color: ${colors.highlightColor};
  }

  .error-message {
    color: ${colors.highlightColor};
    text-align: center;
  }
`

export default AddTrackRecord
