import React from 'react'
import styled from 'styled-components'
// layout
import Button from '../../layout/shared/button'
// icons
import checkIcon from '../../../assets/icons/check-green.png'

const TrackRecordChecker = ({ hasTrackRecord, toggleModal }) => (
  <CheckerStyled>
    {hasTrackRecord ? (
      <span>
        Track record agregado
        <img src={checkIcon} alt="check" />
      </span>
    ) : (
      <>
        <Button
          text="Agregar track record"
          small
          clickHandler={() => toggleModal(true)}
        />
      </>
    )}
  </CheckerStyled>
)

const CheckerStyled = styled.article`
  img {
    margin: 0 5px;
  }
`

export default TrackRecordChecker
