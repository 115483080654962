/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import styled from 'styled-components'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import 'moment/locale/es'
// components
import ChargeModal from './chargeModal'
import PlanRow from './planRow'
// layout
import { colors } from '../layout/constants'
import Modal from '../layout/shared/modal'
import { Plan } from '../../interfaces'
import { EditSubscriptionForm } from './editSubscriptionForm'

const StyledHeader = styled.h2`
  font-size: 1.2em;
  color: ${colors.white};
  letter-spacing: 0.5px;
  font-weight: 500;
`

interface props {
  plans: Plan[]
  setPlan: (value: Plan[]) => void
}

const BasicTable = ({ plans, setPlan }: props): JSX.Element => {
  const [chargeModal, setChargeModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [newData, setNewData] = useState({
    newAmount: 0,
    newChargeDate: 0,
    newStatus: false
  })
  const [editPlan, setEditPlan] = useState<boolean>(false)
  const [activePlan, setActivePlan] = useState<Plan>()

  const toggleModal = (value: boolean): void => {
    setModal(value)
    setChargeModal(value)
  }

  const openModal = (type: string): void => {
    if (type === 'charge') {
      setChargeModal(true)
    } else {
      setModal(true)
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead
          sx={{
            backgroundColor: colors.primaryColor
          }}
        >
          <TableRow>
            <TableCell>
              <StyledHeader>#</StyledHeader>
            </TableCell>
            <TableCell align="left">
              <StyledHeader>Usuario</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Pago Mensual</StyledHeader>
            </TableCell>
            <TableCell align="right">
              <StyledHeader>Pagos realizados</StyledHeader>
            </TableCell>
            <TableCell align="right">
              <StyledHeader>Próximo cobro</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>m2 a comprar</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Finalización de plan</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Suscripción</StyledHeader>
            </TableCell>
            <TableCell align="center">
              <StyledHeader>Editar</StyledHeader>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {plans.map((plan, index) => (
            <PlanRow
              key={plan.id}
              plan={plan}
              index={index}
              openModal={openModal}
              setActivePlan={setActivePlan}
              setEditPlan={setEditPlan}
              setNewData={setNewData}
            />
          ))}
        </TableBody>
      </Table>

      {chargeModal && activePlan && !editPlan && (
        <Modal toggleModal={toggleModal}>
          <ChargeModal activePlan={activePlan} />
        </Modal>
      )}

      {modal && activePlan && editPlan && (
        <Modal toggleModal={toggleModal}>
          <EditSubscriptionForm
            newData={newData}
            setNewData={setNewData}
            setPlan={setPlan}
            plan={activePlan}
            setEditPlan={setEditPlan}
          />
        </Modal>
      )}
    </TableContainer>
  )
}

export default BasicTable
