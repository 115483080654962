import React from 'react'

const ArrowRight = (props) => {
  const { color, className, onClick } = props

  return (
    <svg
      className={className}
      viewBox="0 0 343.089 343.089"
      onClick={() => onClick()}
    >
      <g>
        <g>
          <g>
            <path
              d="M341.521,167.415c-0.267-0.321-0.563-0.618-0.885-0.885l-97.176-99.265c-2.942-3.174-7.901-3.362-11.075-0.42
				c-1.574,1.459-2.481,3.499-2.509,5.645v52.245h-125.91c-4.702,0-9.927,1.045-9.927,5.224v83.592
				c0.621,5.301,4.664,9.557,9.927,10.449h125.91v47.02c0.076,3.126,1.89,5.947,4.702,7.314h3.135
        c2.183-0.086,4.246-1.024,5.747-2.612l97.176-98.743C343.522,174.582,343.918,170.3,341.521,167.415z"
              fill={color || 'black'}
            />
            <path
              d="M70.531,122.122c-4.328,0-7.837,3.509-7.837,7.837v83.592c0,4.328,3.509,7.837,7.837,7.837s7.837-3.509,7.837-7.837
        v-83.592C78.367,125.63,74.859,122.122,70.531,122.122z"
              fill={color || 'black'}
            />
            <path
              d="M39.184,122.122c-4.328,0-7.837,3.509-7.837,7.837v83.592c0,4.328,3.509,7.837,7.837,7.837s7.837-3.509,7.837-7.837
        v-83.592C47.02,125.63,43.512,122.122,39.184,122.122z"
              fill={color || 'black'}
            />
            <path
              d="M7.837,122.122c-4.328,0-7.837,3.509-7.837,7.837v83.592c0,4.328,3.509,7.837,7.837,7.837
        c4.328,0,7.837-3.509,7.837-7.837v-83.592C15.673,125.63,12.165,122.122,7.837,122.122z"
              fill={color || 'black'}
            />
          </g>
        </g>
      </g>
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
      <g />
    </svg>
  )
}

export default ArrowRight
