export async function getNotes(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Notes`, {
    method: 'GET',
    headers: myHeaders,
    redirect: 'follow'
  }).catch((err) => console.error(err))
  const { data } = await response.json()
  return data
}

export async function getUserNotes(userToken, user_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/Notes/user/${user_id}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const Notes = await response.json()
  return Notes.data
}

export async function getSingleNotes(userToken, Note_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/Notes/${Note_id}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const Notes = await response.json()
  return Notes.data
}

export async function newNote(userToken, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/Notes`, {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(body)
  }).catch((err) => console.error(err))
  const data = await response.json()
  return data.rows
}

export async function updateNote(userToken, Note_id, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/Notes/${Note_id}`,
    {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.rows
}

export async function deleteNote(userToken, Note_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/Notes/${Note_id}`,
    {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}
