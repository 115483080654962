import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import dateFormat from '../helpers/dateFormat'
// services
import { getLastSubscriptionTransaction } from '../../services/subscriptions'

interface props {
  userId: number
  lastPay: Date | undefined
  setLastPay: React.Dispatch<React.SetStateAction<Date | undefined>>
}

const LastTransaction = ({
  userId,
  lastPay,
  setLastPay
}: props): JSX.Element => {
  // Redux hooks
  const userToken = useSelector((state: any) => state.user.token)

  useEffect(() => {
    const getLastTrans = async (user_id: number): Promise<void> => {
      const lastTrans = await getLastSubscriptionTransaction(userToken, user_id)
      setLastPay(lastTrans.createdAt)
    }
    getLastTrans(userId)
  }, [setLastPay, userId, userToken])

  return <p>{dateFormat(lastPay, 'full')}</p>
}

export default LastTransaction
