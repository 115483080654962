import React, { useState } from 'react'
import styled from 'styled-components'
// components
import UsersList from './adminUsersList'
import NewUser from './newUser'
// layout
import { colors } from '../layout/constants'
import Wrapper from '../layout/wrapper'
import SectionTitle from '../layout/shared/sectionTitle'
import Modal from '../layout/shared/modal'

const SettingsView = () => {
  const [modal, setModal] = useState(false)

  const toggleModal = (value) => {
    setModal(value)
  }

  return (
    <SettingsStyled>
      <Wrapper>
        <SectionTitle
          title="Configuración"
          action="Nuevo usuario"
          handleClick={toggleModal}
        />
        <UsersList />
      </Wrapper>

      {modal && (
        <Modal toggleModal={toggleModal}>
          <NewUser toggleModal={toggleModal} />
        </Modal>
      )}
    </SettingsStyled>
  )
}

const SettingsStyled = styled.section`
  background-color: ${colors.lightBackground};
  color: ${colors.darkText};
  padding: 15px 0;
`

export default SettingsView
