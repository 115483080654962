import React from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
// layout
import { colors } from '../layout/constants'

const UsersList = ({ usersList, setActiveUser, highlightId }) => {
  const sortedUsersList = usersList.sort((a, b) => {
    if (a.rfc < b.rfc) {
      return -1
    }
    if (a.rfc > b.rfc) {
      return 1
    }
    return 0
  })

  return (
    <ListStyled columns={usersList.length}>
      <li key="Title" className="title item">
        <span>Nombre</span>
        <span>RFC</span>
        <span>Ref Code</span>
        <span className="user-name">Balance</span>
      </li>
      {sortedUsersList.map((user, index) => (
        <li
          key={user.id}
          className={highlightId === user.id ? 'active item' : 'item'}
          onClick={() => setActiveUser(user.id)}
        >
          <span>
            {user.last_name}, {user.name}
          </span>
          <span>{user.rfc}</span>
          <span>{user.ref_code}</span>
          <NumberFormat
            className={
              user.balance < 0
                ? 'red mono'
                : Number(user.balance) > Number(user.last_buyed_building_price)
                ? 'green mono'
                : 'mono'
            }
            value={Number(user.balance).toFixed(2)}
            displayType="text"
            thousandSeparator
            prefix="$"
          />
        </li>
      ))}
    </ListStyled>
  )
}

const ListStyled = styled.ul`
  list-style: none;

  .item {
    border-bottom: 1px solid ${colors.gridGray};
    cursor: pointer;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-column-gap: 20px;
    padding: 5px 2px;

    :hover {
      background-color: ${colors.highlightColor};
    }

    &.active {
      background-color: ${colors.secondaryColor};
    }
  }

  .title {
    cursor: inherit;
    font-weight: 500;

    :hover {
      background-color: none;
    }
  }

  .mono {
    font-family: monospace;
    text-align: right;
  }

  .red {
    color: red;
  }

  .green {
    color: green;
  }
`

export default UsersList
