import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// helpers
import { getUsers, deleteUser } from '../../services/users'
// components
import FilterHeader from './filterHeader'
import TokenTransactionsView from './tokenTransactions.js/index.js'
import UsersList from './usersList'
import UserDetails from './userDetails'
import NewUser from './newUser'
// layout
import DefaultLayout from '../layout'
import Wrapper from '../layout/wrapper'
import SectionTitle from '../layout/shared/sectionTitle'
import Modal from '../layout/shared/modal'
import { colors } from '../layout/constants'

const tabs = [
  { name: 'Todos', value: 0 },
  { name: 'Compra de Tokens', value: 1 }
]

const UsersView = () => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)

  const [activeTab, setActiveTab] = useState({ name: 'Todos', value: 0 })

  const [usersList, setUsersList] = useState([])
  const [highlightId, setHighlightId] = useState()
  const [modal, setModal] = useState(false)

  useEffect(() => {
    getUsersList()
  }, [])

  const toggleModal = (value) => {
    setModal(value)
  }

  // Get al users
  const getUsersList = async () => {
    const users = await getUsers(userToken)
    const investors = users.data.filter((user) => user.user_type === 'investor' && user.rfc)
    setUsersList(investors)
  }

  // Select active user by Id
  const setActiveUser = (id) => {
    if (id !== highlightId) {
      setHighlightId(id)
    }
  }

  const deleteHandler = (Id) => {
    deleteUser(userToken, Id)
  }

  return (
    <DefaultLayout sidenav>
      <UsersViewStyled>
        <Wrapper>
          <FilterHeader
            steps={tabs}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          {activeTab.name === 'Todos' ? (
            <section>
              <SectionTitle
                title="Usuarios"
                subtitle={usersList.length}
                action="Nuevo usuario"
                handleClick={toggleModal}
              />

              <section className="user-container">
                <UsersList
                  usersList={usersList}
                  setActiveUser={setActiveUser}
                  highlightId={highlightId}
                />
                {highlightId ? (
                  <UserDetails
                    highlightId={highlightId}
                    setHighlightId={setHighlightId}
                    getUsersList={getUsersList}
                  />
                ) : (
                  <p className="no-user">
                    Seleciona un usuario para ver su información.
                  </p>
                )}
              </section>
            </section>
          ) : (
            <section>
              <TokenTransactionsView />
            </section>
          )}
        </Wrapper>

        {modal && (
          <Modal toggleModal={toggleModal}>
            <NewUser toggleModal={toggleModal} updateUsers={getUsersList} />
          </Modal>
        )}
      </UsersViewStyled>
    </DefaultLayout>
  )
}

const UsersViewStyled = styled.section`
  background-color: ${colors.lightBackground};
  color: ${colors.darkText};
  padding: 15px 0;

  .user-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 40px;

    .no-user {
      font-size: 30px;
      font-weight: 500;
      text-align: center;
      margin: 100px 0;
    }
  }
`

export default UsersView
