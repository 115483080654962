export async function getDocument(userToken, documentId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/weetrust/documents/${documentId}`,
    {
      method: 'GET',
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const { document } = await response.json()
  return document
}

export async function generateContract(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/weetrust/contract`,
    {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({ userId })
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

export async function signContract(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/weetrust/contract/signContract`,
    {
      method: 'PUT',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify({ userId })
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

export async function deleteContract(userToken, documentId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/weetrust/contract/${documentId}`,
    {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}
