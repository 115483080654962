import React from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
// layout
import { colors } from '../layout/constants'
// services
import { getAdminUsers, deleteAdminUser } from '../../services/admin'

const SettingsView = () => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  // Query
  const { isLoading, isError, data, error } = useQuery('adminUsers', () =>
    getAdminUsers(userToken)
  )

  const deleteUser = (id) => {
    deleteAdminUser(userToken, id)
  }

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <ListStyled>
      <li className="title-row">
        Equipo administrativo (
        {data.filter((user) => user.user_type === 'ADMIN').length})
      </li>
      {data
        .filter((user) => user.user_type === 'ADMIN')
        .map((user, index) => (
          <li key={index} className="user-row">
            {user.email}
            <button
              className="delete-button"
              onClick={() => deleteUser(user.id)}
            >
              Borrar
            </button>
          </li>
        ))}
      <li className="title-row">
        Equipo de Ventas (
        {data.filter((user) => user.user_type === 'SALES').length})
      </li>
      {data
        .filter((user) => user.user_type === 'SALES')
        .map((user, index) => (
          <li key={index} className="user-row">
            {user.email}
            <button
              className="delete-button"
              onClick={() => deleteUser(user.id)}
            >
              Borrar
            </button>
          </li>
        ))}
    </ListStyled>
  )
}

const ListStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  .title-row {
    border-bottom: 1px solid ${colors.darkText};
    font-size: 1.2rem;
    font-weight: 500;
    margin-top: 1rem;
  }

  .user-row {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0;

    :hover {
      background-color: ${colors.secondaryColor};
    }
  }

  .delete-button {
    background-color: ${colors.warningColor};
    border: none;
    border-radius: 8px;
    color: ${colors.white};
    cursor: pointer;
    padding: 0.3rem;
    margin: 0 1rem;

    :hover {
      background-color: red;
    }
  }
`

export default SettingsView
