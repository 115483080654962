import React from 'react'
import styled from 'styled-components'

const CardWrapper = styled.article`
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  padding: 1rem;
  width: 100%;
`

export const Card = ({ children, className = '' }) => {
  return (
    <CardWrapper className={className ? className : ''}>{children}</CardWrapper>
  )
}
