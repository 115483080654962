import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
// layout
import { layoutValues } from './constants'

const DropdownContainer = styled.article`
  display: inline-block;
  text-transform: capitalize;
  position: relative;

  button {
    border: none;
    border-radius: ${layoutValues.cardRadius};
    cursor: pointer;
    font-weight: 600;
    padding: 8px;
    text-transform: capitalize;
    width: 120px;
  }
`

const Options = styled.ul`
  cursor: pointer;
  box-sizing: border-box;
  background-color: white;
  box-shadow: ${layoutValues.cardShadow};
  border-radius: ${layoutValues.cardRadius};
  position: absolute;
  padding: 0.2rem;
  left: 0;
  top: calc(100% + 2px);
  width: 120px;
  z-index: 1;

  button {
    background-color: none;
    filter: saturate(0.5);
    border-radius: ${layoutValues.cardRadius};
    margin: 2px 0;
    width: 100%;

    &:hover {
      transform: scale(1.05);
    }
  }
`

const Dropdown = ({
  options,
  onChange,
  defaultValue
}: {
  options: string[]
  onChange: (status: string) => void
  // eslint-disable-next-line react/require-default-props
  defaultValue?: number | undefined
}): JSX.Element => {
  const [status, setStatus] = useState<string>(options[defaultValue || 0])
  const [open, setOpen] = useState<boolean>(false)

  useEffect(() => {
    if (defaultValue) {
      setStatus(options[defaultValue])
    }
  }, [defaultValue, options])

  const toggleDropdown = (): void => {
    setOpen(!open)
  }

  const selectStatus = async (selectedStatus: string): Promise<void> => {
    toggleDropdown()
    setStatus(selectedStatus)
    onChange(selectedStatus)
  }

  return (
    <DropdownContainer>
      <button
        className={status.replace(/\s/g, '-')}
        type="button"
        onClick={() => toggleDropdown()}
      >
        {status}
      </button>
      {open && (
        <Options>
          {options &&
            options.map((option: string) => (
              <button
                key={option}
                type="button"
                onClick={() => selectStatus(option)}
              >
                {option}
              </button>
            ))}
        </Options>
      )}
    </DropdownContainer>
  )
}

export default Dropdown
