import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// layout
import { Card, colors } from '../../urvita-ui'
import { Token } from '../orders/interfaces'
// services
import { getBuildings } from '../../services/buildings'
import { getBuildingTokens, getUserTokens } from '../../services/tokens'

const PickerContainer = styled.section`
  .buildings-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
  }

  .tokens-container {
    margin-top: 0.5rem;
  }

  .tokens-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 1rem 0;
  }

  .token-chip {
    cursor: pointer;
    background-color: ${colors.secondaryColor};
    border-radius: 4px;
    padding: 2px 5px;
    border: none;
    margin: 1px 2px;
    width: 150px;

    &.selected {
      background-color: ${colors.secondaryDark};
    }

    &:hover {
      background-color: ${colors.secondaryDark};
    }
  }
`

type pickerData = {
  userRfc: string | undefined
  orderType: string
  orderTokens: Token[]
  setOrderTokens: (OrderTokens: Token[]) => void
  setOrderBuilding: (OrderBuilding: string) => void
}

const TokenPicker = ({
  userRfc,
  orderType,
  orderTokens,
  setOrderTokens,
  setOrderBuilding
}: pickerData): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  // filter variables
  const [buildings, setBuildings] = useState([])

  const [orderBuildingId, setOrderBuildingId] = useState<string>('')
  const [buildingTokens, setBuildingTokens] = useState<Token[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    // Separate buy and sell logic
    if (orderType === 'compra') {
      const getBuildingsFunction = async (): Promise<void> => {
        const buildingsList = await getBuildings(userToken)
        setBuildings(buildingsList.data)
      }
      getBuildingsFunction()
    } else {
      const getBuildingsFunction = async (): Promise<void> => {
        const userTokens = await getUserTokens(userToken, userRfc)
        setBuildingTokens(userTokens)
      }
      getBuildingsFunction()
    }
  }, [orderType, userRfc, userToken])

  useEffect(() => {
    if (orderBuildingId) {
      setLoading(true)
      const getTokensFunction = async (): Promise<void> => {
        const tokens = await getBuildingTokens(userToken, orderBuildingId)
        const sortedTokens = tokens.sort((a: Token, b: Token) => {
          if (a.id > b.id) {
            return 1
          }
          if (a.id < b.id) {
            return -1
          }
          return 0
        })
        setLoading(false)
        setBuildingTokens(sortedTokens)
      }
      getTokensFunction()
    }

    if (!orderBuildingId) {
      setBuildingTokens([])
    }
  }, [orderBuildingId, userToken])

  const addToken = (newToken: Token): void => {
    const isPresent = orderTokens.find((token) => token.id === newToken.id)
    if (isPresent) {
      const tokenArray = orderTokens.filter((token) => token.id !== newToken.id)
      const sortedTokens = tokenArray.sort((a, b) => a.id - b.id)
      setOrderTokens(sortedTokens)
    } else {
      const tokenArray = [...orderTokens, newToken]
      const sortedTokens = tokenArray.sort((a, b) => a.id - b.id)
      setOrderTokens(sortedTokens)
    }
  }

  const showAvailableTokens = (): JSX.Element => {
    if (buildingTokens) {
      const availableTokens = buildingTokens.filter((token: Token) => {
        if (orderType === 'compra') {
          return token.available && token.type === 'TOKEN-A'
        }
        return token.type === 'TOKEN-A'
      })

      if (loading) {
        return <p>Cargando....</p>
      }

      if (availableTokens.length <= 0) {
        return <p>Este edificio no tiene tokens disponibles</p>
      }

      return (
        <ul className="tokens-list">
          {availableTokens.map((token) => {
            const isPresent = orderTokens.find(
              (selectedToken) => selectedToken.id === token.id
            )

            return (
              <li key={token.id}>
                <button
                  className={isPresent ? 'token-chip selected' : 'token-chip'}
                  onClick={() => addToken(token)}
                  type="button"
                  tabIndex={0}
                >
                  {token.token_id}
                </button>
              </li>
            )
          })}
        </ul>
      )
    }
    return <section />
  }

  return (
    <PickerContainer>
      <h3 className="sub-title">
        {orderType === 'compra'
          ? 'Selecciona un edificio'
          : 'Selecciona los tokens a vender'}
      </h3>

      <section className="buildings-container">
        {orderBuildingId ? (
          <Card interactive onClick={() => setOrderBuildingId('')}>
            <h2>{orderBuildingId}</h2>
          </Card>
        ) : (
          buildings.map((building: any) => (
            <Card
              interactive
              key={building.id}
              onClick={() => {
                setOrderBuildingId(building.phase_id)
                setOrderBuilding(building.name)
              }}
            >
              <h2>{building.name}</h2>
            </Card>
          ))
        )}
      </section>

      {orderBuildingId && (
        <section className="tokens-container">
          <Card>{showAvailableTokens()}</Card>
        </section>
      )}

      {orderType === 'venta' && (
        <section className="tokens-container">
          <Card>{showAvailableTokens()}</Card>
        </section>
      )}
    </PickerContainer>
  )
}

export default TokenPicker
