const setUserToken = (dispatch, user_token) => {
  dispatch({
    type: 'SET_USER_TOKEN',
    payload: user_token
  })
}

const setUserType = (dispatch, user_type) => {
  dispatch({
    type: 'SET_USER_TYPE',
    payload: user_type
  })
}

const setUserEmail = (dispatch, user_email) => {
  dispatch({
    type: 'SET_USER_EMAIL',
    payload: user_email
  })
}
const setUserId = (dispatch, user_id) => {
  dispatch({
    type: 'SET_USER_ID',
    payload: user_id
  })
}

export { setUserToken, setUserEmail, setUserType, setUserId }
