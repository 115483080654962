import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Chart } from 'react-charts'
import moment from 'moment'

const BuyChart = ({ transactions }) => {
  const data = useMemo(() => {
    if (transactions.length) {
      const dates = transactions.map((transaction) =>
        moment(transaction.transaction_date).format('YYYY-MM')
      )

      const datesArray = Array.from(new Set(dates))
      // const sortDates = datesArray.sort();

      const data = datesArray.map((date) => {
        let count = 0
        transactions.forEach((transaction) => {
          if (date === moment(transaction.transaction_date).format('YYYY-MM')) {
            count++
          }
        })
        return [moment(date), count]
      }, [])

      return [
        {
          label: 'Compras de tokens',
          data
        }
      ]
    }
    return [
      {
        label: 'Compras de tokens',
        data: []
      }
    ]
  }, [transactions])

  const axes = useMemo(
    () => [
      {
        primary: true,
        type: 'time',
        position: 'bottom'
      },
      { type: 'linear', position: 'left' }
    ],
    []
  )

  return (
    <ChartWrapper>
      <Chart data={data} axes={axes} tooltip />
    </ChartWrapper>
  )
}

const ChartWrapper = styled.section`
  height: 200px;
`

export default BuyChart
