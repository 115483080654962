import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { Card, Button } from '../../urvita-ui'
import { User } from '../orders/interfaces'
import Modal from '../layout/shared/modal'

// services
import { getUserNotes, newNote } from '../../services/notes'

interface props {
  user: User
}

export const Notes = ({ user }: props): JSX.Element => {
  // Redux hooks
  const userToken = useSelector((state: any) => state.user.token)
  const adminUser = useSelector((state: any) => state.user)

  const [notes, setNotes] = useState([])
  const [notesModal, setNotesModal] = useState(false)
  const [content, setContent] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const requestUserNotes = async (user_id: number): Promise<void> => {
      const userNotes = await getUserNotes(userToken, user_id)
      setNotes(userNotes)
    }
    requestUserNotes(user.id)
  }, [user, loading])

  const toggleNotesModal = (value: boolean): void => {
    setNotesModal(value)
  }

  const saveNote = async (): Promise<void> => {
    setLoading(true)
    await newNote(userToken, {
      content,
      user_id: user.id,
      created_by: adminUser.id
    })
    toggleNotesModal(false)
    setLoading(false)
  }

  return (
    <>
      <Card>
        <h2>Notas</h2>
        <section
          style={{
            overflowY: 'scroll',
            maxHeight: '400px',
            gap: '.5rem',
            display: 'flex',
            flexDirection: 'column-reverse',
            padding: '.5rem'
          }}
        >
          {notes &&
            notes.map((note: any) => (
              <Card key={note.id}>
                <p>{note.content}</p>
                <p style={{ color: 'gray' }}>
                  {moment(note.createdAt).format('DD/MMMM/YYYY')}
                  {' - '}
                  {note.admin_user?.email}
                </p>
              </Card>
            ))}
        </section>
        <section style={{ padding: '1rem' }}>
          <Button
            text="Crear nota"
            onClick={() => {
              toggleNotesModal(true)
            }}
          />
        </section>
      </Card>

      {notesModal && (
        <Modal toggleModal={toggleNotesModal}>
          <h2>Agrega una nota</h2>

          <textarea onChange={(event) => setContent(event.target.value)} />

          <div className="button-container">
            <Button text="Guardar" onClick={() => saveNote()} />
          </div>
        </Modal>
      )}
    </>
  )
}
