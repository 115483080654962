import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

import { colors } from '../constants'

const SectionTitle = ({
  title,
  subtitle,
  action,
  handleClick,
  link,
  linkText
}) => (
  <TitleStyled>
    <h1 className="title">
      {title} <span>{subtitle}</span>
    </h1>
    {action && <button onClick={() => handleClick(true)}>{action}</button>}
    {link && (
      <Link className="link" to={link}>
        {linkText}
      </Link>
    )}
  </TitleStyled>
)

const TitleStyled = styled.header`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;

  .title {
    font-size: 2em;
    letter-spacing: 1px;

    span {
      color: ${colors.gridGray};
      font-size: 0.75em;
    }
  }

  button {
    background-color: ${colors.secondaryColor};
    border: 1px solid ${colors.secondaryColor};
    color: ${colors.darkText};
    cursor: pointer;
    border-radius: 8px;
    font-size: 1em;
    font-weight: 500;
    padding: 10px 15px;
    transition: all 0.2s;
    :hover {
      background-color: ${colors.secondaryDark};
      border: 1px solid ${colors.secondaryDark};
    }
    :active {
      transform: translateY(1px);
    }
  }

  .link {
    color: ${colors.darkText};
    cursor: pointer;
    font-size: 1.2em;
    font-weight: 500;
    transition: all 0.2s;
    :hover {
      color: ${colors.secondaryColor};
    }
  }
`

export default SectionTitle
