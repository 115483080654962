import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import NumberFormat from 'react-number-format'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
// services
import { editUser } from '../../../services/users'

const UserInfo = ({ userInfo, userReport, loading }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)

  // state
  const [taxRegime, setTaxRegime] = useState(userInfo.tax_regime)

  // Sum of all buildings tokens value. (Total user capital)
  const getTotalTokensValue = (buildings) => {
    let totalValue = 0
    if (buildings) {
      buildings.forEach((building) => {
        totalValue += building.userTokensValue
      })
    }
    return totalValue
  }

  const updateTaxRegime = async (value) => {
    setTaxRegime(value)
    try {
      const edited = await editUser(userToken, userInfo.id, {
        tax_regime: value
      })
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <InfoStyled>
      {userInfo && !loading ? (
        <ul className="info-list">
          <li>
            <span className="title">Nombre</span>
            <Link to={`/usuarios/${userInfo.id}`}>
              {userInfo.name} {userInfo.last_name}
            </Link>
          </li>
          <li>
            <span className="title">Email</span>
            <span>{userInfo.email}</span>
          </li>
          {userInfo.intercom_id ? (
            <li>
              <p>Perfil de intercom</p>
              <a
                className="intercom-button"
                href={`https://app.intercom.com/a/apps/otyuzn6u/users/${userInfo.intercom_id}/all-conversations`}
                target="_blank"
                rel="noopener noreferrer"
              >
                ${userInfo.intercom_id}
              </a>
            </li>
          ) : (
            '-'
          )}
          <li>
            <span className="title">RFC</span>
            <span>{userInfo.rfc}</span>
          </li>
          <li>
            <span>Banco</span>
            <span>{userInfo.account_bank}</span>
          </li>
          <li>
            <span>Clabe</span>
            <span>{userInfo.account_clabe}</span>
          </li>
          <li>
            <span className="title">Régimen</span>
            <select
              name="regime"
              value={taxRegime}
              onChange={(event) => updateTaxRegime(event.target.value)}
            >
              <option value="P_FISICA">Física</option>
              <option value="P_MORAL">Moral</option>
              <option value="P_MORAL_EX_ES">Extranjera Moral</option>
            </select>
            <span>{userInfo.tax_regime}</span>
          </li>
          <li>
            <span className="title">Setting de ganancia pasado</span>
            <span>{userInfo.last_rent_setting}</span>
          </li>
          <li>
            <span className="title">Setting de ganancia actual</span>
            <span>{userInfo.rent_setting}</span>
          </li>
          <li>
            <span className="title">Setting de reinversión</span>
            <span>{userInfo.reinvest_setting}</span>
          </li>
          <li>
            <span className="title">Ultimo edificio comprado</span>
            <span>
              {userInfo.last_buyed_building}
              <NumberFormat
                value={Number(userInfo.last_buyed_building_price).toFixed(2)}
                displayType="text"
                thousandSeparator
                prefix=" ($"
                suffix=")"
              />
            </span>
          </li>
        </ul>
      ) : null}
      {userReport && !loading ? (
        <ul className="info-list">
          <li>
            <span className="title">Balance</span>
            <NumberFormat
              value={Number(userInfo.balance).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </li>
          <li>
            <span className="title">Balance Subtotal</span>
            <NumberFormat
              value={Number(userInfo.subtotal_balance).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </li>
          <li>
            <span className="title">Valor de tokens</span>
            <NumberFormat
              value={Number(getTotalTokensValue(userReport.buildings)).toFixed(
                2
              )}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </li>
          <li>
            <span className="title">Escalarotia anualizada</span>
            <NumberFormat
              value={Number(userReport.totalPeriodScale * 100).toFixed(2)}
              displayType="text"
              thousandSeparator
              suffix="%"
            />
          </li>
          <li>
            <span className="title">Aumento del periodo</span>
            <NumberFormat
              value={Number(userReport.totalPeriodValueChange).toFixed(2)}
              displayType="text"
              thousandSeparator
              prefix="$"
            />
          </li>
        </ul>
      ) : null}
    </InfoStyled>
  )
}

const InfoStyled = styled.section`
  .info-list {
    list-style: none;

    li {
      display: grid;
      grid-template-columns: 1fr 2fr;
      margin: 5px 0;
      border-bottom: 1px solid lightgray;
    }

    .title {
      font-weight: 500;
    }
  }
`

export default UserInfo
