import styled from 'styled-components'
import { colors } from '../layout/constants'

const BuildingsStyles = styled.section`
  background-color: ${colors.lightBackground};
  color: ${colors.darkText};
  padding: 15px 0;

  .buildings-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 20px;
    margin: 20px 0;
  }

  @media (max-width: 480px) {
    .buildings-list {
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 10px;
    }
  }
`

const BuildingsCardStyles = styled.li`
  height: 200px;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
  object-fit: fill;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s;

  h2 {
    color: white;
    font-size: 30px;
    font-weight: bold;
    z-index: 2;
  }

  img {
    height: 100%;
    filter: brightness(50%);
    object-fit: cover;
    position: absolute;
    width: 100%;
  }

  :hover {
    cursor: pointer;
    img {
      filter: brightness(80%);
    }
  }

  :active {
    transform: scale(0.95);
  }
`

const InfoCardStyles = styled.section`
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr 3fr;
  width: 100%;

  .image-container {
    width: 100%;
    height: 200px;
    overflow: hidden;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .building-img {
      min-height: 100%;
      width: 100%;
    }
  }

  .list {
    list-style: none;
  }

  .row {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding: 5px;
  }

  .delete-button {
    padding: 5px 10px;
    background-color: red;
    color: white;
    display: block;
    border-radius: 8px;
    width: 80%;
    text-align: center;
    box-sizing: border-box;
    margin: 10px auto;

    :hover {
      cursor: pointer;
      filter: brightness(105%);
    }

    :disabled {
      background-color: lightgray;
      color: black;
      cursor: not-allowed;
    }
  }
`

const ValueRecordStyles = styled.section`
  border-radius: 4px;
  width: 100%;
  margin-bottom: 10px;
  padding: 0 40px;
  padding-bottom: 40px;
  box-sizing: border-box;

  h1 {
    font-size: 30px;
    text-align: center;
    margin: 10px 0;
  }

  .title-item {
    font-weight: 600;
  }

  .list-container {
    list-style: none;
    display: flex;
    flex-direction: column-reverse;
  }

  .record-item {
    padding: 5px;
    border-bottom: 1px solid lightgray;
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    span {
      text-align: center;
    }

    .delete-button {
      color: white;
      background-color: red;
      border-radius: 4px;
      padding: 5px;
      border: 0;
      cursor: pointer;
      transition: all 0.4s;

      :focus {
        outline: none;
      }

      :active {
        transform: translateY(1px);
      }
    }
  }

  .loading-title {
    text-align: center;
    margin-top: 2rem;
  }
`

const NewTokenStyles = styled.section`
  margin: 40px 0;

  .unit-row {
    display: flex;
    margin: 15px 0;
    justify-content: space-between;
    align-items: center;

    label {
      display: block;
    }

    .id-container,
    input,
    select {
      display: inline-block;
      border: 1px solid gray;
      border-radius: 4px;
      display: flex;
      font-size: 14px;
      padding: 2px 5px;

      :focus {
        outline: none;
      }
    }

    h5 {
      width: 70px;
      font-size: 16px;
    }

    .id-input {
      border: none;
      padding: 0;
    }

    .delete-button {
      color: white;
      background-color: red;
      border-radius: 4px;
      padding: 5px;
      border: 0;
      cursor: pointer;
      transition: all 0.4s;

      :focus {
        outline: none;
      }

      :active {
        transform: translateY(1px);
      }
    }
  }
  .submit-button {
    color: white;
    background-color: black;
    border-radius: 4px;
    border: 0;
    font-size: 16px;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.4s;
    margin: 40px 0;
    width: 300px;

    &.add {
      background-color: white;
      border: 1px solid black;
      color: black;
      font-size: 12px;
      width: 150px;
      margin: 10px 0;
    }

    :focus {
      outline: none;
    }

    :active {
      transform: translateY(1px);
    }
  }
`

const NewFormStyles = styled.section`
  form {
    display: grid;
    grid-column-gap: 2em;
    grid-template-columns: 1fr 1fr;
  }

  label {
    display: block;
    margin-bottom: 10px;
    text-align: left;
  }

  input {
    border: 1px solid gray;
    border-radius: 4px;
    display: block;
    font-size: 14px;
    margin-bottom: 20px;
    padding: 2px 5px;

    :focus {
      outline: none;
    }
  }

  .submit-button {
    color: ${colors.darkText};
    background-color: ${colors.secondaryColor};
    border-radius: 8px;
    border: 0;
    font-size: 1em;
    grid-column: 1/3;
    display: inline-block;
    padding: 10px 20px;
    cursor: pointer;
    transition: all 0.4s;
    margin: 20px auto;
    width: 300px;

    :hover {
      background-color: ${colors.secondaryDark};
    }
    :active {
      transform: translateY(1px);
    }
    :focus {
      outline: none;
    }
    :disabled {
      background-color: ${colors.gridGray};
    }
  }
`

export {
  BuildingsStyles,
  BuildingsCardStyles,
  InfoCardStyles,
  ValueRecordStyles,
  NewTokenStyles,
  NewFormStyles
}
