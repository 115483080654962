import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Card, colors, Button } from '../../urvita-ui'
import Modal from '../layout/shared/modal'
import { ExtendedTransaction, Transaction, User } from '../orders/interfaces'
// components
import TransactionRow from './transactionRow'
import NewTransaction from '../users/newTransaction'

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
`
const TransactionsStyled = styled.section`
  .transactions-list {
    list-style: none;
    display: flex;
    flex-direction: column-reverse;
  }

  h4 {
    font-weight: 400;
    font-size: 1.5rem;
    margin: 2rem 0;
    text-align: center;
  }

  li {
    align-items: center;
    display: grid;
    grid-template-columns: 0.5fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1.5fr 2fr 0.5fr;
    grid-column-gap: 20px;
    padding: 5px 0;
    border-bottom: 1px solid ${colors.gridGray};
    text-align: left;

    .left {
      text-align: left;
    }
  }
`

const Transactions = ({
  user,
  transactions,
  handleUpdate
}: {
  user: User
  transactions: Transaction[]
  handleUpdate: any
}): JSX.Element => {
  const [modal, setModal] = useState<boolean>(false)
  const [sortedTransaction, setSortedTransactions] = useState<Transaction[]>([])

  const toggleModal = (value: boolean): void => {
    setModal(value)
  }

  const sortTransactions = (criteria: string): void => {
    const sorted = [...transactions].sort(
      (a: Transaction, b: Transaction): number => {
        if (criteria === 'creationDate') {
          if (a.createdAt && b.createdAt && a.createdAt > b.createdAt) {
            return 1
          }

          if (a.createdAt && b.createdAt && a.createdAt < b.createdAt) {
            return -1
          }
          return 0
        }
        if (
          a.transaction_date &&
          b.transaction_date &&
          a.transaction_date > b.transaction_date
        ) {
          return 1
        }

        if (
          a.transaction_date &&
          b.transaction_date &&
          a.transaction_date < b.transaction_date
        ) {
          return -1
        }
        return 0
      }
    )
    setSortedTransactions(sorted)
  }

  const downloadCSV = async (): Promise<void> => {
    const data = transactions.map((transaction: Transaction) => [
      transaction.createdAt,
      transaction.id,
      transaction.indicator,
      transaction.isr,
      transaction.iva,
      transaction.net_amount,
      transaction.new_user_balance,
      transaction.new_user_subtotal_balance,
      transaction.note,
      transaction.owner,
      transaction.previus_user_balance,
      transaction.previus_user_subtotal_balance,
      transaction.retention,
      transaction.status,
      transaction.stripe_id,
      transaction.subscription_id,
      transaction.subtotal,
      transaction.target_rfc,
      transaction.transaction_date,
      transaction.type,
      transaction.user_id,
      transaction.user_rfc,
      transaction.zigu_id,
      transaction.zigu_po_id
    ])
    data.unshift([
      'createdAt',
      'id',
      'indicator',
      'isr',
      'iva',
      'net_amount',
      'new_user_balance',
      'new_user_subtotal_balance',
      'note',
      'owner',
      'previus_user_balance',
      'previus_user_subtotal_balance',
      'retention',
      'status',
      'stripe_id',
      'subscription_id',
      'subtotal',
      'target_rfc',
      'transaction_date',
      'type',
      'user_id',
      'user_rfc',
      'zigu_id',
      'zigu_po_id'
    ])
    const csvContent = data.map((e: any) => e.join(',')).join('\n')
    const blob = new Blob([csvContent], {
      type: 'data:text/csv;charset=utf-8,'
    })
    const blobURL = window.URL.createObjectURL(blob)

    // Create new tag for download file
    const anchor = document.createElement('a')
    anchor.download = `Transacciones de ${user.name} ${user.last_name}.csv`
    anchor.href = blobURL
    anchor.dataset.downloadurl = [
      'text/csv',
      anchor.download,
      anchor.href
    ].join(':')
    anchor.click()
  }

  useEffect(() => {
    sortTransactions('operationDate')
  }, [transactions])

  return (
    <Card>
      <div>
        {modal && (
          <Modal toggleModal={toggleModal}>
            <NewTransaction
              highlightRfc={user.rfc}
              handleUpdate={() => toggleModal(false)}
              handleUpdateList={handleUpdate}
            />
          </Modal>
        )}
      </div>

      <Header>
        <h2>Movimientos</h2>
        <Button
          text="Ordenar por fecha de registro"
          onClick={() => sortTransactions('creationDate')}
        />
        <Button
          text="Ordenar por fecha de transacción"
          onClick={() => sortTransactions('operationDate')}
        />
        <Button text="Nueva Transacción" onClick={() => toggleModal(true)} />
        <Button text="Descargar" onClick={() => downloadCSV()} />
      </Header>
      <TransactionsStyled>
        <ul className="transactions-list">
          {sortedTransaction ? (
            sortedTransaction.map((transaction: Transaction) => (
              <TransactionRow
                key={transaction.id}
                transaction={transaction}
                handleUpdate={handleUpdate}
              />
            ))
          ) : (
            <h4>Este usuario aun no tiene transacciones</h4>
          )}
          <li>
            <span>id</span>
            <span>Fecha de Operación</span>
            <span>Fecha de Registro</span>
            <span>Operación</span>
            <span>Subtotal</span>
            <span>IVA</span>
            <span>ISR</span>
            <span>Retención</span>
            <span>Neto</span>
            <span>Balance</span>
            <span>Responsable</span>
            <span>Nota</span>
          </li>
        </ul>
      </TransactionsStyled>
    </Card>
  )
}

export default Transactions
