export async function getSubscriptions(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/subscriptions`,
      {
        method: 'GET',
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export async function getLastSubscriptionTransaction(userToken, userId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/zigu/lastSubscription/${userId}`,
      {
        method: 'GET',
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export async function updateSubscription(userToken, subscriptionId, newValues) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/subscriptions/${subscriptionId}`,
      {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(newValues)
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}
