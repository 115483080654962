import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import currency from 'currency.js'
// layout
import { Card, Button, colors } from '../../urvita-ui'

import { Token } from '../orders/interfaces'

const CheckoutContainer = styled.section`
  color: ${colors.white};

  .checkout-user-info {
    margin: 1rem 0;
    list-style: none;
  }

  .checkout-user-item {
    display: flex;
    justify-content: space-between;
    margin: 0.25rem;

    h4 {
      font-weight: 400;
    }

    span {
      font-weight: 500;
      white-space: 1px;
      text-transform: capitalize;
    }
  }

  .checkout-section {
    margin: 1rem 0;
  }

  .checkout-title {
    margin: 1rem 0 0.5rem 0;
  }

  .mono {
    font-family: monospace;
  }

  .token-list {
    padding: 0.5rem 0 0 3rem;
  }

  .checkout-token-price {
    border: none;
    background-color: transparent;
    color: ${colors.white};
    cursor: pointer;
    font-size: 1rem;
  }

  .checkout-button {
    background-color: ${colors.secondaryColor};
    border-radius: 4px;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 2px 5px;
    margin: 0 0 0 0.5rem;
    transition: all 0.4s;

    &:hover {
      border-radius: 4px;
      transform: translateY(-1px);
    }
  }
`

type checkoutData = {
  orderTokens: Token[]
  setOrderValue: (OrderValue: number) => void
  setTokenPrice: (OrderValue: number) => void
  setOrderDate: (OrderValue: string) => void
  sendOrder: () => void
  orderType: string
  orderValue: number
  tokenPrice: number
  orderDate: string
  userRfc: string | undefined
  userName: string | undefined
  orderBuilding: string | undefined
}

const Checkout = ({
  orderTokens,
  setOrderValue,
  sendOrder,
  orderType,
  orderValue,
  setTokenPrice,
  setOrderDate,
  tokenPrice,
  orderDate,
  userRfc,
  userName,
  orderBuilding
}: checkoutData): JSX.Element => {
  const [editTokenPrice, setEditTokenPrice] = useState<boolean>(false)

  useEffect(() => {
    if (orderTokens.length > 0) {
      setTokenPrice(orderTokens[0].current_value)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderTokens])

  const getTotal = (): string => {
    const total = orderTokens.reduce((pot, token): number => {
      const sum =
        Number(pot) + tokenPrice
          ? Number(tokenPrice)
          : Number(token.current_value)
      return sum
    }, 0)
    setOrderValue(total)
    return currency(total).format()
  }

  return (
    <CheckoutContainer>
      <Card backgroundColor={colors.primaryColor}>
        <div>
          <h2>Checkout</h2>

          <ul className="checkout-user-info">
            <li className="checkout-user-item">
              <h4>Fecha de la orden</h4>{' '}
              <span>
                <input
                  type="date"
                  onChange={(event) => setOrderDate(event.target.value)}
                />
              </span>
            </li>
            <li className="checkout-user-item">
              <h4>Usuario:</h4> <span>{userName}</span>
            </li>
            <li className="checkout-user-item">
              <h4>RFC:</h4> <span>{userRfc}</span>
            </li>
          </ul>

          {orderType && (
            <h2 className="checkout-title">Orden de {orderType}</h2>
          )}

          {orderTokens.length > 0 && (
            <ul className="checkout-user-info">
              <li className="checkout-user-item">
                <h4>Edificio:</h4> <span>{orderBuilding}</span>
              </li>
              <li className="checkout-user-item">
                <h4>Precio x token:</h4>{' '}
                {editTokenPrice ? (
                  <span>
                    {' '}
                    <input
                      type="number"
                      onChange={(event) =>
                        setTokenPrice(Number(event.target.value))
                      }
                    />
                    <button
                      className="checkout-button"
                      type="button"
                      onClick={() => setEditTokenPrice(false)}
                    >
                      Guardar
                    </button>
                  </span>
                ) : (
                  <div>
                    <span className="checkout-token-price">
                      {currency(tokenPrice).format()}
                    </span>
                    <button
                      className="checkout-button"
                      onClick={() => setEditTokenPrice(true)}
                      type="button"
                    >
                      Cambiar
                    </button>
                  </div>
                )}
              </li>
            </ul>
          )}

          {orderType === 'compra' || orderType === 'venta' ? (
            <section className="checkout-section">
              <h4>Tokens ({orderTokens.length})</h4>
              <section>
                <ol className="token-list">
                  {orderTokens.map((token: Token) => (
                    <li key={token.id} className="mono">
                      {token.token_id}
                    </li>
                  ))}
                </ol>
              </section>

              <ul className="checkout-user-info">
                <li className="checkout-user-item">
                  <h2>Total:</h2> <h2>{getTotal()}</h2>
                </li>
              </ul>
            </section>
          ) : (
            <section className="checkout-section">
              <h3 className="checkout-title">Subtotal</h3>
              <span className="number">{currency(orderValue).format()}</span>
            </section>
          )}

          <Button
            text="Crear orden"
            onClick={() => sendOrder()}
            disabled={!userRfc || !orderDate || !orderType || !orderValue}
          />
        </div>
      </Card>
    </CheckoutContainer>
  )
}

export default Checkout
