import React from 'react'
import styled from 'styled-components'
// layout
import { colors } from '../layout/constants'

const FilterHeader = ({ buildingList, activeTab, setActiveTab }) => (
  <HeaderStyled>
    <ul className="tab-list">
      {buildingList &&
        buildingList.map((item) => (
          <li
            key={item}
            className={`tab-item ${activeTab === item ? 'active' : null}`}
            onClick={() => setActiveTab(item)}
          >
            {item}
            <div
              className={`tab-indicator ${
                activeTab === item ? 'active' : null
              }`}
            />
          </li>
        ))}
    </ul>
  </HeaderStyled>
)

const HeaderStyled = styled.header`
  margin: 2rem 0;
  .tab-list {
    align-items: center;
    display: flex;
    justify-content: space-around;
    list-style: none;
    padding: 0;
    width: 100%;
  }

  .tab-item {
    color: ${colors.darkText};
    cursor: pointer;
    flex-grow: 1;
    font-size: 1.2rem;
    text-align: center;
    padding: 0 0 1rem 0;
    transition: 0.4s color;

    &.active {
      color: ${colors.highlightColor};
      :hover {
        color: ${colors.highlightColor};
      }
    }

    :hover {
      color: ${colors.secondaryColor};
    }
  }

  .tab-indicator {
    background-color: transparent;
    border-radius: 8px;
    height: 5px;
    margin-top: 1rem;
    width: 100%;

    &.active {
      animation: fill 0.4s ease-out;
      background-color: ${colors.highlightColor};
    }
  }

  @keyframes fill {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }
`

export default FilterHeader
