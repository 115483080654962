import React, { useState, useEffect } from 'react'
import { BrowserRouter, Route, Redirect } from 'react-router-dom'
// Routes
import { useDispatch } from 'react-redux'
import PrivateRoute from './privateRoute'
import PublicRoute from './publicRoute'
// services
import { verifyAdminToken } from '../services/admin'
// Utils
import {
  getToken,
  removeUserSession,
  setUserSession
} from '../utils/sesionManagment'
import {
  setUserToken,
  setUserType,
  setUserEmail,
  setUserId
} from '../state/actions'
// Views
import Login from '../components/login'
import Dashboard from '../components/dashboard/index.tsx'
import Buildings from '../components/buildings'
import Users from '../components/users'
import UserDetail from '../components/userDetail'
import UsersNew from '../components/usersNew'
import Orders from '../components/orders'
import Subscriptions from '../components/subscriptions'
import PaymentPlan from '../components/paymentPlan'
import NewOrder from '../components/orderNew'
import OrderDetail from '../components/orderDetail'
import Tokens from '../components/tokens'
import MonthUpdate from '../components/monthUpdate'
import Settings from '../components/settings'
import TransactionsView from '../components/orders/TransactionsTable'

const App = () => {
  // Redux hooks
  const dispatch = useDispatch()
  const [authLoading, setAuthLoading] = useState(true)

  useEffect(() => {
    const token = getToken()
    if (!token) {
      return
    }

    verificateToken(token)

    // ReactGA.initialize('UA-77732058-3');
  }, [])

  const verificateToken = async (token) => {
    try {
      const verification = await verifyAdminToken(token)
      // Save in session storage
      setUserSession(
        verification.token,
        verification.user.userId,
        verification.user.userType
      )
      // Save in redux state
      setUserToken(dispatch, verification.token)
      setUserType(dispatch, verification.user.userType)
      setUserEmail(dispatch, verification.user.email)
      setUserId(dispatch, verification.user.userId)
      setAuthLoading(false)
    } catch (error) {
      removeUserSession()
      setAuthLoading(false)
    }
  }

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>
  }

  return (
    <BrowserRouter>
      <Route path="/" exact>
        <Redirect to="/login" />
      </Route>

      <PublicRoute path="/login" component={Login} />

      <PrivateRoute path="/dashboard" component={Dashboard} />
      <PrivateRoute path="/edificios" component={Buildings} />

      <PrivateRoute path="/usuariosNew" component={UsersNew} />
      <PrivateRoute path="/usuarios" component={Users} exact />
      <PrivateRoute path="/usuarios/:id" component={UserDetail} />

      <PrivateRoute path="/tokens" component={Tokens} />

      <PrivateRoute path="/ordenes" component={Orders} exact />
      <PrivateRoute path="/nueva-orden" component={NewOrder} exact />
      <PrivateRoute path="/ordenes/:id" component={OrderDetail} exact />

      <PrivateRoute path="/suscripciones" component={Subscriptions} exact />
      <PrivateRoute path="/plan-de-pagos" component={PaymentPlan} exact />

      <PrivateRoute path="/cambio-de-mes" component={MonthUpdate} />

      <PrivateRoute path="/settings" component={Settings} />
    </BrowserRouter>
  )
}

export default App
