import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import NewBuildingTokens from './newBuildingTokens'
import { colors } from '../../layout/constants'
import { Card } from '../../layout/card'

import { updateToken } from '../../../services/tokens'

const TokenListStyles = styled.section`
  border-radius: 4px;
  width: 100%;

  h1 {
    font-size: 30px;
    text-align: center;
    margin: 10px 0;
  }

  .new-tokens {
    padding: 10px;
  }

  .list-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0 40px;
    padding-bottom: 40px;
  }

  .token-chip {
    border: 4px solid transparent;
    padding: 5px;
    border-radius: 4px;
    margin: 3px 4px;
    background-color: ${colors.tokenBlue};
    box-sizing: border-box;
    color: white;
    display: inline-block;
    font-size: 11px;
    cursor: pointer;
    width: 80px;
    overflow: hidden;
    transition: all 0.2s;
    text-align: center;
    :hover {
      filter: brightness(1.2);
    }

    &.token-b {
      background-color: lightgray;
      color: black;
    }

    &.not-available {
      background-color: ${colors.tokenBlueDark};
    }

    &.token-owned {
      background-color: ${colors.secondaryColor};

      &.not-available {
        background-color: ${colors.secondaryDark};
      }
    }

    &.selected {
      border: 4px solid red;
    }
  }

  .color-list {
    display: flex;
    justify-content: space-around;
    margin: 1rem 0;
    list-style: none;

    li {
      display: flex;
      align-items: center;
    }
  }

  .color-indicator {
    width: 1rem;
    height: 1rem;
    border-radius: 100%;
    background-color: ${colors.tokenBlue};
    margin-right: 0.5rem;

    &.token-b {
      background-color: lightgray;
      color: black;
    }

    &.not-available {
      background-color: ${colors.tokenBlueDark};
    }

    &.token-owned {
      background-color: ${colors.secondaryColor};

      &.not-available {
        background-color: ${colors.secondaryDark};
      }
    }
  }

  .status-button {
    position: fixed;
    bottom: 6rem;
    right: 6rem;

    button {
      cursor: pointer;
      color: ${colors.black};
      font-size: 2rem;
      padding: 1rem 2rem;
      border-radius: 8px;
      border: 0;
      background-color: ${colors.secondaryColor};
      box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0);
      box-shadow: 2px 5px 16px 0px #0b325e;

      &:hover {
        filter: brightness(1.05);
      }

      &:active {
        transform: translateY(2px);
      }

      &:disabled {
        background-color: ${colors.gridGray};
      }
    }
  }
`

const TokenList = ({ activeBuilding, userToken, tokenList, getTokens }) => {
  const [selectedTokens, setSelectedTokens] = useState([])
  const [loading, setLoading] = useState(false)

  const tokenClases = (token) => {
    const type =
      token.type === 'TOKEN-A'
        ? token.owner_rfc !== 'URV150302I90'
          ? 'token-chip token-owned'
          : 'token-chip'
        : 'token-chip token-b'
    const available = token.available ? 'available' : 'not-available'
    const isSelected = selectedTokens.includes(token.token_id)
      ? 'selected'
      : 'notSelected'
    return `${type} ${available} ${isSelected}`
  }

  const updateSelection = (tokenId) => {
    // Clean array not linked with state
    const newTokensArray = [...selectedTokens]
    const selected = newTokensArray.find((element) => element === tokenId)
    if (selected) {
      const index = newTokensArray.indexOf(selected)
      newTokensArray.splice(index, 1)
    } else {
      newTokensArray.push(tokenId)
    }
    setSelectedTokens(newTokensArray)
  }

  const openTokensForSale = () => {
    setLoading(true)
    selectedTokens.forEach(async (tokenId) => {
      const completeToken = tokenList.find(
        (element) => element.token_id === tokenId
      )
      const updated = await updateToken(userToken, tokenId, {
        available: !completeToken.available
      })
      await getTokens()
      setLoading(false)
      setSelectedTokens([])
    })
  }

  return (
    <Card>
      <TokenListStyles>
        <h1>Tokens ({tokenList.length})</h1>
        <ul className="color-list">
          <li>
            <span className="color-indicator"></span>
            <p> Tokens a la Venta</p>
          </li>
          <li>
            <span className="color-indicator not-available"></span>
            <p> Tokens bloqueados</p>
          </li>
          <li>
            <span className="color-indicator token-owned  not-available"></span>
            <p> Tokens de usuarios</p>
          </li>
          <li>
            <span className="color-indicator token-owned"></span>
            <p> Tokens de usuario a la venta</p>
          </li>
          <li>
            <span className="color-indicator token-b"></span>
            <p> Tokens tipo B</p>
          </li>
        </ul>
        {tokenList.length > 0 ? (
          <ul className="list-container">
            {tokenList.map((token) => (
              <li
                key={token.token_id}
                className={tokenClases(token)}
                onClick={() => updateSelection(token.token_id)}
              >
                {token.token_id.substring(7)}
              </li>
            ))}
          </ul>
        ) : (
          <section className="new-tokens">
            <NewBuildingTokens
              buildingId={activeBuilding.phase_id}
              userToken={userToken}
            />
          </section>
        )}

        {selectedTokens.length > 0 && (
          <article className="status-button">
            <button onClick={() => openTokensForSale()}>
              {loading
                ? 'Procesando...'
                : `Habilitar (${selectedTokens.length})`}
            </button>
          </article>
        )}
      </TokenListStyles>
    </Card>
  )
}

export default TokenList
