async function createOrder(userToken, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(`${process.env.REACT_APP_API_URL}/api/orders`, {
    method: 'POST',
    headers: myHeaders,
    redirect: 'follow',
    body: JSON.stringify(body)
  }).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

async function getOrders(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders`,
      {
        method: 'GET',
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function getSingleOrder(userToken, id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/${id}`,
      {
        method: 'GET',
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function updateOrder(userToken, orderId, orderValues) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/${orderId}`,
      {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify(orderValues)
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function deleteOrder(userToken, order_id) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/${order_id}`,
      {
        method: 'DELETE',
        headers: myHeaders
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

async function updateChecklist(userToken, order_id, step_id, new_status) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/orders/${order_id}/${step_id}`,
      {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({ new_status: new_status })
      }
    )

    const data = await response.json()
    return data
  } catch (error) {
    console.error(error)
    return error
  }
}

export {
  createOrder,
  getSingleOrder,
  getOrders,
  updateOrder,
  deleteOrder,
  updateChecklist
}
