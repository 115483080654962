import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { User, Transaction } from '../orders/interfaces'
// layout
import DefaultLayout from '../layout'
import { Card } from '../../urvita-ui'
// components
import BasicInfo from './basicInfo'
import InvestmentSummary from './investmentSummary'
import Transactions from './transactions'
import { Notes } from './notes'
// services
import { getSingleUser } from '../../services/users'
import { getReport } from '../../services/reports'
import { getUserTransactions } from '../../services/transactions'
import UserInfo from './userInfo'
import DocumentsList from './documentsList'

const UsersContainer = styled.section`
  padding: 1rem 2rem;

  .title {
    font-size: 2rem;
  }

  .user-details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
    margin: 2rem 0;
  }

  .user-info {
    list-style: none;
    padding: 0;
    margin: 1rem 0;

    li {
      font-size: 1.2rem;
    }
  }

  .section {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .row {
    display: flex;
    gap: 1rem;

    &.mid-section {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
  }
`

interface ParamTypes {
  id: string
}

const Users = (): JSX.Element => {
  // Redux hooks
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const { id } = useParams<ParamTypes>()
  const [user, setUser] = useState<User>()
  const [tokens, setTokens] = useState<any>()
  const [tokenValues, setTokensValues] = useState<any>()
  const [transactions, setTransactions] = useState<Transaction[]>([])

  const getUser = async (): Promise<void> => {
    const result = await getSingleUser(userToken, id)
    const report = await getReport(userToken, id)
    const trans = await getUserTransactions(userToken, result.rfc)
    setUser(result)
    setTokens(report.tokens)
    setTokensValues(report?.tokenValueRecord)
    setTransactions(trans)
  }

  useEffect(() => {
    getUser()
  }, [userToken, id])

  const updateTransactions = async (): Promise<void> => {
    if (user) {
      const trans = await getUserTransactions(userToken, user.rfc)
      setTransactions(trans)
    }
  }

  const handleUpdate = (): void => {
    updateTransactions()
  }

  return (
    <DefaultLayout sidenav>
      <UsersContainer>
        <h2 className="title">Detalles del Usuario</h2>

        <section className="user-details">
          <section className="section">
            <Card>{user && <BasicInfo user={user} getUser={getUser} />}</Card>
            <Card>{user && <DocumentsList user={user} />}</Card>
          </section>
          {/* user && <IntercomBlock user={user} /> */}
          <Card>{user && <UserInfo setUser={setUser} user={user} />}</Card>
        </section>

        {user && (
          <section className="row user-details mid-section">
            <InvestmentSummary
              user={user}
              tokens={tokens}
              tokenValues={tokenValues}
            />
            <Notes user={user} />
          </section>
        )}

        {/* <section className="user-details">
          {user && <MonthlyAport user={user} />}
        </section> */}
        {user && (
          <Transactions
            user={user}
            transactions={transactions}
            handleUpdate={handleUpdate}
          />
        )}
      </UsersContainer>
    </DefaultLayout>
  )
}

export default Users
