import styled from 'styled-components'
import { colors } from '../layout/constants'

export const DashboardContainer = styled.section`
  margin: 0 auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 2rem;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 0 1rem;
  }

  .month-select {
    list-style: none;
    display: flex;
    gap: 1rem;
    justify-content: center;
    margin: 4rem 0 2rem 0;
    flex-wrap: wrap;

    button {
      cursor: pointer;
      padding: 5px 10px;

      &.active {
        background-color: ${colors.secondaryDark};
      }
    }
  }

  .summary-container {
    text-align: center;
    margin: 2rem 0;

    div {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      margin: 2rem 0;

      article {
        min-width: 280px;
        margin-bottom: 1rem;
      }
    }
  }

  .list-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .list-article {
    margin: 2rem 0;
  }

  .list-title {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
`
