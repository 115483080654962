import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import moment from 'moment'
import { colors, layoutValues } from '../layout/constants'
// layout
import DefaultLayout from '../layout'
import Button from '../layout/shared/button'
// Components
import SearchHeader from '../layout/shared/searchHeader'
import OrdersHighlight from './ordersHighlight'
import OrdersList from './ordersList'
import TransactionsView from './TransactionsTable'
// services
import { getOrders } from '../../services/orders'
import { getUsers } from '../../services/users'
// types
import { User, ExtendedOrder } from './interfaces'

const OrdersContainer = styled.section`
  padding: calc(52px + 1rem) 2rem 1rem 2rem;

  h1 {
    font-size: 40px;
  }
`
const Title = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const TableTabs = styled.div`
  display: flex;
  border-top-left-radius: ${layoutValues.cardRadius};
  overflow: hidden;

  button {
    background-color: white;
    cursor: pointer;
    padding: 0.5rem;
    border: none;
    box-shadow: ${layoutValues.cardShadow};

    &.active {
      background-color: ${colors.secondaryColor};
    }
  }
`

const Orders = (): JSX.Element => {
  // Redux hooks
  const history = useHistory()
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )

  type Filter = {
    type: string
    value: string | number
  }

  const [orders, setOrders] = useState<ExtendedOrder[]>([])
  const [activeTab, setActiveTab] = useState('orders')
  const [searchFilter, setSearchFilter] = useState<string>('')
  const [filter, setFilter] = useState<Filter>({ type: 'all', value: '' })
  const [sort, setSort] = useState<Filter>({ type: 'date', value: '' })
  const [filteredOrders, setFilteredOrders] = useState<ExtendedOrder[]>([])
  const [sortedOrders, setSortedOrders] = useState<ExtendedOrder[]>([])

  useEffect(() => {
    // Add name and email to order for easier data managment
    const ordersList = async (): Promise<void> => {
      const { data: ordersResult } = await getOrders(userToken)
      const { data: usersResult } = await getUsers(userToken)

      ordersResult.map((order: ExtendedOrder) => {
        const orderUser = usersResult.find(
          (user: User) => user.id === order.user_id
        )
        // eslint-disable-next-line no-param-reassign
        order.user_name = `${orderUser.name} ${orderUser.last_name}`
        // eslint-disable-next-line no-param-reassign
        order.user_email = orderUser.email
        if (order.order_status === 'open') {
          // eslint-disable-next-line no-param-reassign
          order.order_type = 'compra'
        }

        return order
      })

      const openOrders = ordersResult.filter(
        (order: ExtendedOrder) =>
          order.order_status !== 'DRAFT' && order.order_status !== 'cancelada'
      )

      const OrdersWithTokens = openOrders.filter((order: ExtendedOrder) => {
        if (order.order_status === 'open') {
          return order.total_tokens_count >= 1
        }
        return true
      })

      setOrders(OrdersWithTokens)
    }
    ordersList()
  }, [userToken])

  useMemo(() => {
    // Search by name or email with searchbox
    if (orders) {
      const filterResult = orders.filter((order: ExtendedOrder) => {
        const fullName = order.user_name.toLocaleLowerCase().trim()
        const email = order.user_email.toLocaleLowerCase().trim()
        return (
          fullName.includes(searchFilter.toLocaleLowerCase().trim()) ||
          email.includes(searchFilter.toLocaleLowerCase().trim())
        )
      })
      setFilteredOrders(filterResult)
    }
  }, [orders, searchFilter])

  useMemo(() => {
    // Filter base on segments
    const filtered = orders.filter((order) => {
      const orderDate = moment(order.createdAt)
      const filterDate = moment(filter.value)

      switch (filter.type) {
        case 'active':
          return (
            order.order_status !== 'DRAFT' &&
            order.order_status !== 'PAID' &&
            order.order_status !== 'completada' &&
            order.order_status !== 'pagada' &&
            order.order_status !== 'cancelada'
          )
        case 'unpaid':
          return (
            order.order_status === 'pendiente de pago' ||
            order.order_status === 'por pagar'
          )
        case 'closed':
          return (
            order.order_status === 'completada' ||
            order.order_status === 'pagada'
          )
        case 'antes de':
          return orderDate.isSameOrBefore(filterDate)
        case 'después de':
          return orderDate.isSameOrAfter(filterDate)
        case 'el día':
          return orderDate.isSame(filterDate)
        default:
          return order.order_status !== 'DRAFT' && order.order_status !== 'PAID'
      }
    })
    setFilteredOrders(filtered)
  }, [orders, filter])

  useMemo(() => {
    // Sort
    const sorted = filteredOrders.sort((a, b) => {
      const aDate = moment(a.updatedAt)
      const bDate = moment(b.updatedAt)
      switch (sort.type) {
        case 'subtotal':
          if (Number(a.subtotal_value) > Number(b.subtotal_value)) {
            return 1
          }
          if (Number(a.subtotal_value) < Number(b.subtotal_value)) {
            return -1
          }
          return 0
        case 'date':
          if (aDate.isSameOrBefore(bDate)) {
            return -1
          }
          if (aDate.isSameOrAfter(bDate)) {
            return 1
          }
          return 0
        default:
          if (a.id < b.id) {
            return -1
          }
          return 0
      }
    })

    setSortedOrders(sorted)
  }, [filteredOrders, sort])

  const newOrder = (): void => {
    history.push('/nueva-orden')
  }

  return (
    <DefaultLayout sidenav>
      <SearchHeader setSearchFilter={setSearchFilter} />
      <OrdersContainer>
        <Title>
          <h1>Ordenes</h1>
          <Button
            text="Nueva Orden"
            clickHandler={() => newOrder()}
            disabled={false}
            small={false}
            danger={false}
          />
        </Title>
        <OrdersHighlight
          filter={filter}
          setFilter={setFilter}
          count={filteredOrders.length}
          orders={orders}
        />

        <TableTabs>
          <button
            className={activeTab === 'orders' ? 'active' : ''}
            onClick={() => setActiveTab('orders')}
            type="button"
          >
            Todas las Ordenes
          </button>
          <button
            className={activeTab === 'sells' ? 'active' : ''}
            onClick={() => setActiveTab('sells')}
            type="button"
          >
            Ordenes de Venta
          </button>
          <button
            className={activeTab === 'simulations' ? 'active' : ''}
            onClick={() => setActiveTab('simulations')}
            type="button"
          >
            Simulaciones
          </button>
          <button
            className={activeTab === 'transactions' ? 'active' : ''}
            onClick={() => setActiveTab('transactions')}
            type="button"
          >
            Transacciones
          </button>
        </TableTabs>
        {orders && activeTab === 'orders' && (
          <OrdersList
            orders={sortedOrders}
            setSort={setSort}
            simulations={false}
            sells={false}
          />
        )}
        {orders && activeTab === 'sells' && (
          <OrdersList
            orders={sortedOrders}
            setSort={setSort}
            simulations={false}
            sells
          />
        )}
        {activeTab === 'transactions' && <TransactionsView />}
        {activeTab === 'simulations' && (
          <OrdersList
            orders={sortedOrders}
            setSort={setSort}
            simulations
            sells={false}
          />
        )}
      </OrdersContainer>
    </DefaultLayout>
  )
}

export default Orders
