import React, { useEffect, useState } from 'react'
import { Switch, Route } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getBuildings } from '../../services/buildings'
// Components
import DefaultLayout from '../layout'
import BuildingsView from './buildingsOverview'
import BuildingDashboard from './buildingDashboard'

const Buildings = () => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)

  const [buildingsList, setBuildingsList] = useState([])

  useEffect(() => {
    getBuildingsList()
  }, [])

  const getBuildingsList = async () => {
    if (userToken) {
      try {
        const list = await getBuildings(userToken)

        setBuildingsList(list.data)
      } catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <DefaultLayout sidenav>
      <Switch>
        <Route path="/edificios" exact>
          <BuildingsView
            userToken={userToken}
            buildingsList={buildingsList}
            updateBuildings={getBuildingsList}
          />
        </Route>

        {buildingsList &&
          buildingsList.map((building) => (
            <Route
              key={building.phase_id}
              path={`/edificios/${building.phase_id}`}
            >
              <BuildingDashboard
                userToken={userToken}
                activeBuilding={building}
              />
            </Route>
          ))}
      </Switch>
    </DefaultLayout>
  )
}

export default Buildings
