import React from 'react'
import styled from 'styled-components'
import { Transaction } from './types'
import { UrvitaTable } from './urvitaTable'
import { filterTransactions } from './utils'
import { downloadTransactions } from '../../utils/downloadCSV'
import { colors } from '../../urvita-ui/constants'

interface TransactionsListProps {
  transactions: Transaction[]
}

const DownloadButton = styled.button`
  background-color: ${colors.primaryColor};
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #45a049;
  }
`

const TransactionsList: React.FC<TransactionsListProps> = ({
  transactions
}) => (
  <section className="list-container">
    {transactions && (
      <>
        <article className="list-article">
          <div className="list-title">
            <h2>
              Compras de Tokens (
              {filterTransactions(transactions, 'CXC_VTA_TOKENS').length})
            </h2>
            <DownloadButton
              type="button"
              onClick={() =>
                downloadTransactions(
                  filterTransactions(transactions, 'CXC_VTA_TOKENS')
                )
              }
            >
              Descargar
            </DownloadButton>
          </div>
          <UrvitaTable
            transactions={filterTransactions(transactions, 'CXC_VTA_TOKENS')}
          />
        </article>
        <article className="list-article">
          <div className="list-title">
            <h2>
              Suscripciones (
              {
                filterTransactions(transactions, 'COBRANZA_DEPOSITO_RECURRENTE')
                  .length
              }
              )
            </h2>
            <DownloadButton
              type="button"
              onClick={() =>
                downloadTransactions(
                  filterTransactions(
                    transactions,
                    'COBRANZA_DEPOSITO_RECURRENTE'
                  )
                )
              }
            >
              Descargar
            </DownloadButton>
          </div>
          <UrvitaTable
            transactions={filterTransactions(
              transactions,
              'COBRANZA_DEPOSITO_RECURRENTE'
            )}
          />
        </article>
        <article className="list-article">
          <div className="list-title">
            <h2>
              Abonos (
              {filterTransactions(transactions, 'COBRANZA_DEPOSITO').length})
            </h2>
            <DownloadButton
              type="button"
              onClick={() =>
                downloadTransactions(
                  filterTransactions(transactions, 'COBRANZA_DEPOSITO')
                )
              }
            >
              Descargar
            </DownloadButton>
          </div>
          <UrvitaTable
            transactions={filterTransactions(transactions, 'COBRANZA_DEPOSITO')}
          />
        </article>
        <article className="list-article">
          <div className="list-title">
            <h2>
              Ganancias (
              {filterTransactions(transactions, 'CXP_REINVERTIR').length})
            </h2>
            <DownloadButton
              type="button"
              onClick={() =>
                downloadTransactions(
                  filterTransactions(transactions, 'CXP_REINVERTIR')
                )
              }
            >
              Descargar
            </DownloadButton>
          </div>
          <UrvitaTable
            transactions={filterTransactions(transactions, 'CXP_REINVERTIR')}
          />
        </article>
      </>
    )}
  </section>
)

export default TransactionsList
