import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// layout
import DefaultLayout from '../layout'
// Components
import BasicTable from './table'
// services
import { getPaymentPlans } from '../../services/paymentPlan'
// interfaces
// types
import { Plan } from '../../interfaces'

// styles
const Container = styled.section`
  padding: calc(52px + 1rem) 2rem 1rem 2rem;
`

const Title = styled.h1`
  font-size: 40px;
  margin-bottom: 1em;
  width: 100%;
`

const PaymentPlan = (): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const [plans, setPlans] = useState<Plan[]>([])

  useEffect(() => {
    const plansList = async (): Promise<void> => {
      const { data: plansResult } = await getPaymentPlans(userToken)
      setPlans(plansResult)
    }
    plansList()
  }, [userToken])

  return (
    <DefaultLayout sidenav>
      <Container>
        <Title>Plan de pagos</Title>
        <BasicTable setPlan={setPlans} plans={plans} />
      </Container>
    </DefaultLayout>
  )
}

export default PaymentPlan
