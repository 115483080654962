import React from 'react'
import { useSelector } from 'react-redux'
import DefaultLayout from '../layout'
import { DashboardContainer } from './styles'
import { useDashboardData } from './useDashboardData'
import PeriodSelector from './periodSelector'
import SummarySection from './summarySection'
import { BuildingMetrics } from '../buildings/buildingDashboard/buildingMetrics'
import TransactionsList from './transactionsList'

const Dashboard = (): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const { activePeriod, setActivePeriod, transactions, users, loading, error } =
    useDashboardData(userToken)

  return (
    <DefaultLayout sidenav>
      {error && <div>Error: {error}</div>}

      {!error && (
        <DashboardContainer>
          <PeriodSelector
            activePeriod={activePeriod}
            setActivePeriod={setActivePeriod}
          />
          <SummarySection
            transactions={transactions}
            users={users}
            activePeriod={activePeriod}
            loading={loading}
          />
          <BuildingMetrics userToken={userToken} />
          <TransactionsList transactions={transactions} />
        </DashboardContainer>
      )}
    </DefaultLayout>
  )
}

export default Dashboard
