async function getBuildingPeriodRecord(userToken, phase_id, period) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/track_records/period/${phase_id}/${period}`,
    {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data.data
}

async function newTrackRecord(userToken, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/track_records/`,
    {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => console.error(err))
  const data = await response.json()
  return data
}

export { getBuildingPeriodRecord, newTrackRecord }
