import React from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import { BuildingsCardStyles } from '../styles'

import pascualImg from '../../../assets/img/buildings/pascual.png'
import micaelaImg from '../../../assets/img/buildings/micaela.jpg'
import noeImg from '../../../assets/img/buildings/noe.jpg'
import victoriaImg from '../../../assets/img/buildings/victoria.jpg'
import hernanImg from '../../../assets/img/buildings/hernan.jpeg'
import evaImg from '../../../assets/img/buildings/eva.jpg'
import juanitoImg from '../../../assets/img/buildings/juanito.jpg'
import genericImg from '../../../assets/img/buildings/generic.jpg'

const BuildingCard = (props) => {
  const { name, phase_id } = props

  const { url } = useRouteMatch()

  const nameLower = name.toLowerCase()

  let image = ''

  switch (nameLower) {
    case 'micaela':
      image = micaelaImg
      break
    case 'noe ':
      image = noeImg
      break
    case 'pascual':
      image = pascualImg
      break
    case 'victoria':
      image = victoriaImg
      break
    case 'hernan':
      image = hernanImg
      break
    case 'eva':
      image = evaImg
      break
    case 'juanito':
      image = juanitoImg
      break
    default:
      image = genericImg
      break
  }

  const capitalName = (name) => {
    const firstLetter = name[0].toUpperCase()
    const lastLetters = name
      .split('')
      .splice(1, name.length - 1)
      .join('')
      .toLowerCase()
    return firstLetter + lastLetters
  }

  return (
    <Link to={`${url}/${phase_id}`}>
      <BuildingsCardStyles>
        <img src={image} alt={name} />
        <h2>{capitalName(name)}</h2>
      </BuildingsCardStyles>
    </Link>
  )
}

export default BuildingCard
