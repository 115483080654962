const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER_TOKEN':
      return {
        user: {
          ...state.user,
          token: action.payload
        }
      }
    case 'SET_USER_TYPE':
      return {
        user: {
          ...state.user,
          type: action.payload
        }
      }
    case 'SET_USER_EMAIL':
      return {
        user: {
          ...state.user,
          email: action.payload
        }
      }
    case 'SET_USER_ID':
      return {
        user: {
          ...state.user,
          id: action.payload
        }
      }
    case 'LOGOUT':
      return {
        user: {}
      }
    default:
      return state
  }
}

export default reducer
