import React from 'react'
import styled from 'styled-components'
import currency from 'currency.js'
import { Card, colors } from '../../urvita-ui'
import { User } from '../orders/interfaces'

const SummaryContainer = styled.section`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 1rem;
  text-align: center;

  article:nth-child(3) {
    grid-column: 1/3;
  }

  .number {
    font-size: 2rem;
    color: ${colors.darkText};
    font-weight: 500;
    letter-spacing: 1px;

    &.total {
      font-size: 2.4rem;
    }
  }

  .token-chip {
    padding: 5px;
    border-radius: 4px;
    margin: 3px;
    background-color: #4765ff;
    color: white;
    display: inline-block;
    font-size: 11px;
    cursor: pointer;
    width: 100px;
    overflow: hidden;
    transition: all 0.6s;
    text-align: center;
    :hover {
      filter: brightness(1.2);
    }
  }
`

interface TokenValue {
  building: string
  period: string
  singleValue: number
  tokens: number
  totalValue: number
}

interface Token {
  available: boolean
  building_phase_id: string
  current_value: number
  id: number
  owner_id: number
  owner_rfc: string
  token_cost: number
  token_id: string
  type: string
}

interface Props {
  user: User
  tokens: Token[]
  tokenValues: TokenValue[]
}

const InvestmentSummary = ({
  user,
  tokens,
  tokenValues
}: Props): JSX.Element => {
  const getLatestValue = (
    values: TokenValue[],
    building_phase_id: string
  ): number => {
    const filteredValues = values.filter(
      (value: TokenValue) => value.building === building_phase_id
    )

    const sortedValues = filteredValues.sort((a: TokenValue, b: TokenValue) => {
      if (a.period > b.period) {
        return -1
      }
      if (a.period < b.period) {
        return 1
      }
      return 0
    })

    return Number(sortedValues[0]?.singleValue)
  }

  const getTotalValue = (): string => {
    const totalTokensValue = tokens.reduce(
      (count: number, token: Token): number => {
        const tokenValue =
          getLatestValue(tokenValues, token.building_phase_id) || 0
        return count + Number(tokenValue)
      },
      0
    )
    return currency(Number(user.balance) + totalTokensValue).format()
  }

  const tokensList = (list: Token[]): JSX.Element => (
    <>
      {list?.map(
        (token: Token): JSX.Element => (
          <li key={token.id} className="token-chip">
            {token.token_id}
          </li>
        )
      )}
    </>
  )

  return (
    <SummaryContainer>
      <Card>
        {tokenValues ? (
          <p className="number total">{getTotalValue()}</p>
        ) : (
          <p className="number total">
            {currency(user.balance ? user.balance : 0).format()}
          </p>
        )}
        <p>Valor Total</p>
      </Card>
      <Card>
        {user && (
          <p className="number">
            {user.balance ? currency(user.balance).format() : 0}
          </p>
        )}
        <p>Balance</p>
      </Card>
      <Card>
        {tokens && <p className="number">{tokens.length}</p>}
        <p>Tokens</p>
        {tokensList(tokens)}
      </Card>
    </SummaryContainer>
  )
}

export default InvestmentSummary
