const colors = {
  black: '#050714',
  white: '#fff',
  tokenBlue: '#1633F4',
  gridGray: '#909090',
  gridSecondary: '#eaeaea',

  lightBackground: '#FFFAF7',
  darkBackground: '#616B63',

  primaryColor: '#546B5A',
  primaryDark: '#252D29',
  primaryLight: '#65796A',

  secondaryColor: '#FBCFA4',
  secondaryDark: '#FAB16B',

  highlightColor: '#F9A14D',

  darkText: '#38493C',
  lightText: '#fecfa4',
  warningColor: 'darkred'
}

const layoutValues = {
  cardRadius: '16px',
  cardShadow: '1px 2px 4px 1px lightgrey',
  cardHighlightShadow: '1px 4px 5px 2px lightgrey'
}

export { colors, layoutValues }
