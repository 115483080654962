import React, { useState } from 'react'
import styled from 'styled-components'
// layout
import { UrvitaInput, UrvitaSubmit } from '../layout/form'

const Login = ({ loginHandler, loading, error }) => {
  const [mail, setMail] = useState('')
  const [password, setPassword] = useState('')

  const submitHandler = async (event) => {
    event.preventDefault()
    // Send user credentials to service
    const userCredentials = {
      email: mail,
      password
    }

    loginHandler(userCredentials)
  }

  return (
    <LoginStyles>
      <form className="login-form" onSubmit={submitHandler}>
        <UrvitaInput
          labelText="Correo electrónico"
          autoComplete="email"
          name="mail"
          type="text"
          onChange={(event) => setMail(event.target.value)}
          value={mail}
        />
        <UrvitaInput
          labelText="Contraseña"
          autoComplete="current-pasword"
          name="password"
          type="password"
          onChange={(event) => setPassword(event.target.value)}
          value={password}
        />
        <UrvitaSubmit text="Iniciar sesión" loading={loading} error={error} />
      </form>
    </LoginStyles>
  )
}

const LoginStyles = styled.section`
  .login-form {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    padding: 1rem;
    max-width: 300px;

    .login-input {
      width: 100%;
    }
  }
`

export default Login
