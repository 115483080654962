import React from 'react'
import currency from 'currency.js'
import styled from 'styled-components'
import { ExtendedToken } from '../orders/interfaces'

const Wrapper = styled.div`
  .token-detail {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 1rem;
    justify-content: right;
  }

  .building-name {
    margin-bottom: 0.8rem;
  }

  .token-info {
    font-size: 0.9rem;
  }

  .order-price {
    text-align: right;
  }
`

type filtered = {
  filteredTokens: ExtendedToken[]
  building: string
}

const ListByBuilding = ({
  filteredTokens,
  building
}: filtered): JSX.Element | null => {
  if (filteredTokens.length < 1) {
    return null
  }

  return (
    <Wrapper>
      <div className="token-detail-heading">
        <h4>
          Tokens de {building} x {filteredTokens.length}
        </h4>
        <h4>Precio actual</h4>
        <h4 className="order-price-heading">Precio de transacción</h4>
      </div>
      {filteredTokens.map((token) => {
        const { token_id, current_value, order_token } = token
        return (
          <article className="token-detail" key={token_id}>
            <span className="token-info">{token_id}</span>
            <span>{currency(current_value).format()}</span>
            <span className="order-price">
              {currency(order_token.token_transaction_price).format()}
            </span>
          </article>
        )
      })}
    </Wrapper>
  )
}

export default ListByBuilding
