import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors, layoutValues } from '../layout/constants'
// types
import { ExtendedUser, ExtendedUserList, UserList } from '../orders/interfaces'
// Components
import UserRow from './userRow'

const ListContainer = styled.section`
  background-color: white;
  border-radius: ${layoutValues.cardRadius};
  box-shadow: ${layoutValues.cardShadow};
  margin: 2rem 0;
  padding: 1rem;
`

const ListHeader = styled.header`
  border-top: 1px solid gray;
  border-bottom: 1px solid gray;
  color: gray;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  display: grid;
  grid-template-columns: 1fr 2fr repeat(6, 1fr);

  .center {
    text-align: center;
  }

  .right {
    text-align: right;
  }
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  padding: 0;

  li {
    display: grid;
    grid-template-columns: 1fr 2fr repeat(6, 1fr);
  }

  .center {
    text-align: center;
  }
`

const Pagination = styled.section`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;

  p {
    margin: 0 1rem;
  }

  button {
    background-color: ${colors.secondaryColor};
    border-radius: 8px;
    cursor: pointer;
    padding: 0.5rem;
    border: none;
  }
`

const UsersList = ({ users }: { users: ExtendedUser[] }): JSX.Element => {
  const [page, setPage] = useState<number>(1)
  const [pageCount, setPageCount] = useState(20)
  const [usersInPage, setUsersInPage] = useState<ExtendedUser[]>([])

  useEffect(() => {
    if (users) {
      setUsersInPage(users.slice(0, 20))
      setPage(1)
      setPageCount(20)
    }
  }, [users])

  const nextPage = (): void => {
    const count = 20 * (page + 1)
    if (count < users.length + 20) {
      if (users.length < count) {
        setPageCount(users.length)
      } else {
        setPageCount(count)
      }
      setPage(page + 1)
      setUsersInPage(users.slice(count - 20, count))
    }
  }

  const prevPage = (): void => {
    const count = 20 * (page - 1)
    if (count > 0) {
      setPageCount(count)
      setPage(page - 1)
      setUsersInPage(users.slice(count - 20, count))
    }
  }

  return (
    <ListContainer>
      <Pagination>
        <button type="button" onClick={() => prevPage()}>
          previews
        </button>
        <p>
          {20 * page - 19} a {pageCount}
        </p>
        <button type="button" onClick={() => nextPage()}>
          Next
        </button>
      </Pagination>
      <ListHeader>
        <span className="center">External Id</span>
        <span>Usuario</span>
        <span className="center">Creado</span>
        <span className="center">Verificado</span>
        <span className="center">Contrato</span>
        <span className="center">Tokens</span>
        <span className="center">Regimen</span>
        <span className="center">Balance</span>
      </ListHeader>
      <List>
        {usersInPage.map((user: ExtendedUser) => (
          <UserRow key={user.id} user={user} />
        ))}
      </List>
    </ListContainer>
  )
}

export default UsersList
