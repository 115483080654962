export const downloadTransactions = (transactionsList) => {
  const formattedList = transactionsList.map((transaction) => {
    const userName = `${transaction.user.name} ${transaction.user.last_name}`

    return [
      transaction.user.id,
      userName,
      transaction.user.rfc,
      transaction.user.email,
      transaction.transaction_date,
      transaction.type,
      Number(transaction.subtotal).toFixed(2),
      Number(transaction.iva).toFixed(2),
      Number(transaction.isr).toFixed(2),
      Number(transaction.retention).toFixed(2),
      Number(transaction.net_amount).toFixed(2)
    ]
  })

  formattedList.unshift([
    'id',
    'Nombre',
    'RFC',
    'Email',
    'Fecha',
    'Tipo',
    'Subtotal',
    'iva',
    'isr',
    'retención',
    'neto'
  ])

  const csvContent = `data:text/csv;charset=utf-8,${formattedList
    .map((e) => e.join(','))
    .join('\n')}`

  const encodedUri = encodeURI(csvContent)
  window.open(encodedUri)
}
