import React, { useState } from 'react'
import { NewTokenStyles } from '../styles'
import { createToken } from '../../../services/tokens'

const NewBuildingTokens = ({ buildingId, userToken }) => {
  const [unitList, setUnitList] = useState([])

  const addUnit = () => {
    setUnitList([...unitList, {}])
  }

  const handleChange = (event, index) => {
    const input = event.target
    unitList[index][input.name] = input.value.toUpperCase()
    setUnitList([...unitList])
  }

  const handleRemove = (index) => {
    unitList.splice(index, 1)
    setUnitList([...unitList])
  }

  const createTokensHandler = (event) => {
    event.preventDefault()
    unitList.forEach((unit) => {
      for (let i = 1; i <= unit.tokens; i++) {
        const tokenId =
          buildingId + unit.id + 'T0000'.slice(0, 5 - i.toString().length) + i

        const newToken = {
          token_id: tokenId.trim(),
          owner_id: 1,
          type: unit.type.trim()
        }
        createToken(userToken, newToken)
      }
    })
  }

  return (
    <NewTokenStyles>
      <h2>Unidades</h2>
      <button className="submit-button add" onClick={() => addUnit()}>
        Agregar unidad +
      </button>
      <form onSubmit={createTokensHandler}>
        <ul>
          {unitList.map((input, index) => (
            <li key={`unit-${index}`} className="unit-row">
              <div>
                <label>Id</label>
                <span className="id-container">
                  <h5>{buildingId}</h5>
                  <input
                    className="id-input"
                    name="id"
                    type="text"
                    value={input.id || ''}
                    placeholder="U00X"
                    onChange={(event) => handleChange(event, index)}
                  />
                </span>
              </div>
              <div>
                <label>Tokens</label>
                <input
                  name="tokens"
                  type="number"
                  value={input.tokens || ''}
                  onChange={(event) => handleChange(event, index)}
                />
              </div>
              <div>
                <label>Tipo</label>
                <select
                  name="type"
                  value={input.type || ''}
                  onChange={(event) => handleChange(event, index)}
                >
                  <option value="" />
                  <option value="TOKEN-A">Token A</option>
                  <option value="TOKEN-B">Token B</option>
                </select>
              </div>
              <div>
                <label>Borrar</label>
                <button
                  className="delete-button"
                  onClick={() => handleRemove(index)}
                >
                  X
                </button>
              </div>
            </li>
          ))}
        </ul>

        <input className="submit-button" type="submit" value="Crear tokens" />
      </form>
    </NewTokenStyles>
  )
}

export default NewBuildingTokens
