async function getAllTokenTrades(userToken) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/token_trades`,
    {
      method: 'GET',
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp.data.rows
}

async function getBuildingTokenTrades(userToken, phaseId) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/token_trades/building/${phaseId}`,
    {
      method: 'GET',
      headers: myHeaders
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp.data
}

async function newTokenTrade(userToken, body) {
  const myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/api/token_trades`,
    {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow',
      body: JSON.stringify(body)
    }
  ).catch((err) => console.error(err))
  const resp = await response.json()
  return resp
}

export { getBuildingTokenTrades, newTokenTrade, getAllTokenTrades }
