/* eslint-disable react/require-default-props */
import React from 'react'
import styled from 'styled-components'
import { colors } from './constants'

interface Props {
  type: string
  disabled: boolean
}

const ButtonStyled = styled.div<Props>`
  background-color: ${(props) =>
    props.type === 'cancel' || props.disabled
      ? colors.gridGray
      : colors.secondaryColor};
  color: ${(props) => (props.disabled ? colors.gridSecondary : colors.black)};
  cursor: pointer;
  padding: 5px 1rem;
  transition: all 0.2s;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;

  :hover {
    background-color: ${(props) =>
      props.disabled ? colors.gridGray : colors.highlightColor};
  }

  :active {
    transform: ${(props) => (props.disabled ? null : 'translateY(1px)')};
  }
`

interface ButtonProps {
  text: string
  type?: string
  disabled?: boolean
  className?: string
  onClick: () => void
}
const Button = ({
  text,
  onClick,
  disabled = false,
  type = 'primary',
  className = ''
}: ButtonProps): JSX.Element => {
  const handleClick = (): void => {
    if (!disabled) {
      onClick()
    }
  }

  return (
    <ButtonStyled
      className={className}
      onClick={() => handleClick()}
      type={type}
      disabled={disabled}
    >
      {text}
    </ButtonStyled>
  )
}

export default Button
