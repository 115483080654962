import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useSelector } from 'react-redux'
// layout
import { colors } from '../layout/constants'
// services
import { getUsers } from '../../services/users'

const OwnersTable = ({ tokens }) => {
  // Redux hooks
  const userToken = useSelector((state) => state.user.token)
  const [owners, setOwners] = useState([])

  useEffect(() => {
    const ownersList = tokens.reduce((list, token) => {
      const reducedList = new Set([...list, token.owner_rfc])
      return reducedList
    }, [])
    setOwners(Array.from(ownersList))
  }, [tokens])

  // Query
  const { isLoading, isError, data, error } = useQuery('users', () =>
    getUsers(userToken)
  )

  // Render helpers
  const getOwnerInfo = (owner) => {
    const userInfo = data.data.find((user) => user.rfc === owner)
    return (
      <span className="center-item">
        <p>
          {userInfo.name} {userInfo.last_name}
        </p>
        <p>{userInfo.rfc}</p>
      </span>
    )
  }

  const getUserTokens = (userRfc) => {
    const userTokens = tokens
      .filter((token) => token.owner_rfc === userRfc)
      .sort(sortTokens)

    return userTokens.map((token) => <li key={token.id}>{token.token_id}</li>)
  }

  const downloadCsv = () => {
    const tokenList = owners.map((owner) => {
      const userTokens = tokens
        .filter((token) => token.owner_rfc === owner)
        .sort(sortTokens)

      const userInfo = data.data.find((user) => user.rfc === owner)

      const userRow = [
        `${userInfo.name} ${userInfo.last_name}`,
        userInfo.rfc,
        userTokens[0].building_phase_id,
        userTokens.map((token) => token.token_id).join(' ')
      ]
      return userRow
    })

    tokenList.unshift(['Nombre', 'RFC', 'Edificio', 'Tokens'])

    const csvContent = `data:text/csv;charset=utf-8,${tokenList
      .map((e) => e.join(','))
      .join('\n')}`

    const encodedUri = encodeURI(csvContent)
    window.open(encodedUri)
  }

  const sortTokens = (a, b) => a.id - b.id

  if (isLoading) {
    return <span>Loading...</span>
  }

  if (isError) {
    return <span>Error: {error.message}</span>
  }

  return (
    <TableStyled>
      <ul className="table-container">
        <li className="table-row table-header">
          <span className="table-header" onClick={() => downloadCsv()}>
            <button className="table-action">Descargar</button>
          </span>
          <span className="table-header">Usuario</span>
          <span className="table-header">Cantidad</span>
          <span className="table-header">Tokens</span>
        </li>
        {owners.map((owner, index) => (
          <li key={index} className="table-row">
            <span />
            {getOwnerInfo(owner)}
            <span className="center-item">{getUserTokens(owner).length}</span>
            <span>
              <ol className="token-list">{getUserTokens(owner)}</ol>
            </span>
          </li>
        ))}
      </ul>
    </TableStyled>
  )
}

const TableStyled = styled.div`
  .table-container {
    list-style: none;
  }

  .table-row  {
    background-color: ${colors.darkBackground};
    color: ${colors.white};
    display: grid;
    grid-template-columns: 100px 200px 100px 1fr;
    padding: 1rem;
    margin: 0.25rem 0;

    &.table-header {
      align-items: center;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      font-size: 1.2rem;
      text-align: center;
    }

    :hover {
      background-color: #425246;
    }

    .center-item {
      text-align: center;
      font-size: 1.2rem;
    }
  }

  .token-list {
    max-height: 600px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    overflow-x: scroll;

    li {
      margin: 0.25rem 2rem;
    }

    ::-webkit-scrollbar {
      margin: 1rem;
      width: 0.5rem;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: none;
      border-radius: 8px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${colors.secondaryColor};
      border-radius: 8px;
    }
  }

  .table-action {
    background-color: ${colors.secondaryColor};
    border: none;
    border-radius: 8px;
    color: ${colors.darkText};
    cursor: pointer;
    padding: 0.5rem 1rem;

    :hover {
      background-color: ${colors.highlightColor};
    }
  }
`

export default OwnersTable
