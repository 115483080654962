import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useSelector } from 'react-redux'
// layout
import DefaultLayout from '../layout'
// Components
import BasicTable from './table'
// services
import { getSubscriptions } from '../../services/subscriptions'
// interfaces
// types
import { Subscription } from '../../interfaces'

// styles
const Container = styled.section`
  padding: calc(52px + 1rem) 2rem 1rem 2rem;
`

const Title = styled.h1`
  font-size: 40px;
  margin-bottom: 1em;
  width: 100%;
`

const Subscriptions = (): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])

  useEffect(() => {
    const subscriptionsList = async (): Promise<void> => {
      const { data: subscriptionsResult } = await getSubscriptions(userToken)
      setSubscriptions(subscriptionsResult)
    }
    subscriptionsList()
  }, [userToken])

  return (
    <DefaultLayout sidenav>
      <Container>
        <Title>Suscripciones</Title>
        <BasicTable
          setSubscription={setSubscriptions}
          subscriptions={subscriptions}
        />
      </Container>
    </DefaultLayout>
  )
}

export default Subscriptions
