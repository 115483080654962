export async function getFundingInstructions(userToken, userId) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/paymentIntent/instructions/${userId}`,
      {
        method: 'GET',
        headers: myHeaders
      }
    )
    const { bank_transfer } = await response.json()
    const spei = bank_transfer.financial_addresses[0].spei

    return spei
  } catch (err) {
    console.error(err)
  }
}

export async function openCheckoutSession(userToken, initialPayment, userId) {
  var myHeaders = new Headers()
  myHeaders.append('Content-Type', 'application/json')
  myHeaders.append('Authorization', `Bearer ${userToken}`)

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/api/stripe/checkout`,
      {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({
          initialPayment,
          modeSession: 'payment',
          userId
        })
      }
    )
    const resp = await response.json()
    return resp
  } catch (err) {
    console.error(err)
  }
}
