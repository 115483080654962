import React from 'react'
import styled from 'styled-components'
import { colors } from '../constants'

const Modal = ({ toggleModal, children }) => (
  // Modal controlls
  // const [modal, setModal] = useState(false);
  // const toggleModal = (value) => {
  //   setModal(value);
  // };

  <ModalStyled>
    <section className="modal-background" onClick={() => toggleModal(false)} />
    <section className="modal-card">
      <article className="close-button">
        <p onClick={() => toggleModal(false)}>X Cerrar</p>
      </article>
      {children}
    </section>
  </ModalStyled>
)
const ModalStyled = styled.section`
  align-items: center;
  height: 100vh;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 2;

  .modal-background {
    background-color: #0006;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    z-index: -1;
  }

  .modal-card {
    animation-name: enter;
    animation-duration: 0.8s;
    animation-fill-mode: easy-in;
    background-color: ${colors.gridSecondary};
    border-radius: 8px;
    box-shadow: 1px 4px 10px 1px ${colors.black};
    color: ${colors.darkText};
    padding: 20px;
  }

  @keyframes enter {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(100%);
    }
  }

  .close-button {
    color: ${colors.darkText};
    margin-bottom: 1em;
    text-align: right;

    p {
      display: inline-block;
      cursor: pointer;
    }

    p:hover {
      transform: scale(1.1);
    }
  }
`

export default Modal
