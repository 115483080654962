import React from 'react'
import styled from 'styled-components'
import { colors, layoutValues } from '../layout/constants'

const HighlightContainer = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;
`

const Card = styled.article`
  background-color: white;
  box-sizing: border-box;
  box-shadow: ${layoutValues.cardShadow};
  border-radius: ${layoutValues.cardRadius};
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 190px;

  &:hover {
    background-color: ${colors.gridSecondary};
  }

  h2 {
    font-size: 4rem;
    color: ${colors.primaryColor};
    text-align: center;
  }

  h3 {
    color: ${colors.gridGray};
    font-size: 1.5rem;
  }

  &.active {
    border: 5px solid ${colors.secondaryColor};
  }
`

const Highlights = ({
  count,
  segmentFilter,
  setSegmentFilter
}: any): JSX.Element => (
  <HighlightContainer>
    <Card className={segmentFilter} onClick={() => setSegmentFilter('')}>
      <h3>Usuarios</h3>
      <h2>{count}</h2>
    </Card>
    <Card
      className={segmentFilter === 'new-users' ? 'active' : undefined}
      onClick={() => setSegmentFilter('new-users')}
    >
      <h3>Nuevos Usuarios</h3>
    </Card>
    <Card
      className={segmentFilter === 'verificated-users' ? 'active' : undefined}
      onClick={() => setSegmentFilter('verificated-users')}
    >
      <h3>Usuarios Verificados</h3>
    </Card>
    <Card
      className={segmentFilter === 'pending-users' ? 'active' : undefined}
      onClick={() => setSegmentFilter('pending-users')}
    >
      <h3>Usuarios pendiented de contrato</h3>
    </Card>
    <Card
      className={segmentFilter === 'signed-users' ? 'active' : undefined}
      onClick={() => setSegmentFilter('signed-users')}
    >
      <h3>Usuarios con Contrato Firmado</h3>
    </Card>
    <Card
      className={segmentFilter === 'balance-users' ? 'active' : undefined}
      onClick={() => setSegmentFilter('balance-users')}
    >
      <h3>Ordenar por balance</h3>
    </Card>
  </HighlightContainer>
)

export default Highlights
