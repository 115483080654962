import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import currency from 'currency.js'
import { useSelector } from 'react-redux'
// components
import { User, ExtendedToken } from '../orders/interfaces'
import ListByBuilding from './listByBuilding'
import { Button } from '../../urvita-ui'
// services
import { openCheckoutSession } from '../../services/stripe'

const TokensWrapper = styled.div`
  color: black;
  display: grid;
  align-items: center;
  gap: 1rem;
  margin-top: 1rem;
  padding: 1rem;
  border-radius: 1rem;

  .order-total {
    text-align: right;
    margin-top: 1rem;
  }

  .token-detail-heading {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .order-price-heading {
    text-align: right;
  }

  .left-button {
    margin: 0 0 0 auto;
    cursor: copy;
  }
`

type listData = {
  tokens: ExtendedToken[]
  phase_name: string
  user: User
}

const OrderTokensList = ({
  tokens,
  phase_name,
  user
}: listData): JSX.Element => {
  const userToken = useSelector(
    (state: { user: { token: string } }) => state.user.token
  )
  const [presentBuildings, setPresentBuildings] = useState<string[]>()
  const [paymentLink, setPaymentLink] = useState<string>()

  const orderTotal = tokens.reduce(
    (a, b) => a + Number(b.order_token.token_transaction_price),
    0
  )

  useEffect(() => {
    const getBuildings = (): void => {
      const buildings = tokens.map((token) => token.building_phase_id)
      const set = new Set(buildings)
      const setToArray = [...Array.from(set)]
      setPresentBuildings(setToArray)
    }
    const getPaymentUrl = async (): Promise<void> => {
      const { data } = await openCheckoutSession(userToken, orderTotal, user.id)
      setPaymentLink(data?.url)
    }

    getBuildings()
    getPaymentUrl()
  }, [orderTotal, tokens, user, userToken])

  const filterBuildingTokens = (building_id: string): ExtendedToken[] => {
    const filteredTokens = tokens.filter(
      (token) => token.building_phase_id === building_id
    )
    return filteredTokens
  }

  if (!tokens || tokens.length < 1) {
    return (
      <ol>
        {phase_name.split(',').map((token: string) => (
          <li key={token}>{token}</li>
        ))}
      </ol>
    )
  }

  return (
    <TokensWrapper>
      {presentBuildings?.map((building) => (
        <ListByBuilding
          key={building}
          building={building}
          filteredTokens={filterBuildingTokens(building)}
        />
      ))}

      <h3 className="order-total">
        Total de la Orden: {currency(orderTotal).format()} MXN
      </h3>
      {paymentLink && (
        <Button
          className="left-button"
          text="Copiar link de pago"
          onClick={() =>
            paymentLink && navigator.clipboard.writeText(paymentLink)
          }
        />
      )}
    </TokensWrapper>
  )
}

export default OrderTokensList
