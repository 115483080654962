import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
// helpers
import dateFormat from '../../helpers/dateFormat'
// components
import BuildingRow from './buildingRow'

const BuildingsTable = ({
  lastPeriod,
  period,
  lastValues,
  presentValues,
  buildingsList,
  setHasTrackRecord,
  toggleModal,
  askForBuildings
}) => {
  const [missingRecordCount, setMissingRecordCount] = useState(0)

  useEffect(() => {
    if (missingRecordCount > 0) {
      setHasTrackRecord(false)
    } else {
      setHasTrackRecord(true)
    }
  }, [missingRecordCount])

  // Filter by building and period helper
  const filterValues = (phase_id, values) => {
    const buildingValue = values?.filter((value) => {
      const hasValue = phase_id === value.building_phase_id
      return hasValue
    })
    return buildingValue ? buildingValue[0] : null
  }

  return (
    <TableStyled>
      <li className="building-item">
        <h3>Urvita</h3>
        <h3 className="title">{lastPeriod ? dateFormat(lastPeriod) : null}</h3>
        <span />
        <h3 className="title">{period ? dateFormat(period) : null}</h3>
        <h3 className="title">Ocupación</h3>
        <h3 className="title">Gastos</h3>
      </li>
      {lastValues.length > 0
        ? buildingsList.map((building) => (
            <BuildingRow
              key={building.phase_id}
              building={building}
              period={period}
              lastValues={filterValues(building.phase_id, lastValues)}
              presentValues={filterValues(building.phase_id, presentValues)}
              setMissingRecordCount={setMissingRecordCount}
              toggleModal={toggleModal}
              askForBuildings={askForBuildings}
            />
          ))
        : null}
    </TableStyled>
  )
}

const TableStyled = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  .title {
    text-align: center;
  }

  .building-item {
    align-items: center;
    border-bottom: 2px solid black;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-gap: 1rem;
    padding: 10px 0;
  }
`

export default BuildingsTable
